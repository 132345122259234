import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  forwardRef,
  Injector,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  NgControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

import {
  WindowCloseResult,
  WindowService,
} from '@progress/kendo-angular-dialog';
import { of } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';

import {
  AttributeEditor,
  CustomComponent,
} from '../../models/dynamicEditor.interface';
import { ComponentItem } from '../../models/componentContract.model';
import { FrameEditorConfig } from '../../models/editorContract.model';
import { DynamicContainerDirective } from '../../directives/dynamic-container.directive';

import { SwapService } from '../../services/swap.service';
import { ComponentService } from '../../services/component.service';

import { EditorFrameConfigComponent } from './editor-frame-config.component';
import { createFrameEditorValidator } from '../../validators/validators';
import { EditorEvent } from '../../models/dataContract.model';

@Component({
  selector: 'app-editor-frame',
  templateUrl: './editor-frame.component.html',
  styleUrls: ['./editor-frame.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorFrameComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EditorFrameComponent),
      multi: true,
    },
  ],
})
export class EditorFrameComponent
  extends AttributeEditor
  implements AfterViewInit
{
  @ViewChild(DynamicContainerDirective)
  componentContainer: DynamicContainerDirective;

  private conf = new FrameEditorConfig();
  public get config() {
    return this.conf;
  }
  public set config(value) {
    this.conf = value;
    this.configChange.emit(this.conf);
  }

  componentItem: ComponentItem;
  componentRef: ComponentRef<any>;

  get value() {
    return this.editorAttribute
      ? this.config.isMultivalue
        ? this.editorAttribute.values
        : this.editorAttribute.value
      : null;
  }
  set value(value) {
    if (this.config.isMultivalue) {
      this.editorAttribute.values = value;
      if (value && value.length > 0) {
        this.editorAttribute.value = value[0];
      }
    } else {
      this.editorAttribute.value = value;
    }
    this.propagateChange(this.editorAttribute);
  }

  constructor(
    public injector: Injector,
    private swap: SwapService,
    private cfr: ComponentFactoryResolver,
    private window: WindowService,
    private com: ComponentService
  ) {
    super(injector);
  }

  setDisplay(usedFor: string = null, optionValue: boolean = null) {
    this.applyDisplaySettings(this.swap, this.resource, usedFor, optionValue);
  }

  applyConfig() {
    setTimeout(() => {
      this.setDisplay();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const ngControl: NgControl = this.injector.get<NgControl>(NgControl);
      if (ngControl) {
        this.control = ngControl.control as FormControl;
      }

      this.validationFn = createFrameEditorValidator(this.config);

      this.applyConfig();

      if (this.creationMode && !this.configMode) {
        if (this.config.initExpression) {
          this.value = this.resolveExpression(this.config.initExpression);
          // trigger init value building for wizard view
          // this doesn't apply for editing view because initExpression doesn't exist
          this.swap.editorEvent(
            new EditorEvent(
              'change',
              this.config.attributeName,
              this.currentID,
              this.currentType,
              this.value
            )
          );
        }
      }

      this.initComponent();
    });
  }

  // #region AttributeEditor implementation

  initComponent() {
    if (this.editorAttribute && this.editorAttribute.required) {
      this.config.required = true;
      this.config.requiredFromSchema = true;
    }

    const initConfig = new FrameEditorConfig();
    this.utils.CopyInto(this.config, initConfig, true, true, [
      'calculatedDisplayable',
      'calculatedEditable',
    ]);
    this.config = initConfig;

    this.componentItem = this.com.getCustomComponent(this.config.componentID);
    if (
      this.componentContainer &&
      this.componentItem &&
      this.componentItem.component
    ) {
      // get component
      const componentFactory = this.cfr.resolveComponentFactory(
        this.componentItem.component
      );
      // get container
      const viewContainerRef = this.componentContainer.viewContainerRef;
      viewContainerRef.clear();
      // load component
      this.componentRef =
        viewContainerRef.createComponent<CustomComponent>(componentFactory);

      // init component with attribute value
      if (this.componentRef.instance) {
        this.componentRef.instance.data =
          this.config.data ?? this.componentItem.data;
        this.componentRef.instance.currentResource = this.currentResource;
        this.componentRef.instance.componentValue = this.value;
        // register value change handler
        this.componentRef.instance.valueChange.subscribe(
          (instanceValue: any) => {
            if (instanceValue) {
              this.value = instanceValue;
            }
          }
        );
      }

      // trigger the validator
      if (this.creationMode && !this.configMode) {
        // eslint-disable-next-line no-self-assign
        this.value = this.value;
      }
    }

    return this.config;
  }

  configure() {
    const configCopy = this.utils.DeepCopy(this.config);

    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    const windowRef = this.window.open({
      content: EditorFrameConfigComponent,
      width: 700,
    });
    const windowIns = windowRef.content.instance;
    windowIns.data = {
      component: this,
      config: this.config,
      attribute: this.editorAttribute,
      creationMode: this.creationMode,
      viewMode: this.viewMode,
    };

    return windowRef.result.pipe(
      tap((result: any) => {
        if (result instanceof WindowCloseResult) {
          this.config = configCopy;
        } else {
          this.validationFn = createFrameEditorValidator(this.config);
          this.applyConfig();
        }
      }),
      switchMap(() => {
        return of(this.config);
      }),
      finalize(() => {
        this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });
      })
    );
  }

  // #endregion
}
