import {
  AfterViewInit,
  Component,
  forwardRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  NgControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { AttributeEditor } from '../../models/dynamicEditor.interface';
import {
  AttributeResource,
  EditorEvent,
} from '../../models/dataContract.model';
import { XpathEditorConfig } from '../../models/editorContract.model';

import { SwapService } from '../../services/swap.service';
import { XpathBuilderComponent } from '../xpath-builder/xpath-builder.component';
import { EMPTY, of, Subscription } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { ResourceTableConfig } from '../../models/componentContract.model';
import { ResourceTableComponent } from '../resource-table/resource-table.component';
import { EditorXpathConfigComponent } from './editor-xpath-config.component';
import {
  WindowCloseResult,
  WindowService,
} from '@progress/kendo-angular-dialog';
import { AttributePickerComponent } from '../attribute-picker/attribute-picker.component';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-editor-xpath',
  templateUrl: './editor-xpath.component.html',
  styleUrls: ['./editor-xpath.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorXpathComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EditorXpathComponent),
      multi: true,
    },
  ],
})
export class EditorXpathComponent
  extends AttributeEditor
  implements OnInit, AfterViewInit, OnDestroy
{
  private subscription = new Subscription();

  @ViewChild('xpathBuilder') builder: XpathBuilderComponent;

  @ViewChild('resourceTable')
  resourceTable: ResourceTableComponent;

  @ViewChild('attributePicker')
  attributePicker: AttributePickerComponent;

  tableConfig: ResourceTableConfig;

  showResultsTable = false;

  queryType = '*';
  selectedAttributes: Array<string> = ['DisplayName'];

  displayMode: string;

  xpathCanNotParse = false;

  private conf = new XpathEditorConfig();
  public get config() {
    return this.conf;
  }
  public set config(value) {
    this.conf = value;
    this.configChange.emit(this.conf);
  }

  get value() {
    if (!this.editorAttribute || !this.editorAttribute.value) {
      return null;
    }
    let retVal: string = this.editorAttribute.value;
    if (this.config.prefix) {
      if (this.isFilterXmlPrefix(this.config.prefix)) {
        retVal = retVal.substring(this.config.prefix.length);
      } else {
        retVal = retVal.replace(this.config.prefix, '');
      }
    }
    if (this.config.surfix) {
      retVal = retVal.replace(this.config.surfix, '');
    }
    return retVal;
  }
  set value(value) {
    let result = value;
    if (this.config.prefix) {
      result = this.config.prefix + result;
    }
    if (this.config.surfix) {
      result = result + this.config.surfix;
    }
    this.editorAttribute.value = result;

    this.propagateChange(this.editorAttribute);
  }

  hideNoReadAccessMessage: boolean = this.configService.getConfig(
    'hideNoReadAccessMessage',
    false
  );
  hideNoWriteAccessMessage: boolean = this.configService.getConfig(
    'hideNoWriteAccessMessage',
    false
  );

  private setTableProperties() {
    if (this.config && this.config.showResults) {
      this.tableConfig = this.config.tableConfig;
    }
  }

  private isFilterXmlPrefix(text: string) {
    return text.toLowerCase().startsWith('<filter ') && text.length === 231;
  }

  constructor(
    public injector: Injector,
    private swap: SwapService,
    private window: WindowService,
    private configService: ConfigService
  ) {
    super(injector);
  }

  setDisplay(usedFor: string = null, optionValue: boolean = null) {
    this.applyDisplaySettings(this.swap, this.resource, usedFor, optionValue);
  }

  applyConfig() {
    setTimeout(() => {
      this.setDisplay();
    });
  }

  ngOnInit(): void {
    this.initComponent();

    this.setTableProperties();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const ngControl: NgControl = this.injector.get<NgControl>(NgControl);
      if (ngControl) {
        this.control = ngControl.control as FormControl;
      }
    });

    setTimeout(() => {
      this.xpathCanNotParse = false;

      this.applyConfig();

      if (this.creationMode && !this.configMode) {
        if (this.config.initExpression) {
          this.value = this.resolveExpression(this.config.initExpression);
          // trigger init value building for wizard view
          // this doesn't apply for editing view because initExpression doesn't exist
          this.swap.editorEvent(
            new EditorEvent(
              'change',
              this.config.attributeName,
              this.currentID,
              this.currentType,
              this.value
            )
          );
        }
      }

      if (this.builder && this.value) {
        this.subscription.add(
          this.resource
            .xpathToJson(this.value)
            .pipe(
              tap((result: any) => {
                this.xpathCanNotParse = !this.utils.validateXPathQuery(result);
                if (!this.xpathCanNotParse) {
                  this.builder.query = this.utils.DeepCopy(
                    this.utils.toXPathQuery(result)
                  );
                  this.builder.ngOnInit();
                }
              }),
              catchError(() => {
                this.xpathCanNotParse = true;
                return of(EMPTY);
              })
            )
            .subscribe()
        );
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initComponent() {
    if (this.editorAttribute && this.editorAttribute.required) {
      this.config.required = true;
      this.config.requiredFromSchema = true;
    }

    const initConfig = new XpathEditorConfig();
    this.utils.CopyInto(this.config, initConfig, true, true, [
      'calculatedDisplayable',
      'calculatedEditable',
    ]);
    this.config = initConfig;

    this.displayMode = this.config.displayMode ?? 'builder';

    return this.config;
  }

  configure() {
    const configCopy = this.utils.DeepCopy(this.config);

    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    const windowRef = this.window.open({
      content: EditorXpathConfigComponent,
      width: 780,
    });
    const windowIns = windowRef.content.instance;
    windowIns.data = {
      component: this,
      config: this.config,
      attribute: this.editorAttribute,
      creationMode: this.creationMode,
      viewMode: this.viewMode,
    };

    return windowRef.result.pipe(
      tap((result: any) => {
        if (result instanceof WindowCloseResult) {
          this.config = configCopy;
        } else {
          this.applyConfig();
        }
      }),
      switchMap(() => {
        return of(this.config);
      }),
      finalize(() => {
        this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });
      })
    );
  }

  applyStatement() {
    if (this.builder) {
      this.builder.onApplyStatement();
    }
  }

  // #region Event handler

  onFocuse() {
    this.propagateTouched();
  }

  onChange() {
    if (this.displayMode === 'builder' && this.builder) {
      this.tableConfig.objectType = this.builder.getType();
      this.subscription.add(
        this.builder
          .getXpath()
          .pipe(
            tap((result: any) => {
              if (this.value !== result) {
                this.value = result;
                if (this.valueChange.observers.length > 0) {
                  this.valueChange.emit(this.value);
                }
                this.swap.editorEvent(
                  new EditorEvent(
                    'change',
                    this.config.attributeName,
                    this.currentID,
                    this.currentType,
                    this.value
                  )
                );
              }
            }),
            catchError(() => {
              return of(EMPTY);
            })
          )
          .subscribe()
      );
    }
  }

  onChangeObs() {
    if (this.builder) {
      this.tableConfig.objectType = this.builder.getType();
      return this.builder.getXpath().pipe(
        tap((result: any) => {
          if (this.displayMode === 'builder') {
            this.value = result;
          }
          if (this.valueChange.observers.length > 0) {
            this.valueChange.emit(this.value);
          }
          this.swap.editorEvent(
            new EditorEvent(
              'change',
              this.config.attributeName,
              this.currentID,
              this.currentType,
              this.value
            )
          );
        }),
        catchError(() => {
          return EMPTY;
        })
      );
    } else {
      return EMPTY;
    }
  }

  onShowResults() {
    if (this.displayMode === 'builder') {
      if (this.builder) {
        this.subscription.add(
          this.builder
            .getXpath()
            .pipe(
              tap((result: any) => {
                this.showResultsTable = true;
                // this.builder.onApplyStatement();
                setTimeout(() => {
                  if (this.resourceTable) {
                    if (this.attributePicker) {
                      this.queryType = this.builder.getType();
                      this.selectedAttributes =
                        this.config.tableConfig.columns.map((c) => c.field);
                      setTimeout(() => {
                        this.attributePicker.refresh();
                      });
                    }
                    this.tableConfig.objectType = this.builder.getType();
                    this.tableConfig.dynamicColumns = [];
                    this.tableConfig.query = result;
                    this.resourceTable.updateDataSource(true);
                  }
                });
              }),
              catchError(() => {
                return of(EMPTY);
              })
            )
            .subscribe()
        );
      }
    } else if (this.displayMode === 'query') {
      this.showResultsTable = true;
      setTimeout(() => {
        if (this.value && this.resourceTable) {
          this.queryType = this.value.substring(1, this.value.indexOf('['));
          this.selectedAttributes = this.config.tableConfig.columns.map(
            (c) => c.field
          );
          this.tableConfig.objectType = this.queryType;
          this.tableConfig.dynamicColumns = [];
          this.tableConfig.query = this.value;
          setTimeout(() => {
            if (this.attributePicker) {
              this.attributePicker.refresh();
            }
            if (this.resourceTable) {
              this.resourceTable.updateDataSource(true);
            }
          });
        }
      });
    }
  }

  onShowQuery() {
    if (this.resourceTable) {
      this.resourceTable.clear();
    }
  }

  onResetValue() {
    this.xpathCanNotParse = false;
    this.showResultsTable = false;
    if (this.builder) {
      this.builder.query = null;
      this.builder.ngOnInit();
      this.value = null;
    }
  }

  onAttributeChange(attributes: Array<AttributeResource>) {
    if (!this.resourceTable || !this.resourceTable) {
      return;
    }

    attributes.forEach((attribute) => {
      if (
        this.resourceTable.allColumns.findIndex(
          (col) =>
            col.field.toLowerCase() === attribute.systemName.toLowerCase()
        ) < 0
      ) {
        if (this.resourceTable.config.dynamicColumns) {
          this.resourceTable.config.dynamicColumns.push({
            field: attribute.systemName,
            width: 0,
            sortable: true,
          });
        } else {
          this.resourceTable.config.dynamicColumns = [
            {
              field: attribute.systemName,
              width: 0,
              sortable: true,
            },
          ];
        }
      }
    });

    if (this.resourceTable.config.dynamicColumns) {
      this.resourceTable.config.dynamicColumns =
        this.resourceTable.config.dynamicColumns.filter(
          (col) =>
            attributes.findIndex(
              (a) => a.systemName.toLowerCase() === col.field.toLowerCase()
            ) >= 0
        );
    }

    this.resourceTable.updateDataSource(true);
  }

  onDisplayModeChange() {
    if (this.displayMode === 'query') {
      if (this.builder && this.builder.query) {
        this.subscription.add(
          this.builder
            .getXpath()
            .pipe(
              tap((result: string) => {
                if (
                  result &&
                  result !== '/*' &&
                  this.value.replace(/\s/g, '') !==
                    this.value.replace(/\s/g, '')
                ) {
                  this.value = result;
                }
              })
            )
            .subscribe()
        );
      }
    } else if (this.displayMode === 'builder') {
      if (this.builder && this.value) {
        this.subscription.add(
          this.resource
            .xpathToJson(this.value)
            .pipe(
              tap((result: any) => {
                this.xpathCanNotParse = !this.utils.validateXPathQuery(result);
                if (this.xpathCanNotParse) {
                  this.builder.query = undefined;
                } else {
                  this.builder.query = this.utils.DeepCopy(
                    this.utils.toXPathQuery(result)
                  );
                  this.builder.ngOnInit();
                }
              }),
              catchError(() => {
                this.xpathCanNotParse = true;
                if (this.builder) {
                  this.builder.query = undefined;
                }
                return EMPTY;
              })
            )
            .subscribe()
        );
      }
    }
  }

  // #endregion
}
