import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { EMPTY, of, throwError } from 'rxjs';
import { ModalType } from '../core/models/componentContract.model';
import { FormConfig } from '../core/models/dataContract.model';
import { ObjectView } from '../core/models/dynamicEditor.interface';
import { CanComponentDeactivate } from '../core/services/route-guard.service';
import { ConfigService } from '../core/services/config.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-form-view',
  templateUrl: './form-view.component.html',
  styleUrls: ['./form-view.component.scss'],
})
export class FormViewComponent
  extends ObjectView
  implements OnInit, OnDestroy, CanComponentDeactivate
{
  uiType: string;
  uiSetting: FormConfig;

  blurLevel: number;

  errorMessage = '';

  defaultChildrenQuery = `/*[ocgParentRef='%ParentID%']`;

  constructor(injector: Injector, private config: ConfigService) {
    super(injector);
  }

  ngOnInit(): void {
    this.errorMessage = '';

    this.blurLevel = this.config.getConfig('blurLevel', 1);
    this.subscription.add(
      this.route.params
        .pipe(
          switchMap(() => {
            const paramId = this.route.snapshot.paramMap.get('id');
            if (paramId) {
              return this.resource.getResourceByID(paramId, ['DisplayName']);
            } else {
              throwError('key_resourceNotFound');
            }
          }),
          tap(() => {
            this.swap.broadcast({ name: 'exit-edit-mode', parameter: true });
            const paramType = this.route.snapshot.paramMap.get('type');
            if (paramType) {
              if (!this.uiType) {
                this.uiType = paramType;
                this.uiSetting =
                  this.resource.primaryViewSetting.editingView[this.uiType];
                if (this.uiSetting) {
                  this.initComponent('editingView', this.uiType);
                } else {
                  const typeNames = Object.keys(this.resource.schema).filter(
                    (s: string) => s.toLowerCase() === this.uiType
                  );
                  if (typeNames && typeNames.length === 1) {
                    this.uiSetting = this.genericEditingForm;
                    this.uiSetting.objectType = typeNames[0];
                    this.initComponent('editingView', typeNames[0]);
                  } else {
                    throwError('key_typeNotFound');
                  }
                }
              } else {
                if (this.uiType !== paramType) {
                  this.uiType = paramType;
                  this.uiSetting =
                    this.resource.primaryViewSetting.editingView[this.uiType];
                  if (this.uiSetting) {
                    this.initComponent('editingView', this.uiType, false);
                  } else {
                    const typeNames = Object.keys(this.resource.schema).filter(
                      (s: string) => s.toLowerCase() === this.uiType
                    );
                    if (typeNames && typeNames.length === 1) {
                      this.uiSetting = this.genericEditingForm;
                      this.uiSetting.objectType = typeNames[0];
                      this.initComponent('editingView', typeNames[0]);
                    } else {
                      throwError('key_typeNotFound');
                    }
                  }
                }
              }
            } else {
              throwError('key_typeNotFound');
            }
          }),
          catchError((err: HttpErrorResponse | string) => {
            this.errorMessage = typeof err === 'string' ? err : err.error;
            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.swap.broadcast({ name: 'exit-edit-mode', parameter: true });
    // this.onCancelSetting();
    this.subscription.unsubscribe();
  }

  canDeactivate(
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    if (this.tabView && this.tabView.isTabDirty()) {
      if (nextState && nextState.url === '/expired') {
        return true;
      }

      const exclusions: string[] = this.config
        .getConfig('deactivationGuardExceptions', [])
        .concat(this.swap.deactivationGuardExceptions);
      if (
        this.tabView.formName &&
        exclusions.indexOf(this.tabView.formName) >= 0
      ) {
        return true;
      }

      const confirm = this.modal.show(
        ModalType.confirm,
        'key_confirmation',
        'l10n_changesNotSaved'
      );
      return confirm.afterClosed().pipe(
        switchMap((result) => {
          return result && result === 'yes' ? of(true) : of(false);
        })
      );
    }

    return true;
  }
}
