<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h3 mat-dialog-title cdkDragHandle>
    {{ 'key_resourceTableConfig' | translate }}
  </h3>
  <div mat-dialog-content>
    <mat-tab-group class="config-tab-group">
      <mat-tab [label]="'key_general' | translate">
        <div fxFlex fxLayout="column" class="first-dialog-item">
          <div
            fxFlex="1 1 auto"
            fxLayout="row"
            fxLayoutGap="20px"
            style="margin-bottom: 10px"
          >
            <mat-form-field fxFlex="auto">
              <input
                matInput
                [placeholder]="'key_title' | translate"
                [(ngModel)]="data.config.title"
              />
            </mat-form-field>
            <mat-form-field fxFlex="auto">
              <input
                matInput
                type="number"
                min="10"
                max="20"
                [placeholder]="'key_fontSize' | translate"
                [(ngModel)]="data.config.fontSize"
              />
            </mat-form-field>
            <mat-form-field fxFlex="auto">
              <input
                matInput
                type="number"
                min="0"
                max="20"
                [placeholder]="'key_padding' | translate"
                [(ngModel)]="data.config.cellPadding"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="1 1 auto"
            fxLayout="row"
            fxLayoutGap="20px"
            style="margin-bottom: 10px"
          >
            <mat-form-field fxFlex="auto">
              <input
                matInput
                [placeholder]="'key_objectType' | translate"
                [(ngModel)]="data.config.objectType"
              />
            </mat-form-field>
            <mat-form-field fxFlex="auto">
              <input
                matInput
                [placeholder]="'key_navigationKey' | translate"
                [(ngModel)]="data.config.navigationKey"
              />
            </mat-form-field>
            <mat-form-field fxFlex="auto">
              <input
                matInput
                type="number"
                [placeholder]="'key_tableHeight' | translate"
                [(ngModel)]="data.config.tableHeight"
              />
            </mat-form-field>
          </div>
          <div fxFlex="1 1 auto">
            <mat-form-field fxFlex="1 1 auto">
              <input
                matInput
                [placeholder]="'key_query' | translate"
                [(ngModel)]="data.config.query"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="1 1 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-bottom: 10px"
          >
            <mat-slide-toggle
              fxFlex="1 1 33"
              [(ngModel)]="data.config.linkNoneForm"
            >
              {{ 'key_noneFormLink' | translate }}</mat-slide-toggle
            >
            <mat-form-field fxFlex="1 1 33">
              <input
                matInput
                [placeholder]="'key_formatDate' | translate"
                [(ngModel)]="data.config.datetimeFormat"
              />
            </mat-form-field>
            <mat-form-field fxFlex="1 1 33">
              <input
                matInput
                type="number"
                [min]="0"
                [placeholder]="'key_exportMaxCount' | translate"
                [(ngModel)]="data.config.exportMaxCount"
              />
            </mat-form-field>
          </div>
          <div fxFlex="1 1 auto" fxLayout="row" fxLayoutGap="20px">
            <mat-slide-toggle
              fxFlex="33"
              [(ngModel)]="data.config.exportToClipBoard"
            >
              {{ 'key_exportToClipboard' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="33"
              [(ngModel)]="data.config.exportToExcel"
            >
              {{ 'key_exportToExcel' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="33"
              [(ngModel)]="data.config.exportAllPages"
            >
              {{ 'key_exportAllPages' | translate }}</mat-slide-toggle
            >
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_columns' | translate">
        <div fxLayout="row">
          <div fxFlex="40" fxLayout="column" style="max-height: 300px">
            <div
              fxFlex="auto"
              dragula="COLUMNS"
              [(dragulaModel)]="data.config.columns"
              style="overflow-y: auto"
            >
              <div
                *ngFor="let column of data.config.columns"
                fxLayout="row"
                [ngClass]="{
                  'attribute-cloumn-selected':
                    selectedColumnAttribute &&
                    column.field === selectedColumnAttribute.field
                }"
                class="attribute-column"
                style="padding-left: 10px; margin: 5px 0"
              >
                <div
                  fxFlex="auto"
                  style="line-height: 34px; cursor: pointer"
                  (click)="onSelectColumnAttribute(column)"
                >
                  {{ column.field }}
                </div>
                <button
                  fxFlex="none"
                  mat-icon-button
                  color="warn"
                  [disableRipple]="true"
                  [matTooltip]="'key_delete' | translate"
                  (click)="onDeleteColumn(column)"
                >
                  <mat-icon style="font-size: 20px; margin-top: -8px"
                    >delete_outline</mat-icon
                  >
                </button>
                <div
                  fxFlex="none"
                  class="handle"
                  style="width: 36px; cursor: pointer; color: lightgray"
                >
                  <mat-icon style="line-height: 34px">menu</mat-icon>
                </div>
              </div>
            </div>
            <div fxFlex="none" fxLayout="row" fxLayoutAlign="start end">
              <mat-form-field
                fxFlex="auto"
                style="margin-bottom: -20px; margin-right: 0"
              >
                <input
                  matInput
                  [placeholder]="'key_addAttributeColumn' | translate"
                  [(ngModel)]="columnToAdd"
                />
              </mat-form-field>
              <button
                mat-icon-button
                color="primary"
                [disabled]="!columnToAdd"
                (click)="onAddColumn()"
              >
                <mat-icon>playlist_add</mat-icon>
              </button>
            </div>
          </div>
          <div
            fxFlex="60"
            class="attribute-column-setting"
            style="max-height: 300px"
          >
            <div
              *ngIf="selectedColumnAttribute"
              fxLayout="column"
              fxLayoutGap="10px"
              style="font-size: 12px; padding: 10px"
            >
              <mat-form-field>
                <input
                  matInput
                  [placeholder]="'key_field' | translate"
                  [(ngModel)]="selectedColumnAttribute.field"
                />
              </mat-form-field>
              <mat-form-field>
                <input
                  matInput
                  [placeholder]="'key_title' | translate"
                  [(ngModel)]="selectedColumnAttribute.title"
                />
              </mat-form-field>
              <div fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex="33">
                  <mat-slide-toggle
                    [(ngModel)]="selectedColumnAttribute.sortable"
                    (change)="onEnableSorting(selectedColumnAttribute)"
                    >{{ 'key_sortable' | translate }}</mat-slide-toggle
                  >
                </div>
                <div fxFlex="33">
                  <mat-slide-toggle
                    [(ngModel)]="selectedColumnAttribute.locked"
                    >{{ 'key_locked' | translate }}</mat-slide-toggle
                  >
                </div>
                <mat-form-field fxFlex="33">
                  <input
                    matInput
                    type="number"
                    min="-1"
                    max="800"
                    [placeholder]="'key_width' | translate"
                    [(ngModel)]="selectedColumnAttribute.width"
                  />
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex="33">
                  <mat-slide-toggle
                    [(ngModel)]="selectedColumnAttribute.filterable"
                    >{{ 'key_filterable' | translate }}</mat-slide-toggle
                  >
                </div>
                <div fxFlex="33">
                  <mat-slide-toggle
                    [disabled]="!selectedColumnAttribute.filterable"
                    [(ngModel)]="selectedColumnAttribute.isReference"
                    (change)="onEnableReferenceFilter(selectedColumnAttribute)"
                    >{{ 'key_isReference' | translate }}</mat-slide-toggle
                  >
                </div>
                <mat-form-field fxFlex="33">
                  <mat-select
                    [disabled]="!selectedColumnAttribute.filterable"
                    [placeholder]="'key_filterType' | translate"
                    [(ngModel)]="selectedColumnAttribute.filter"
                  >
                    <mat-option value="text">text</mat-option>
                    <mat-option value="numeric">numeric</mat-option>
                    <mat-option value="boolean">boolean</mat-option>
                    <mat-option value="date">date</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxLayout="row">
                <app-editor-iterables
                  #itrColumnStatus
                  fxFelx="none"
                  [simpleMode]="true"
                  [config]="columnStatusConfig"
                  [(ngModel)]="selectedColumnAttribute.showStatus"
                  (valueChange)="
                    onColumnStatusDefChange($event, selectedColumnAttribute)
                  "
                  style="font-size: 16px; width: 100%; margin-top: -20px"
                ></app-editor-iterables>
              </div>
              <div
                *ngIf="showFallbackStatus"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <div fxFlex="33">{{ 'key_fallbackStatus' | translate }}</div>
                <mat-form-field fxFlex="33">
                  <input
                    matInput
                    [placeholder]="'key_text' | translate"
                    [(ngModel)]="selectedColumnAttribute.fallbackStatus.text"
                  />
                </mat-form-field>
                <mat-form-field fxFlex="33">
                  <input
                    matInput
                    [placeholder]="'key_color' | translate"
                    [(ngModel)]="selectedColumnAttribute.fallbackStatus.color"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_paging' | translate">
        <div
          fxFlex
          fxLayout="column"
          fxLayoutGap="10px"
          class="first-dialog-item"
        >
          <div
            fxFlex="1 1 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="33 0 0">
              <mat-select
                [placeholder]="'key_scrollType' | translate"
                [(ngModel)]="data.config.scrollMode"
              >
                <mat-option value="basic">{{
                  'key_basic' | translate
                }}</mat-option>
                <mat-option value="virtual">{{
                  'key_virtual' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="33 0 0">
              <input
                matInput
                type="number"
                min="0"
                max="100"
                [placeholder]="'key_scrollHeight' | translate"
                [(ngModel)]="data.config.scrollHeight"
                [disabled]="data.config.scrollMode === 'basic'"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="1 1 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="33 0 0">
              <mat-select
                [placeholder]="'key_type' | translate"
                [(ngModel)]="data.config.pageType"
                [disabled]="data.config.scrollMode === 'virtual'"
              >
                <mat-option value="numeric">numeric</mat-option>
                <mat-option value="input">input</mat-option>
              </mat-select>
            </mat-form-field>
            <div fxFlex="33 0 0">
              <mat-slide-toggle
                [disabled]="data.config.scrollMode === 'virtual'"
                [(ngModel)]="data.config.pageInfo"
                >{{ 'key_showPageInfo' | translate }}</mat-slide-toggle
              >
            </div>
            <div fxFlex="33 0 0">
              <mat-slide-toggle
                [disabled]="data.config.scrollMode === 'virtual'"
                [(ngModel)]="data.config.pageButton"
                >{{ 'key_showPageButton' | translate }}</mat-slide-toggle
              >
            </div>
          </div>
          <div
            fxFlex="1 1 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <div fxFlex="auto">
              <mat-form-field fxFlex="1 1 auto">
                <input
                  matInput
                  type="number"
                  min="1"
                  max="100"
                  [placeholder]="'key_pageSize' | translate"
                  [(ngModel)]="data.config.pageSize"
                />
              </mat-form-field>
            </div>
            <mat-form-field fxFlex="auto">
              <input
                matInput
                type="number"
                min="1"
                max="10"
                [placeholder]="'key_maxCounterNumber' | translate"
                [(ngModel)]="data.config.pageCountNumber"
                [disabled]="data.config.scrollMode === 'virtual'"
              />
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_sorting' | translate">
        <div
          fxFlex="1 1 auto"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="20px"
          class="first-dialog-item"
        >
          <mat-form-field fxFlex="33">
            <mat-select
              [disabled]="isCloud"
              [placeholder]="'key_sortMode' | translate"
              [(ngModel)]="data.config.sortMode"
            >
              <mat-option value="single">single</mat-option>
              <mat-option value="multiple">multiple</mat-option>
            </mat-select>
          </mat-form-field>
          <div fxFlex="33">
            <mat-slide-toggle [(ngModel)]="data.config.allowUnsort">
              {{ 'key_allowUnsort' | translate }}</mat-slide-toggle
            >
          </div>
        </div>
        <div
          fxFlex="none"
          style="font-weight: 400; margin-bottom: 20px; margin-top: 20px"
        >
          {{ 'key_addSortingOptions' | translate }}
        </div>
        <app-editor-iterables
          fxFelx="none"
          [config]="sortConfig"
          [ngModel]="attrDetailSorting"
          style="font-size: 16px"
        ></app-editor-iterables>
      </mat-tab>
      <mat-tab [label]="'key_selectAndResize' | translate">
        <div fxFlex fxLayout="column" class="first-dialog-item">
          <div
            fxFlex="1 1 auto"
            fxLayout="row"
            fxLayoutGap="20px"
            style="margin-bottom: 20px"
          >
            <div fxFlex="auto">
              <mat-slide-toggle [(ngModel)]="data.config.resizable">{{
                'key_resizable' | translate
              }}</mat-slide-toggle>
            </div>
          </div>
          <div
            fxFlex="1 1 auto"
            fxLayout="row"
            fxLayoutGap="20px"
            style="margin-bottom: 20px"
          >
            <div fxFlex="50">
              <mat-slide-toggle [(ngModel)]="data.config.selectable">{{
                'key_selectable' | translate
              }}</mat-slide-toggle>
            </div>
            <div fxFlex="50">
              <mat-slide-toggle
                [disabled]="!data.config.selectable"
                [(ngModel)]="data.config.checkboxSelectOnly"
              >
                {{ 'key_checkboxSelectionOnly' | translate }}</mat-slide-toggle
              >
            </div>
          </div>
          <div fxFlex="1 1 auto" fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="50">
              <mat-select
                [disabled]="!data.config.selectable"
                [placeholder]="'key_selectionMode' | translate"
                [(ngModel)]="data.config.selectMode"
              >
                <mat-option value="single">single</mat-option>
                <mat-option value="multiple">multiple</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <input
                [disabled]="!data.config.selectable"
                matInput
                type="number"
                min="10"
                max="100"
                [placeholder]="'key_selectBoxWidth' | translate"
                [(ngModel)]="data.config.selectBoxWidth"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="1 1 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="50">
              <input
                matInput
                [placeholder]="'key_defaultLinkAction' | translate"
                [(ngModel)]="data.config.defaultLinkAction"
              />
            </mat-form-field>
            <mat-slide-toggle
              fxFlex="50"
              [(ngModel)]="data.config.linkDisabled"
            >
              {{ 'key_disableLink' | translate }}</mat-slide-toggle
            >
          </div>
          <div fxFlex="1 1 auto" fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxFlex="100">
              <mat-chip-list #clLinkActions>
                <mat-chip
                  *ngFor="let action of data.config.linkActions"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveLinkAction(action)"
                  style="font-size: 12px"
                >
                  {{ action }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_linkActions' | translate"
                  [matChipInputFor]="clLinkActions"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddLinkAction($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      [mat-dialog-close]="data.config"
      (click)="onClose()"
    >
      {{ 'key_submit' | translate }}
    </button>
    <button fxFlex="none" mat-stroked-button (click)="onRefresh()">
      {{ 'key_apply' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button mat-dialog-close="cancel">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
