<div fxLayout="column">
  <div style="font-size: 18px; font-weight: 400; margin-bottom: 20px">
    {{ 'l10n_fxPlayGround' | translate }}
  </div>
  <div style="width: 100%">
    <mat-card>
      <mat-card-content>
        <div fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="start stretch">
            <div fxFlex="60" fxLayout="column">
              <mat-form-field appearance="fill" style="width: 100%">
                <mat-label>{{
                  'l10n_functionExpression' | translate
                }}</mat-label>
                <div>
                  <textarea
                    matInput
                    class="code-block"
                    [(ngModel)]="expression"
                    rows="30"
                    spellcheck="false"
                  ></textarea>
                  <button
                    fxFlex="none"
                    mat-stroked-button
                    color="primary"
                    class="operation-button"
                    [disabled]="expression === '' || expression === undefined"
                    (click)="onExecute()"
                    style="position: absolute; bottom: 20px; right: 3px"
                  >
                    <div
                      fxLayout="row"
                      fxLayoutAlign="center center"
                      fxLayoutGap="10px"
                    >
                      <div>{{ 'l10n_run' | translate }}</div>
                      <span class="material-icons-outlined"
                        >keyboard_return</span
                      >
                    </div>
                  </button>
                </div>
              </mat-form-field>
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                style="margin: -10px 0 10px 0"
              >
                <span class="material-icons-outlined">arrow_downward</span>
              </div>
              <div style="width: 100%">
                <div fxLayout="row">
                  <mat-form-field
                    fxFlex="auto"
                    appearance="fill"
                    style="width: 100%"
                  >
                    <mat-label>{{ 'key_viewResults' | translate }}</mat-label>
                    <mat-spinner
                      *ngIf="isLoading"
                      [diameter]="40"
                      style="
                        position: absolute;
                        left: calc(50% - 20px);
                        top: calc(50% - 20px);
                      "
                    ></mat-spinner>
                    <textarea
                      matInput
                      class="code-block"
                      rows="8"
                      spellcheck="false"
                      [readonly]="true"
                      [value]="expressionResultString"
                    ></textarea>
                  </mat-form-field>
                  <div
                    fxFlex="none"
                    fxLayout="column"
                    fxLayoutGap="5px"
                    style="margin-left: 10px"
                  >
                    <button
                      fxFlex="none"
                      mat-stroked-button
                      class="operation-button"
                      [disabled]="
                        expressionResultString === '' ||
                        expressionResult === undefined
                      "
                      (click)="onRawData()"
                    >
                      <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutGap="10px"
                      >
                        <span fxFlex="30px" class="material-icons-outlined"
                          >raw_on</span
                        >
                        <div>{{ 'l10n_raw' | translate }}</div>
                      </div>
                    </button>
                    <button
                      fxFlex="none"
                      mat-stroked-button
                      class="operation-button"
                      [disabled]="
                        expressionResultString === '' ||
                        expressionResult === undefined
                      "
                      (click)="onJsonData()"
                    >
                      <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutGap="10px"
                      >
                        <span fxFlex="30px" class="material-icons-outlined"
                          >segment</span
                        >
                        <div>{{ 'l10n_json' | translate }}</div>
                      </div>
                    </button>
                    <button
                      fxFlex="none"
                      mat-stroked-button
                      class="operation-button"
                      [disabled]="
                        expressionResultString === '' ||
                        expressionResult === undefined
                      "
                      (click)="onCopyData()"
                    >
                      <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutGap="10px"
                      >
                        <span
                          fxFlex="30px"
                          class="material-icons-outlined"
                          style="font-size: 18px"
                          >content_copy</span
                        >
                        <div>{{ 'key_copy' | translate }}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="40" fxLayout="column" style="margin-left: 20px">
              <div
                fxFlex="650px"
                fxLayout="column"
                style="overflow-x: hidden; overflow-y: auto"
              >
                <app-editor-identity
                  #idpRequestor
                  style="margin-top: 5px"
                  [(ngModel)]="requestorResource"
                  [creationMode]="true"
                  [config]="{
                    showPhoto: true,
                    allowEmptySearch: true,
                    queryNormalSearch:
                      '/person[starts-with(DisplayName,\'%SearchText%\') or starts-with(AccountName,\'%SearchText%\') or ObjectID=\'%SearchText%\']',
                    queryEmptySearch: '/person[starts-with(DisplayName,\'%\')]',
                    attributesToShow: [
                      {
                        field: 'DisplayName',
                        width: 0,
                        filterable: true,
                        filter: 'text',
                        sortable: true
                      },
                      {
                        field: 'AccountName',
                        width: 0,
                        filterable: true,
                        filter: 'text',
                        sortable: true
                      }
                    ]
                  }"
                ></app-editor-identity>
                <app-editor-identity
                  #idpTarget
                  [(ngModel)]="targetResource"
                  [creationMode]="true"
                  [config]="{
                    allowEmptySearch: true,
                    browserShowTypePicker: true,
                    queryNormalSearch:
                      '/*[starts-with(DisplayName,\'%SearchText%\')]',
                    queryEmptySearch: '/*[starts-with(DisplayName,\'%\')]',
                    attributesToShow: [
                      {
                        field: 'DisplayName',
                        width: 0,
                        filterable: true,
                        filter: 'text',
                        sortable: true
                      },
                      {
                        field: 'ObjectType',
                        width: 0,
                        filterable: true,
                        filter: 'text',
                        sortable: true
                      }
                    ]
                  }"
                ></app-editor-identity>
                <app-editor-iterables
                  #itrWorkflowData
                  [config]="configWorkflowDataExpression"
                  [ngModel]="workflowDataExpression"
                ></app-editor-iterables>
                <app-editor-iterables
                  #itrDelta
                  [config]="configDeltaExpression"
                  [ngModel]="deltaExpression"
                  style="margin-top: 10px"
                ></app-editor-iterables>
                <app-editor-iterables
                  #itrDeltaBeforeChange
                  [config]="configDeltaExpression"
                  [ngModel]="deltaBeforeChangeDataExpression"
                  style="margin-top: 10px"
                ></app-editor-iterables>
              </div>
              <div
                fxFlex="none"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="5px"
                style="margin-top: 20px"
              >
                <!-- <button
                  fxFlex="none"
                  mat-stroked-button
                  color="primary"
                  class="operation-button"
                  [disabled]="expression === '' || expression === undefined"
                  (click)="onExecute()"
                >
                  <div
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    fxLayoutGap="10px"
                  >
                    <div>{{ 'l10n_run' | translate }}</div>
                    <span class="material-icons-outlined">keyboard_return</span>
                  </div>
                </button> -->
                <div fxFlex="auto"></div>
                <button
                  fxFlex="none"
                  mat-stroked-button
                  class="operation-button"
                  [disabled]="expression === '' || expression === undefined"
                  (click)="onSaveExpression()"
                >
                  <div
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    fxLayoutGap="10px"
                  >
                    <div>{{ 'key_save' | translate }}</div>
                  </div>
                </button>
                <mat-form-field
                  appearance="outline"
                  style="height: 40px"
                  class="saved-expressions"
                >
                  <mat-select [(value)]="selectedExpression">
                    <mat-option
                      *ngFor="let exp of expressions"
                      [value]="exp"
                      style="font-size: 14px; height: 32px"
                    >
                      {{ exp.key }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button
                  fxFlex="none"
                  mat-stroked-button
                  class="operation-button"
                  [disabled]="!selectedExpression"
                  (click)="onLoadExpression()"
                >
                  <div
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    fxLayoutGap="10px"
                  >
                    <div>{{ 'key_load' | translate }}</div>
                  </div>
                </button>
                <button
                  fxFlex="none"
                  mat-stroked-button
                  class="operation-button"
                  color="warn"
                  [disabled]="!selectedExpression"
                  (click)="onDeleteExpression()"
                >
                  <div
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    fxLayoutGap="10px"
                  >
                    <div>{{ 'key_delete' | translate }}</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
