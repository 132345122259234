<div class="config-dialog">
  <div mat-dialog-content>
    <mat-tab-group>
      <mat-tab [label]="'key_general' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-bottom: 10px"
          >
            <mat-form-field fxFlex="50">
              <input
                matInput
                disabled
                [placeholder]="'key_attributeName' | translate"
                [(ngModel)]="data.config.attributeName"
              />
            </mat-form-field>
            <div fxFlex="50" fxLayout="column">
              <div
                fxFlex="1 1 50"
                style="
                  font-size: 12px;
                  font-weight: 400;
                  height: 18px;
                  color: rgba(0, 0, 0, 0.54);
                "
              >
                {{ 'key_layoutUnits' | translate }}
              </div>
              <mat-slider
                *ngIf="data.viewMode === 'tab'"
                fxFlex="none"
                [max]="12"
                [min]="1"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="1"
                [(ngModel)]="data.config.units"
                style="margin-top: 2px; padding-left: 0"
              >
              </mat-slider>
              <mat-slider
                *ngIf="data.viewMode === 'gridster'"
                fxFlex="none"
                [max]="12"
                [min]="1"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="1"
                [(ngModel)]="data.config.gridsterUnits"
                style="margin-top: 2px; padding-left: 0"
              >
              </mat-slider>
            </div>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-bottom: 10px"
          >
            <mat-form-field fxFlex="50">
              <input
                matInput
                [placeholder]="'key_componentID' | translate"
                [(ngModel)]="data.config.componentID"
              />
            </mat-form-field>
            <mat-slide-toggle
              fxFlex="25"
              [(ngModel)]="data.config.isMultivalue"
            >
              {{ 'key_multivalue' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle fxFlex="25" [(ngModel)]="data.config.required">
              {{ 'key_required' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex="none">{{ 'key_backgroundColor' | translate }}</div>
              <div
                fxFlex="0 0 auto"
                [(colorPicker)]="data.config.backgroundColor"
                cpPosition="right"
                [style.backgroundColor]="data.config.backgroundColor"
                style="
                  height: 24px;
                  width: 24px;
                  cursor: pointer;
                  border: solid darkgray;
                  margin-left: 10px;
                "
              ></div>
            </div>
            <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex="none">{{ 'key_borderColor' | translate }}</div>
              <div
                fxFlex="0 0 auto"
                [(colorPicker)]="data.config.borderColor"
                cpPosition="left"
                [style.backgroundColor]="data.config.borderColor"
                style="
                  height: 24px;
                  width: 24px;
                  cursor: pointer;
                  border: solid darkgray;
                  margin-left: 10px;
                "
              ></div>
            </div>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="0"
                max="30"
                [placeholder]="'key_spaceTop' | translate"
                [(ngModel)]="data.config.marginTop"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="0"
                max="30"
                [placeholder]="'key_spaceBottom' | translate"
                [(ngModel)]="data.config.marginBottom"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="0"
                max="30"
                [placeholder]="'key_paddingLeft' | translate"
                [(ngModel)]="data.config.paddingLeft"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="0"
                max="30"
                [placeholder]="'key_paddingRight' | translate"
                [(ngModel)]="data.config.paddingRight"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="0"
                max="30"
                [placeholder]="'key_paddingTop' | translate"
                [(ngModel)]="data.config.paddingTop"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="0"
                max="30"
                [placeholder]="'key_paddingBottom' | translate"
                [(ngModel)]="data.config.paddingBottom"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <mat-form-field fxFlex="25">
              <mat-select
                [placeholder]="'key_borderStyle' | translate"
                [(ngModel)]="data.config.borderStyle"
              >
                <mat-option value="dashed">{{
                  'dashed' | translate
                }}</mat-option>
                <mat-option value="dotted">{{
                  'dotted' | translate
                }}</mat-option>
                <mat-option value="double">{{
                  'double' | translate
                }}</mat-option>
                <mat-option value="groove">{{
                  'groove' | translate
                }}</mat-option>
                <mat-option value="inset">{{ 'inset' | translate }}</mat-option>
                <mat-option value="none">{{ 'none' | translate }}</mat-option>
                <mat-option value="outset">{{
                  'outset' | translate
                }}</mat-option>
                <mat-option value="ridge">{{ 'ridge' | translate }}</mat-option>
                <mat-option value="solid">{{ 'solid' | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="0"
                max="30"
                [placeholder]="'key_borderRadius' | translate"
                [(ngModel)]="data.config.borderRadius"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="0"
                max="10"
                [placeholder]="'key_borderWidth' | translate"
                [(ngModel)]="data.config.borderWidth"
              />
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_advanced' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div fxFlex="none" *ngIf="data.creationMode">
            <mat-form-field fxFlex="auto">
              <textarea
                matInput
                [matTextareaAutosize]
                [matAutosizeMinRows]="1"
                [matAutosizeMaxRows]="6"
                [placeholder]="'key_initExpression' | translate"
                [(ngModel)]="data.config.initExpression"
              ></textarea>
            </mat-form-field>
          </div>
          <div fxFlex="none" fxLayout="row">
            <mat-form-field fxFlex="100" [appearance]="'fill'">
              <mat-label>{{ 'key_data' | translate }}</mat-label>
              <textarea
                matInput
                [(ngModel)]="data.config.data"
                [rows]="3"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_display' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div fxFlex="none" style="margin-bottom: 15px">
            <mat-radio-group [(ngModel)]="data.config.accessUsedFor">
              <div fxLayout="row">
                <mat-radio-button fxFlex="1 1 33" value="visibility">{{
                  'key_useForVisibility' | translate
                }}</mat-radio-button>
                <mat-radio-button fxFlex="1 1 33" value="editability">{{
                  'key_useForEditability' | translate
                }}</mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <mat-chip-list #clDeniedSets>
                <mat-chip
                  *ngFor="let deniedSet of data.config.accessDenied"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveDeniedSet(deniedSet)"
                >
                  {{ deniedSet }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_deniedSets' | translate"
                  [matChipInputFor]="clDeniedSets"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddDeniedSet($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <mat-chip-list #clAllowedSets>
                <mat-chip
                  *ngFor="let alowedSet of data.config.accessAllowed"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveAllowedSet(alowedSet)"
                >
                  {{ alowedSet }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_allowedSets' | translate"
                  [matChipInputFor]="clAllowedSets"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddAllowedSet($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <input
                matInput
                [placeholder]="'key_accessExpression' | translate"
                [(ngModel)]="data.config.accessExpression"
              />
            </mat-form-field>
          </div>
          <div
            *ngIf="data.config.accessUsedFor === 'visibility'"
            fxFlex="none"
            style="margin-top: 15px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.hideFromDOM"
            >
              {{ 'key_hideFromDOM' | translate }}</mat-slide-toggle
            >
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      (click)="onClose()"
    >
      {{ 'key_submit' | translate }}
    </button>
    <button
      fxFlex="none"
      mat-stroked-button
      style="margin-left: 20px"
      (click)="onExportSetting()"
    >
      {{ 'key_exportSetting' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button (click)="onCancel()">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
<ng-template #titleBar let-win>
  <app-popup-title
    [win]="win"
    [title]="'key_editorConfig'"
    [leftPadding]="leftPadding"
  ></app-popup-title>
</ng-template>
