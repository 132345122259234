import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';

import { CustomComponent } from '../../models/dynamicEditor.interface';
import { DynamicComponent } from '../../models/dynamicComponent.interface';
import {
  ComponentItem,
  FrameViewConfig,
} from '../../models/componentContract.model';
import { DynamicContainerDirective } from '../../directives/dynamic-container.directive';
import { UtilsService } from '../../services/utils.service';
import { ComponentService } from '../../services/component.service';

import { FrameViewConfigComponent } from './frame-view-config.component';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-frame-view',
  templateUrl: './frame-view.component.html',
  styleUrls: ['./frame-view.component.scss'],
})
export class FrameViewComponent
  implements DynamicComponent, OnInit, AfterViewInit
{
  @ViewChild(DynamicContainerDirective)
  componentContainer: DynamicContainerDirective;

  @Input()
  config: FrameViewConfig;

  localConfig: FrameViewConfig;
  spinnerType = SPINNER;

  componentItem: ComponentItem;
  componentRef: ComponentRef<any>;

  blurLevel = this.configService.getConfig('blurLevel', 1);
  uiLoader = this.configService.getConfig(
    'uiLoader',
    this.configService.defaultUiLoader
  );

  constructor(
    private utils: UtilsService,
    private cfr: ComponentFactoryResolver,
    private spinner: NgxUiLoaderService,
    private dialog: MatDialog,
    private com: ComponentService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.initComponent();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadCompoent();
    });
  }

  resize() {}

  initComponent() {
    this.localConfig = new FrameViewConfig();
    this.utils.CopyInto(this.config, this.localConfig, true, true);

    return this.localConfig;
  }

  loadCompoent() {
    this.componentItem = this.com.getCustomComponent(
      this.localConfig.componentID
    );
    if (
      this.componentContainer &&
      this.componentItem &&
      this.componentItem.component
    ) {
      // get component
      const componentFactory = this.cfr.resolveComponentFactory(
        this.componentItem.component
      );
      // get container
      const viewContainerRef = this.componentContainer.viewContainerRef;
      viewContainerRef.clear();
      // load component
      this.componentRef =
        viewContainerRef.createComponent<CustomComponent>(componentFactory);

      // init component with attribute value
      if (this.componentRef.instance) {
        this.componentRef.instance.data =
          this.localConfig.data ?? this.componentItem.data;
        // register spinner loader handler
        if (this.componentRef.instance.startLoader) {
          this.componentRef.instance.startLoader.subscribe((param: any) => {
            if (param === true) {
              this.spinner.startLoader(this.localConfig.name);
            } else {
              this.spinner.stopLoader(this.localConfig.name);
            }
          });
        }
      }
    }
  }

  configure() {
    const configCopy = this.utils.DeepCopy(this.localConfig);

    const dialogRef = this.dialog.open(FrameViewConfigComponent, {
      minWidth: '650px',
      data: {
        component: this,
        config: this.localConfig,
      },
    });

    return dialogRef.afterClosed().pipe(
      tap((result) => {
        if (!result || (result && result === 'cancel')) {
          this.localConfig = configCopy;
        }
        this.updateDataSource();
      }),
      switchMap(() => {
        return of(this.localConfig);
      })
    );
  }

  updateDataSource() {
    this.loadCompoent();
  }
}
