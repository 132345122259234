import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { EMPTY, Observable, of, Subscription } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import { WindowCloseResult } from '@progress/kendo-angular-dialog';
import * as xml2js from 'xml2js';

import {
  ApprovalViewConfig,
  ModalType,
} from '../../models/componentContract.model';

import {
  AnyProperties,
  ApiDef,
  ApprovalRequest,
  ApprovalResponse,
  PopupConfig,
  RequestParameter,
  Resource,
  ResourceSet,
} from '../../models/dataContract.model';
import { TransService } from '../../models/translation.model';

import { ModalService } from '../../services/modal.service';
import { ResourceService } from '../../services/resource.service';
import { UtilsService } from '../../services/utils.service';

import { ModalComponent } from '../modal/modal.component';
import { ConfigService } from '../../services/config.service';
import { DynamicComponent } from '../../models/dynamicComponent.interface';
import { ApprovalCheckConfigComponent } from './approval-check-config.component';

@Component({
  selector: 'app-approval-check',
  templateUrl: './approval-check.component.html',
  styleUrls: ['./approval-check.component.scss'],
})
export class ApprovalCheckComponent
  implements DynamicComponent, OnInit, OnDestroy
{
  private subscription: Subscription = new Subscription();

  @Input()
  config: ApprovalViewConfig;

  localConfig: ApprovalViewConfig;
  approvalRequests: Array<ApprovalRequest> = [];
  spinnerType = SPINNER;

  blurLevel = this.configService.getConfig('blurLevel', 1);
  uiLoader = this.configService.getConfig(
    'uiLoader',
    this.configService.defaultUiLoader
  );

  private loadApprovalRequests() {
    this.approvalRequests = [];
    const objectID = this.utils.ExtraValue(this.resource.loginUser, 'ObjectID');
    if (objectID) {
      this.spinner.startLoader(this.localConfig.name);

      let obsApprovalRequests: Observable<any>;

      if (this.localConfig.mode === 'onprem') {
        const queryPendingApprovals =
          this.localConfig.queryPendingApprovals.replace(
            /\[#LoginID\]/gi,
            objectID
          );
        const queryAllApprovals = this.localConfig.queryAllApprovals.replace(
          /\[#LoginID\]/gi,
          objectID
        );
        const query = this.localConfig.showPendingApprovalsOnly
          ? queryPendingApprovals
          : queryAllApprovals;

        const attributesToLoad = [
          'DisplayName',
          'RequestStatus',
          'Target',
          'Creator',
          'CreatedTime',
        ];
        if (this.localConfig.titleAttribute) {
          attributesToLoad.push(this.localConfig.titleAttribute);
        }

        const sortOption = `${this.localConfig.sortAttribute}:${this.localConfig.sortOrder}`;

        obsApprovalRequests = this.resource
          .getResourceByQuery(
            query,
            attributesToLoad,
            this.localConfig.limit,
            0,
            false,
            [sortOption]
          )
          .pipe(
            tap((result: ResourceSet) => {
              if (result && result.results && result.results.length > 0) {
                result.results.forEach((r: Resource) => {
                  const appRequest: ApprovalRequest = {
                    displayName: this.utils.ExtraValue(r, 'DisplayName'),
                    objectID: this.utils.ExtraValue(r, 'ObjectID'),
                    status: this.utils.ExtraValue(r, 'RequestStatus'),
                    target: this.utils.ExtraValue(r, 'Target'),
                    creator: this.utils.ExtraValue(r, 'Creator'),
                    requestParameters: [],
                    approvals: [],
                    createdTime: this.utils.ExtraValue(r, 'CreatedTime'),
                    showContent: false,
                    showDetail: false,
                    showResponse: false,
                  };
                  if (this.localConfig.titleAttribute) {
                    appRequest.title = this.utils.ExtraValue(
                      r,
                      this.localConfig.titleAttribute
                    );
                  }
                  this.approvalRequests.push(appRequest);
                });
              }
            }),
            finalize(() => {
              try {
                this.spinner.stopLoader(this.localConfig.name);
              } catch {}
            })
          );
      } else {
        const apiApproval: ApiDef = this.localConfig.showPendingApprovalsOnly
          ? {
              method: 'get',
              path: 'approval/search',
              param: {
                approver: objectID,
              },
            }
          : {
              method: 'get',
              path: 'approval/search',
              param: {
                requestor: objectID,
                approvalStatus: 'rejected',
              },
            };

        obsApprovalRequests = this.resource
          .callApi(apiApproval.method, apiApproval.path, apiApproval.param)
          .pipe(
            tap((result: Array<any>) => {
              console.log(result);
              if (result && result.length > 0) {
                result.forEach((approvalInfo: any) => {
                  const attributeChanges = [];
                  if (approvalInfo.requestParameters) {
                    Object.keys(approvalInfo.requestParameters).forEach(
                      (key: string) => {
                        attributeChanges.push({
                          attribute: key,
                          attributeName: key,
                          action: 'Modify',
                          value: approvalInfo.requestParameters[key],
                        });
                      }
                    );
                  }

                  const responses = [];
                  if (
                    approvalInfo.responses &&
                    approvalInfo.responses.length > 0
                  ) {
                    approvalInfo.responses.forEach((response: any) => {
                      responses.push({
                        objectID: response.objectId,
                        duration: '',
                        status: response.approvalDecision,
                        threshold: 1,
                        approver: response.approver,
                        responses: [],
                        expanded: false,
                      });
                    });
                  }

                  const appRequest: ApprovalRequest = {
                    displayName: approvalInfo.displayName,
                    objectID: approvalInfo.approvalObjectId,
                    title: approvalInfo.approvalTitle,
                    content: approvalInfo.description,
                    status: approvalInfo.approvalDecision,
                    target: approvalInfo.targetId,
                    creator: approvalInfo.requestorId,
                    requestParameters: attributeChanges,
                    approvals: responses,
                    showContent: false,
                    showDetail: false,
                    showResponse: false,
                  };
                  this.approvalRequests.push(appRequest);
                });
              }
            }),
            finalize(() => {
              try {
                this.spinner.stopLoader(this.localConfig.name);
              } catch {}
            })
          );
      }

      this.subscription.add(obsApprovalRequests.subscribe());
    }
  }

  private getResponses(
    approvalID: string
  ): Observable<Array<ApprovalResponse>> {
    return this.resource
      .getResourceByQuery(
        `/Approval[ObjectID='${approvalID}']/ApprovalResponse`,
        ['CreatedTime', 'Creator', 'Decision', 'Reason']
      )
      .pipe(
        switchMap((responses: ResourceSet) => {
          if (responses && responses.results && responses.results.length > 0) {
            const retVal: Array<ApprovalResponse> = [];
            responses.results.forEach((res: Resource) => {
              retVal.push({
                createdTime: this.utils.ExtraValue(res, 'CreatedTime'),
                creator: this.utils.ExtraValue(res, 'Creator'),
                decision: this.utils.ExtraValue(res, 'Decision'),
                reason: this.utils.ExtraValue(res, 'Reason'),
              });
            });
            return of(retVal);
          } else {
            return of([]);
          }
        }),
        catchError(() => {
          return of([]);
        })
      );
  }

  constructor(
    private resource: ResourceService,
    private utils: UtilsService,
    private modal: ModalService,
    private spinner: NgxUiLoaderService,
    private translate: TransService,
    private configService: ConfigService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.initComponent();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  resize() {}

  initComponent() {
    this.localConfig = new ApprovalViewConfig();
    this.utils.CopyInto(this.config, this.localConfig, true, true);

    this.loadApprovalRequests();

    return this.localConfig;
  }

  configure() {
    const configCopy = this.utils.DeepCopy(this.localConfig);

    const dialogRef = this.dialog.open(ApprovalCheckConfigComponent, {
      minWidth: '650px',
      data: {
        component: this,
        config: this.localConfig,
      },
    });

    return dialogRef.afterClosed().pipe(
      tap((result) => {
        if (!result || (result && result === 'cancel')) {
          this.localConfig = configCopy;
        }
        this.updateDataSource();
      }),
      switchMap(() => {
        return of(this.localConfig);
      })
    );
  }

  updateDataSource() {
    this.loadApprovalRequests();
  }

  onApprove(approvalRequest: ApprovalRequest, approve: boolean) {
    let prg: MatDialogRef<ModalComponent, any>;

    if (
      this.utils.matchRegExpressions(
        this.configService.getConfig('approvalReasonExclusions', null),
        approvalRequest
      )
    ) {
      prg = this.modal.show(ModalType.progress, 'key_processing', '', '300px');

      this.subscription.add(
        this.resource
          .approve(approvalRequest.objectID, approve, null)
          .pipe(
            delay(1000),
            finalize(() => {
              if (prg) {
                prg.close();
              }
              this.loadApprovalRequests();
            })
          )
          .subscribe()
      );
    } else {
      const popupConfig: PopupConfig = new PopupConfig();
      popupConfig.title = approve ? 'key_approveRequest' : 'key_rejectRequest';
      popupConfig.style = 'fill';
      popupConfig.singleButton = true;
      popupConfig.confirmButton = approve
        ? {
            text: 'key_approve',
            color: 'primary',
          }
        : {
            text: 'key_reject',
            color: 'warn',
          };
      popupConfig.data = {
        reason: {
          text: 'key_reason',
          value: '',
          type: 'textarea',
          focused: true,
        },
        approve: {
          value: approve,
          visible: false,
        },
      };

      this.subscription.add(
        this.modal
          .popup(popupConfig)
          .pipe(
            switchMap((windowResult: any) => {
              if (!(windowResult instanceof WindowCloseResult)) {
                prg = this.modal.show(
                  ModalType.progress,
                  'key_processing',
                  '',
                  '300px'
                );
                return this.resource
                  .approve(
                    approvalRequest.objectID,
                    approve,
                    windowResult.reason.value
                  )
                  .pipe(
                    delay(1000),
                    finalize(() => {
                      if (prg) {
                        prg.close();
                      }
                      this.loadApprovalRequests();
                    })
                  );
              } else {
                return EMPTY;
              }
            }),
            catchError((error: HttpErrorResponse) => {
              this.modal.show(ModalType.error, 'key_error', error.error);
              if (prg) {
                prg.close();
              }
              return EMPTY;
            })
          )
          .subscribe(
            () => {},
            (err) => {
              console.log(err);
            }
          )
      );
    }
  }

  refresh() {
    this.loadApprovalRequests();
  }

  getParameterType(parameter: RequestParameter): string {
    if (
      parameter.attribute &&
      parameter.attribute.toLowerCase() !== 'objectid' &&
      this.utils.IsGuid(parameter.value)
    ) {
      return 'guid';
    }

    const photoAttributes: string[] = this.configService.getConfig(
      'photoAttributes',
      ['Photo']
    );
    if (
      photoAttributes &&
      parameter.attribute &&
      parameter.value &&
      photoAttributes.findIndex(
        (attribute: string) =>
          attribute.toLowerCase() === parameter.attribute.toLowerCase()
      ) >= 0
    ) {
      return 'bin';
    }

    return 'text';
  }

  onShowResponse(approvalRequest: ApprovalRequest, changeState = true) {
    if (!approvalRequest.showResponse) {
      approvalRequest.showResponse = true;
    } else {
      approvalRequest.showResponse = changeState ? false : true;
    }

    if (
      approvalRequest.showResponse &&
      approvalRequest.approvals.length === 0
    ) {
      this.spinner.startLoader(this.localConfig.name);

      this.subscription.add(
        this.resource
          .getResourceByQuery(
            `/Approval[Request='${approvalRequest.objectID}']`,
            [
              'ApprovalDuration',
              'ApprovalStatus',
              'ApprovalThreshold',
              'Approver',
            ]
          )
          .pipe(
            tap((approvals: ResourceSet) => {
              if (
                approvals &&
                approvals.results &&
                approvals.results.length > 0
              ) {
                approvals.results.forEach((res: Resource) => {
                  approvalRequest.approvals.push({
                    objectID: this.utils.ExtraValue(res, 'ObjectID'),
                    duration: this.utils.ExtraValue(res, 'ApprovalDuration'),
                    status: this.utils.ExtraValue(res, 'ApprovalStatus'),
                    threshold: this.utils.ExtraValue(res, 'ApprovalThreshold'),
                    approver: this.utils.ExtraValue(res, 'Approver'),
                    responses: this.getResponses(
                      this.utils.ExtraValue(res, 'ObjectID')
                    ),
                    expanded: false,
                  });
                });
              }
            }),
            catchError((error: HttpErrorResponse) => {
              this.modal.show(ModalType.error, 'key_error', error.error);
              try {
                this.spinner.stopLoader(this.localConfig.name);
              } catch {}
              return EMPTY;
            }),
            finalize(() => {
              try {
                this.spinner.stopLoader(this.localConfig.name);
              } catch {}
            })
          )
          .subscribe()
      );
    }
  }

  onShowDetail(approvalRequest: ApprovalRequest, changeState = true) {
    if (!approvalRequest.showDetail) {
      approvalRequest.showDetail = true;
    } else {
      approvalRequest.showDetail = changeState ? false : true;
    }

    if (
      approvalRequest.showDetail &&
      approvalRequest.requestParameters.length === 0
    ) {
      this.spinner.startLoader(this.localConfig.name);

      const parser: xml2js.Parser = new xml2js.Parser();
      let theRequest: AnyProperties;
      this.subscription.add(
        this.resource
          .getResourceByID(
            approvalRequest.objectID,
            ['RequestParameter', 'Target'],
            'simple',
            this.configService.getCulture(this.translate.currentCulture),
            'true'
          )
          .pipe(
            switchMap((res: AnyProperties) => {
              if (res) {
                theRequest = res;
                if (res.Target && res.Target.ObjectType) {
                  return this.resource.getType(
                    res.Target.ObjectType,
                    this.configService.getCulture(this.translate.currentCulture)
                  );
                } else {
                  let theType: string;
                  res.RequestParameter.forEach((p: string) => {
                    parser.parseString(p, (err: any, result: any) => {
                      if (!err) {
                        const sysName: string =
                          result.RequestParameter.PropertyName[0];
                        if (sysName && sysName.toLowerCase() === 'objecttype') {
                          theType = result.RequestParameter.Value[0]._;
                        }
                      }
                    });
                  });
                  if (theType) {
                    return this.resource.getType(
                      theType,
                      this.configService.getCulture(
                        this.translate.currentCulture
                      )
                    );
                  } else {
                    return EMPTY;
                  }
                }
              } else {
                return EMPTY;
              }
            }),
            tap((schema) => {
              if (schema) {
                theRequest.RequestParameter.forEach((p: string) => {
                  parser.parseString(p, (err: any, result: any) => {
                    if (!err) {
                      const sysName = result.RequestParameter.PropertyName[0];
                      let disName = sysName;
                      if (schema[sysName] && schema[sysName].displayName) {
                        disName = schema[sysName].displayName;
                      }
                      approvalRequest.requestParameters.push({
                        attribute: sysName,
                        attributeName: disName,
                        action:
                          result.RequestParameter.Mode &&
                          result.RequestParameter.Mode.length > 0
                            ? result.RequestParameter.Mode[0]
                            : result.RequestParameter.Operation[0],
                        value: result.RequestParameter.Value[0]._,
                      });
                    }
                  });
                });
                if (this.localConfig.contentAttribute) {
                  const contentAttribute =
                    approvalRequest.requestParameters.find((p) => {
                      return p.attribute === this.localConfig.contentAttribute;
                    });
                  if (contentAttribute) {
                    approvalRequest.content = contentAttribute.value;
                  }
                }
              }
            }),
            catchError((error: HttpErrorResponse) => {
              this.modal.show(ModalType.error, 'key_error', error.error);
              try {
                this.spinner.stopLoader(this.localConfig.name);
              } catch {}
              return EMPTY;
            }),
            finalize(() => {
              try {
                this.spinner.stopLoader(this.localConfig.name);
              } catch {}
            })
          )
          .subscribe()
      );
    }
  }

  onExpandRequest(approvalRequest: ApprovalRequest) {
    approvalRequest.showContent = !approvalRequest.showContent;
    if (approvalRequest.showContent) {
      switch (this.localConfig.defaultContent) {
        case 'detail':
          this.onShowDetail(approvalRequest, false);
          break;
        case 'response':
          this.onShowResponse(approvalRequest, false);
          break;
        default:
          break;
      }
    }
  }

  onShowPhoto(data: string) {
    const popupConfig: PopupConfig = new PopupConfig();
    popupConfig.noButton = true;
    popupConfig.data = {
      photo: {
        value: data,
        type: 'image',
      },
    };
    this.subscription.add(this.modal.popup(popupConfig).subscribe());
  }
}
