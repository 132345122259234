import { Component, OnInit, ViewChild } from '@angular/core';
import { EChartsOption } from 'echarts';
import { BroadcastEvent } from '../../models/dataContract.model';
import { ConfigService } from '../../services/config.service';
import { SwapService } from '../../services/swap.service';
import { ObjectExplorerComponent } from '../object-explorer/object-explorer.component';

@Component({
  selector: 'app-tags-explorer',
  templateUrl: './tags-explorer.component.html',
  styleUrls: ['./tags-explorer.component.scss'],
})
export class TagsExplorerComponent implements OnInit {
  @ViewChild('objExplorer')
  objExplorer: ObjectExplorerComponent;

  linkAttributesToLoad = [
    'DisplayName',
    'ocgParentRef',
    'requestorXpathTemplate',
    'resourceBeforeXpathTemplate',
    'resourceAfterXpathTemplate',
    'workflows',
  ];

  options: EChartsOption;

  categories = [];
  query: string;

  tagName = '';
  highlightTag = '';

  constructor(private config: ConfigService, private swap: SwapService) {}

  ngOnInit(): void {
    console.log();
  }

  onChartClick(event: any) {
    if (event && event.data && event.data.id && event.data.type) {
      this.swap.linkEvent(
        new BroadcastEvent(
          'sideView',
          {
            ObjectID: event.data.id,
            ObjectType: event.data.type,
          },
          {
            noneForm: false,
            readOnly: false,
            scope: '',
          }
        )
      );
    }
  }

  onBuildChart() {
    if (this.objExplorer) {
      this.query = `/*[tags='${this.tagName}']`;
      setTimeout(() => {
        this.objExplorer.refresh();
      });
    }
  }

  onSetCategories(event: any) {
    this.categories = event;
  }
}
