<div fxLayout="column" fxLayoutAlign="start center" class="reports-view">
  <gridster [options]="gdOptions" class="gridster">
    <gridster-item
      *ngFor="let item of gdItems"
      [item]="item"
      [ngClass]="{ 'config-hidden': !hasPermission(item) }"
      style="background-color: transparent"
    >
      <div
        *ngIf="gdOptions.draggable.enabled === true || hasPermission(item)"
        style="height: 100%"
      >
        <div
          *ngIf="gdOptions.draggable.enabled === true"
          style="
            position: absolute;
            right: 5px;
            top: 5px;
            z-index: 10;
            height: 40px;
            width: 40px;
          "
        >
          <button
            mat-mini-fab
            [style.background]="buttonColor"
            (click)="onGridsterDelete($event, item)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <div
          *ngIf="gdOptions.draggable.enabled === true"
          style="
            position: absolute;
            right: 65px;
            top: 5px;
            z-index: 10;
            height: 40px;
            widows: 40px;
          "
        >
          <button
            mat-mini-fab
            [style.background]="buttonColor"
            (click)="onGridsterConfig($event, item)"
          >
            <mat-icon>settings</mat-icon>
          </button>
        </div>
        <app-report-card
          #reportCard
          *ngIf="item.componentType === 'report' || item.componentType === ''"
          [config]="item.componentConfig"
        ></app-report-card>
        <app-report-separator
          #reportSeparator
          *ngIf="item.componentType === 'separator'"
          [config]="item.componentConfig"
        ></app-report-separator>
      </div>
    </gridster-item>
  </gridster>
</div>
