<form
  autocomplete="off"
  #activityForm="ngForm"
  (keydown.enter)="onEnterKey($event)"
  (keydown.shift.enter)="onEnterKey($event)"
>
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start start" style="margin-bottom: 10px">
      <app-editor-text
        fxFlex="50"
        style="margin-right: 20px"
        name="txtApprovalTitle"
        [(ngModel)]="approvalTitle"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          hintLimit: 50,
          customDisplayName: 'key_title',
          customDescription: 'key_titleDesManualTask',
          updateOn: updateOnBlure,
          validationKey: 'workflow/approval/approvalTitle'
        }"
        appExpressionValidator
      ></app-editor-text>
      <app-editor-text
        name="txtFormId"
        fxFlex="50"
        [creationMode]="true"
        [(ngModel)]="formId"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          required: true,
          hintLimit: 50,
          customDisplayName: 'key_formId',
          customDescription: 'key_formIdDesManualTask',
          updateOn: updateOnBlure,
          validationKey: 'workflow/manualTask/formId'
        }"
        appExpressionValidator
      ></app-editor-text>
    </div>
    <!-- <div
      fxLayout="row"
      fxLayoutAlign="start center"
      style="margin-bottom: 10px;"
    >
      <mat-checkbox
        fxFlex="50"
        style="margin-right: 20px"
        [(ngModel)]="showFormImmediately"
        [ngModelOptions]="{ standalone: true }"
        >{{ 'key_showFormImmediately' | translate }}</mat-checkbox
      >
      <mat-checkbox
        fxFlex="50"
        [(ngModel)]="doNotWaitForResponse"
        [ngModelOptions]="{ standalone: true }"
        >{{ 'key_doNotWaitForResponse' | translate }}</mat-checkbox
      >
    </div> -->
    <div fxLayout="row" fxLayoutAlign="start start" style="margin-bottom: 10px">
      <app-editor-text
        name="txtTaskType"
        fxFlex="50"
        style="margin-right: 20px; margin-top: 2.5px"
        [creationMode]="true"
        [(ngModel)]="taskType"
        [config]="{
          isSimpleValue: true,
          required: true,
          hintLimit: 50,
          customDisplayName: 'key_taskType',
          customDescription: 'key_taskTypeDesManualTask'
        }"
      ></app-editor-text>
      <app-editor-text
        fxFlex="50"
        name="txtTaskStartMessage"
        [(ngModel)]="taskStartMessage"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          hintLimit: 50,
          customDisplayName: 'key_taskStartMessage',
          customDescription: 'key_taskStartMessageDesManualTask',
          updateOn: updateOnBlure,
          validationKey: 'workflow/manualTask/taskStartMessage'
        }"
        appExpressionValidator
      ></app-editor-text>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start">
      <app-editor-text
        fxFlex="50"
        style="margin-right: 20px"
        name="txtEmergencyApprovers"
        [(ngModel)]="emergencyApprovers"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          hintLimit: 50,
          customDisplayName: 'key_emergencyRecipients',
          customDescription: 'key_emergencyRecipientsDesManualTask',
          updateOn: updateOnBlure,
          validationKey: 'workflow/approval/emergencyapprovers'
        }"
        appExpressionValidator
      ></app-editor-text>
      <app-editor-text
        fxFlex="50"
        name="txtApprovalThreshold"
        [(ngModel)]="approvalThreshold"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          hintLimit: 50,
          customDisplayName: 'key_threshold',
          customDescription: 'key_thresholdDesManualTask',
          updateOn: updateOnBlure,
          validationKey: 'workflow/approval/approvalthreshold'
        }"
        appExpressionValidator
      ></app-editor-text>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" style="margin-top: 10px">
      <app-editor-text
        fxFlex="50"
        style="margin-right: 20px"
        name="txtResponseKey"
        [(ngModel)]="responseKey"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          hintLimit: 50,
          customDisplayName: 'key_responseKey',
          customDescription: 'key_responseKeyDesManualTask',
          updateOn: updateOnBlure,
          validationKey: 'workflow/manualtask/responsekey'
        }"
        appExpressionValidator
      ></app-editor-text>
      <div fxFlex="50"></div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 20px">
      <button
        mat-stroked-button
        color="primary"
        (click)="onAddApprovalStage()"
        matTooltip="{{ 'key_addApprovalStageDesManualTask' | translate }}"
      >
        {{ 'key_addApprovalStage' | translate }}
      </button>
    </div>
    <div
      *ngIf="approversByStage && approversByStage.length > 0"
      class="background-bg-color"
      style="margin-top: 10px; padding: 10px; border-radius: 5px"
    >
      <div
        *ngFor="let stage of stageDic; let i = index"
        fxLayout="row"
        fxLayoutAlign="start start"
        style="margin-bottom: 10px"
      >
        <div
          fxFlex="none"
          class="stage-number accent-bg-color accent-contrast-color"
        >
          {{ i + 1 }}
        </div>
        <app-editor-text
          fxFlex="60"
          style="margin-right: 20px"
          [name]="'txtApproverByStage' + i"
          [(ngModel)]="approversByStage[i]"
          [config]="{
            isSimpleValue: true,
            isCode: true,
            hintLimit: 50,
            customDisplayName: 'key_recipient',
            updateOn: updateOnBlure,
            validationKey: 'workflow/approval/approversByStage'
          }"
          appExpressionValidator
        ></app-editor-text>
        <app-editor-text
          fxFlex="40"
          style="margin-right: 20px"
          [name]="'txtDurationByStage' + i"
          [(ngModel)]="durationByStage[i]"
          [config]="{
            isSimpleValue: true,
            isCode: true,
            hintLimit: 50,
            customDisplayName: 'key_durationInDays',
            updateOn: updateOnBlure,
            validationKey: 'workflow/approval/durationbystage'
          }"
          appExpressionValidator
        ></app-editor-text>
        <button
          mat-icon-button
          color="warn"
          fxFlex="none"
          [matTooltip]="'key_delete' | translate"
          style="margin-top: 6px"
          (click)="onRemoveApprovalStage(i)"
        >
          <span class="material-icons-outlined">delete</span>
        </button>
      </div>
    </div>
    <div style="margin-top: 20px">
      <app-editor-iterables
        #itrFormData="ngModel"
        name="itrFormData"
        [config]="configFormData"
        [ngModel]="attrFormData"
        (valueChange)="onFormDataChange()"
      ></app-editor-iterables>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      style="margin-top: 30px; margin-bottom: 10px"
    >
      <app-editor-text
        fxFlex="100"
        style="margin-right: 20px"
        name="txtEtPending"
        [(ngModel)]="pendingApprovalEmailTemplate"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          hintLimit: 100,
          customDisplayName: 'key_emailTemplatePendingManualTask',
          customDescription: 'key_emailTemplatePendingManualTaskDes',
          updateOn: updateOnBlure,
          validationKey: 'workflow/approval/pendingapprovalemailtemplate'
        }"
        appExpressionValidator
      ></app-editor-text>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      style="margin-top: 10px; margin-bottom: 10px"
    >
      <app-editor-text
        fxFlex="100"
        style="margin-right: 20px"
        name="txtEtEscalation"
        [(ngModel)]="pendingApprovalEscalationEmailTemplate"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          hintLimit: 100,
          customDisplayName: 'key_emailTemplateEscalationManualTask',
          customDescription: 'key_emailTemplateEscalationManualTaskDes',
          updateOn: updateOnBlure,
          validationKey:
            'workflow/approval/pendingapprovalescalationemailtemplate'
        }"
        appExpressionValidator
      ></app-editor-text>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      style="margin-top: 10px; margin-bottom: 10px"
    >
      <app-editor-text
        fxFlex="100"
        style="margin-right: 20px"
        name="txtEtCompleted"
        [(ngModel)]="completedApprovalEmailTemplate"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          hintLimit: 100,
          customDisplayName: 'key_emailTemplateCompletedManualTask',
          customDescription: 'key_emailTemplateCompletedManualTaskDes',
          updateOn: updateOnBlure,
          validationKey: 'workflow/approval/completedapprovalemailtemplate'
        }"
        appExpressionValidator
      ></app-editor-text>
    </div>
    <!-- <div
      fxLayout="row"
      fxLayoutAlign="start center"
      style="margin-top: 10px; margin-bottom: 10px"
    >
      <app-editor-text
        fxFlex="100"
        style="margin-right: 20px"
        name="txtEtRejected"
        [(ngModel)]="rejectedRequestEmailTemplate"
        [config]="{
          isSimpleValue: true,
          hintLimit: 100,
          customDisplayName: 'key_emailTemplateRejected',
          updateOn: updateOnBlure,
          validationKey: 'workflow/approval/rejectedrequestemailtemplate'
        }"
        appExpressionValidator
      ></app-editor-text>
    </div> -->
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      style="margin-top: 10px; margin-bottom: 10px"
    >
      <app-editor-text
        fxFlex="100"
        style="margin-right: 20px"
        name="txtEtTimeout"
        [(ngModel)]="timedOutRequestEmailTemplate"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          hintLimit: 100,
          customDisplayName: 'key_emailTemplateTimedOut',
          customDescription: 'key_emailTemplateTimedOutDes',
          updateOn: updateOnBlure,
          validationKey: 'workflow/approval/timedoutrequestemailtemplate'
        }"
        appExpressionValidator
      ></app-editor-text>
    </div>
  </div>
</form>
