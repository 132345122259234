import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditorCreationConfig } from '../../models/editorContract.model';

@Component({
  selector: 'app-editor-creator',
  templateUrl: './editor-creator.component.html',
  styleUrls: ['./editor-creator.component.scss'],
})
export class EditorCreatorComponent {
  config = new EditorCreationConfig();

  types = [
    { text: 'Textbox', value: 'text' },
    { text: 'Checkbox', value: 'boolean' },
    { text: 'Select', value: 'select' },
    { text: 'Date', value: 'date' },
    { text: 'Identity-Picker', value: 'identity' },
    { text: 'Identity-List', value: 'identities' },
    { text: 'Button', value: 'button' },
    { text: 'XPath-Editor', value: 'xpath' },
    { text: 'Separator', value: 'separator' },
    { text: 'Frame', value: 'frame' },
    { text: 'Iterables', value: 'iterables' },
    { text: 'Object', value: 'object' },
    { text: 'For-each', value: 'foreach' },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}
}
