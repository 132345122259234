<div
  *ngIf="mode === 'resolver'"
  [innerHTML]="infoText | translate"
  class="resolver-info-text"
></div>
<div style="margin: 5px 15px 20px 35px">
  <mat-form-field
    *ngIf="mode === 'resolver'"
    fxFlex="auto"
    appearance="outline"
    style="font-size: 12px; margin-bottom: -16px; margin-right: 20px"
  >
    <mat-label>{{ 'key_reasonAndAction' | translate }}</mat-label>
    <input
      matInput
      [(ngModel)]="summaryComment"
      (change)="onSummaryResolve()"
    />
  </mat-form-field>
  <mat-button-toggle-group
    *ngIf="mode === 'resolver'"
    fxFlex="none"
    [(ngModel)]="summaryDecision"
    [disabled]="!summaryComment"
    (change)="onSummaryResolve()"
    style="font-size: 0.85em; height: 42px; margin-top: 4px"
  >
    <mat-button-toggle value="approve">
      <div fxLayout="row" fxLayoutAlign="center center" style="height: 40px">
        <div
          *ngIf="summaryDecision === 'approve'"
          fxFlex="none"
          style="height: 35px; margin-right: 5px"
        >
          <span class="material-icons-outlined" style="line-height: 35px"
            >done</span
          >
        </div>
        <div fxFlex="auto">{{ 'key_accept' | translate }}</div>
      </div>
    </mat-button-toggle>
    <mat-button-toggle value="escalate">
      <div fxLayout="row" fxLayoutAlign="center center" style="height: 40px">
        <div
          *ngIf="summaryDecision === 'escalate'"
          fxFlex="none"
          style="height: 35px; margin-right: 5px"
        >
          <span class="material-icons-outlined" style="line-height: 35px"
            >done</span
          >
        </div>
        <div fxFlex="auto">{{ 'key_escalate' | translate }}</div>
      </div>
    </mat-button-toggle>
    <mat-button-toggle value="reject">
      <div fxLayout="row" fxLayoutAlign="center center" style="height: 40px">
        <div
          *ngIf="summaryDecision === 'reject'"
          fxFlex="none"
          style="height: 35px; margin-right: 5px"
        >
          <span class="material-icons-outlined" style="line-height: 35px"
            >done</span
          >
        </div>
        <div fxFlex="auto">{{ 'key_reject' | translate }}</div>
      </div>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <div
    *ngIf="mode === 'attestation'"
    fxFlex="auto"
    style="line-height: 45px; font-size: 1.15em"
    [innerHtml]="infoText | translate"
  ></div>
  <div *ngIf="mode === 'info'" fxFlex="auto">
    <mat-slide-toggle
      [(ngModel)]="infoConfirmed"
      (change)="onInfoConfirmation()"
      style="margin-top: 10px; font-size: 1.15em"
    >
      <div [innerHtml]="infoText | translate" style="margin-left: 10px"></div>
    </mat-slide-toggle>
  </div>
  <button
    mat-icon-button
    color="primary"
    style="margin-top: 5px; margin-left: 20px"
    [matTooltip]="'key_expandAll' | translate"
    (click)="onExpandAll()"
  >
    <mat-icon>unfold_more</mat-icon>
  </button>
  <button
    mat-icon-button
    color="primary"
    style="margin-top: 5px; margin-left: 10px"
    [matTooltip]="'key_collapseAll' | translate"
    (click)="onCollapseAll()"
  >
    <mat-icon>unfold_less</mat-icon>
  </button>
</div>
<div
  class="full-size-relative"
  [style.height.px]="contentHeight"
  style="overflow-y: auto; padding-right: 10px"
>
  <div *ngFor="let conflictItem of conflicts" style="margin-bottom: 20px">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div
        fxFlex="none"
        class="navigation-arrow"
        (click)="conflictItem.showdetail = !conflictItem.showdetail"
        style="cursor: pointer"
      >
        <span class="material-icons-outlined">{{
          conflictItem.showdetail ? 'expand_more' : 'navigate_next'
        }}</span>
      </div>
      <div fxFlex="auto">
        <div
          (click)="conflictItem.showdetail = !conflictItem.showdetail"
          style="cursor: pointer"
        >
          {{ conflictItem.target | extraValue: 'displayName' }}
        </div>
        <div class="id-field">
          {{ conflictItem.target | extraValue: 'objectId' }}
        </div>
      </div>
    </div>
    <div *ngIf="conflictItem.showdetail">
      <div *ngIf="conflictItem.roles" class="sod-field">
        <div *ngFor="let role of conflictItem.roles" style="margin-top: 10px">
          <div
            *ngIf="!role.ishidden"
            fxLayout="row"
            fxlayoutAlign="center center"
          >
            <div
              fxFlex="50"
              class="conflict-item"
              [style.border-color]="getConflictColor(role, 'normal')"
            >
              <div fxLayout="row">
                <div fxFlex="auto">
                  <div
                    style="cursor: pointer"
                    (click)="onShowRoleExplorer(role, conflictItem.target)"
                  >
                    {{ role | extraValue: 'displayName' }}
                  </div>
                  <div class="id-field">
                    {{ role | extraValue: 'objectId' }}
                  </div>
                </div>
                <div
                  fxFlex="none"
                  [matTooltip]="
                    (role.isdirect ? 'key_directRole' : 'key_inheritedRole')
                      | translate
                  "
                >
                  <span class="material-icons-outlined role-lock">{{
                    role.isdirect ? 'lock_open' : 'lock'
                  }}</span>
                </div>
              </div>
            </div>
            <div
              *ngIf="mode === 'attestation'"
              fxFlex="none"
              class="attestation-option-line"
              [style.border-color]="getConflictColor(role, 'normal')"
            ></div>
            <div
              *ngIf="mode === 'attestation'"
              fxFlex="50"
              class="attestation-option"
              [style.border-color]="getConflictColor(role, 'normal')"
            >
              <div fxLayout="row">
                <mat-form-field
                  fxFlex="auto"
                  appearance="outline"
                  style="
                    font-size: 12px;
                    margin-bottom: -16px;
                    margin-right: 20px;
                  "
                >
                  <mat-label>{{ 'key_reasonAndAction' | translate }}</mat-label>
                  <input
                    matInput
                    [(ngModel)]="role.comment"
                    (change)="onAttest(role, conflictItem)"
                  />
                </mat-form-field>
                <div fxFlex="240px">
                  <mat-button-toggle-group
                    [(ngModel)]="role.decision"
                    [disabled]="!role.comment"
                    (change)="onAttest(role, conflictItem)"
                    style="font-size: 0.85em; height: 42px; margin-top: 5px"
                  >
                    <mat-button-toggle value="approve">
                      <div
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        style="height: 40px"
                      >
                        <div
                          *ngIf="role.decision === 'approve'"
                          fxFlex="none"
                          style="height: 35px; margin-right: 5px"
                        >
                          <span
                            class="material-icons-outlined"
                            style="line-height: 35px"
                            >done</span
                          >
                        </div>
                        <div fxFlex="auto">{{ 'key_accept' | translate }}</div>
                      </div>
                    </mat-button-toggle>
                    <mat-button-toggle value="escalate">
                      <div
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        style="height: 40px"
                      >
                        <div
                          *ngIf="role.decision === 'escalate'"
                          fxFlex="none"
                          style="height: 35px; margin-right: 5px"
                        >
                          <span
                            class="material-icons-outlined"
                            style="line-height: 35px"
                            >done</span
                          >
                        </div>
                        <div fxFlex="auto">
                          {{ 'key_escalate' | translate }}
                        </div>
                      </div>
                    </mat-button-toggle>
                    <mat-button-toggle value="reject">
                      <div
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        style="height: 40px"
                      >
                        <div
                          *ngIf="role.decision === 'reject'"
                          fxFlex="none"
                          style="height: 35px; margin-right: 5px"
                        >
                          <span
                            class="material-icons-outlined"
                            style="line-height: 35px"
                            >done</span
                          >
                        </div>
                        <div fxFlex="auto">{{ 'key_reject' | translate }}</div>
                      </div>
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!role.ishidden && role.showexplorer" fxLayout="row">
            <div fxFlex="50px"></div>
            <div fxFlex="auto" class="object-explorer-line-left"></div>
            <div fxFlex="60%" class="object-explorer">
              <app-object-explorer
                #objExplorer
                [title]="' '"
                [query]="oeQuery"
                [travelQuery]="oeTravelQuery"
                [linkedAttributes]="oeLinkedAttributes"
                [height]="oeHeight"
                [showPlaceHolder]="false"
                [highlightCategories]="['ocgrole', 'person']"
              ></app-object-explorer>
            </div>
            <div fxFlex="auto"></div>
            <div fxFlex="50px"></div>
          </div>
        </div>
      </div>
      <div *ngIf="conflictItem.sods" class="sod-field">
        <div
          *ngFor="let conflict of conflictItem.sods"
          style="margin-top: 10px"
        >
          <div *ngIf="!conflict.ishidden">
            <div fxLayout="row" fxlayoutAlign="center center">
              <div
                fxFlex="50"
                class="conflict-item"
                [style.border-color]="getConflictColor(conflict)"
              >
                <div
                  style="cursor: pointer"
                  (click)="
                    onShowConflictExplorer(
                      conflict,
                      conflictItem.target,
                      'left'
                    )
                  "
                >
                  {{ conflict.conflictingobject1 | extraValue: 'displayName' }}
                </div>
                <div class="id-field">
                  {{ conflict.conflictingobject1 | extraValue: 'objectId' }}
                </div>
              </div>
              <div
                fxFlex="none"
                class="conflict-option-line"
                [style.border-color]="getConflictColor(conflict)"
              ></div>
              <div
                fxFlex="none"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="conflict-option"
                [style.border-color]="getConflictColor(conflict)"
                [matTooltip]="conflict.conflictname"
                (click)="onShowResolver(conflict)"
              >
                <span
                  class="material-icons-outlined"
                  style="font-size: 32px"
                  [style.color]="getConflictColor(conflict)"
                  >{{ getConflictIcon(conflict) }}</span
                >
              </div>
              <div
                fxFlex="none"
                class="conflict-option-line"
                [style.border-color]="getConflictColor(conflict)"
              ></div>
              <div
                fxFlex="50"
                class="conflict-item"
                [style.border-color]="getConflictColor(conflict)"
              >
                <div
                  style="cursor: pointer"
                  (click)="
                    onShowConflictExplorer(
                      conflict,
                      conflictItem.target,
                      'right'
                    )
                  "
                >
                  {{ conflict.conflictingobject2 | extraValue: 'displayName' }}
                </div>
                <div class="id-field">
                  {{ conflict.conflictingobject2 | extraValue: 'objectId' }}
                </div>
              </div>
            </div>
            <div *ngIf="conflict.showexplorer" fxLayout="row">
              <div fxFlex="50px"></div>
              <div
                fxFlex="auto"
                [ngClass]="{
                  'object-explorer-line-left':
                    conflict.explorerContent === 'left'
                }"
              ></div>
              <div fxFlex="60%" class="object-explorer">
                <app-object-explorer
                  #objExplorer
                  [title]="' '"
                  [query]="oeQuery"
                  [travelQuery]="oeTravelQuery"
                  [linkedAttributes]="oeLinkedAttributes"
                  [height]="oeHeight"
                  [showPlaceHolder]="false"
                  [highlightCategories]="['ocgrole', 'person']"
                ></app-object-explorer>
              </div>
              <div
                fxFlex="auto"
                [ngClass]="{
                  'object-explorer-line-right':
                    conflict.explorerContent === 'right'
                }"
              ></div>
              <div fxFlex="50px"></div>
            </div>
            <div
              *ngIf="conflict.showresolver"
              class="conflict-reolver-line"
            ></div>
            <div *ngIf="conflict.showresolver" fxLayout="row">
              <div fxFlex="auto"></div>
              <div fxFlex="60%" class="conflict-resolver">
                <div>{{ conflict.conflictname }}</div>
                <div class="id-field">{{ conflict.conflictdescription }}</div>
                <div
                  *ngIf="mode === 'info' && conflict.existNoMore"
                  class="no-more-conflict"
                >
                  <div style="margin-right: 5px">
                    {{ 'key_noMoreConflict' | translate }}
                  </div>
                </div>
                <div
                  *ngIf="
                    mode === 'info' &&
                    !conflict.existNoMore &&
                    conflict.previoususer &&
                    conflict.previouscomment
                  "
                  fxLayout="row"
                  class="previous-comment"
                >
                  <div style="margin-right: 5px">
                    {{ 'key_commentFrom' | translate }}
                  </div>
                  <div style="margin-right: 5px">
                    {{ conflict.previoususer | extraValue: 'displayName' }}:
                  </div>
                  <q>{{ conflict.previouscomment }}</q>
                </div>
                <div
                  *ngIf="mode === 'attestation'"
                  fxLayout="row wrap"
                  fxLayoutAlign="start center"
                  style="margin-top: 10px; margin-bottom: 5px"
                >
                  <mat-form-field
                    fxFlex="auto"
                    appearance="outline"
                    style="
                      font-size: 12px;
                      margin-bottom: -16px;
                      margin-right: 20px;
                    "
                  >
                    <mat-label>{{
                      'key_reasonAndAction' | translate
                    }}</mat-label>
                    <input
                      matInput
                      [(ngModel)]="conflict.comment"
                      (change)="onResolve()"
                    />
                  </mat-form-field>
                  <mat-button-toggle-group
                    fxFlex="none"
                    [(ngModel)]="conflict.decision"
                    [disabled]="!conflict.comment"
                    (change)="onResolve()"
                    style="font-size: 0.85em; height: 42px"
                  >
                    <mat-button-toggle value="approve">
                      <div
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        style="height: 40px"
                      >
                        <div
                          *ngIf="conflict.decision === 'approve'"
                          fxFlex="none"
                          style="height: 35px; margin-right: 5px"
                        >
                          <span
                            class="material-icons-outlined"
                            style="line-height: 35px"
                            >done</span
                          >
                        </div>
                        <div fxFlex="auto">{{ 'key_accept' | translate }}</div>
                      </div>
                    </mat-button-toggle>
                    <mat-button-toggle value="escalate">
                      <div
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        style="height: 40px"
                      >
                        <div
                          *ngIf="conflict.decision === 'escalate'"
                          fxFlex="none"
                          style="height: 35px; margin-right: 5px"
                        >
                          <span
                            class="material-icons-outlined"
                            style="line-height: 35px"
                            >done</span
                          >
                        </div>
                        <div fxFlex="auto">
                          {{ 'key_escalate' | translate }}
                        </div>
                      </div>
                    </mat-button-toggle>
                    <mat-button-toggle value="reject">
                      <div
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        style="height: 40px"
                      >
                        <div
                          *ngIf="conflict.decision === 'reject'"
                          fxFlex="none"
                          style="height: 35px; margin-right: 5px"
                        >
                          <span
                            class="material-icons-outlined"
                            style="line-height: 35px"
                            >done</span
                          >
                        </div>
                        <div fxFlex="auto">{{ 'key_reject' | translate }}</div>
                      </div>
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                  <div
                    *ngIf="conflict.decisioncreator"
                    fxFlex="100%"
                    fxLayout="row"
                    style="font-size: 0.85em; opacity: 0.6"
                  >
                    <div style="margin-right: 5px">
                      {{ 'key_acceptedBy' | translate }}
                    </div>
                    <div>
                      {{ conflict.decisioncreator | extraValue: 'displayName' }}
                    </div>
                    <div style="margin-left: 10px">
                      {{ conflict | extraValue: 'decisionDate' }}
                    </div>
                  </div>
                </div>
              </div>
              <div fxFlex="auto"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
