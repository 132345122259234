<div
  fxFlex="auto"
  fxLayout="row"
  *ngIf="configMode || showEditor(resource.rightSets)"
  [ngClass]="{ 'config-hidden': configMode && !showEditor(resource.rightSets) }"
  [fxLayoutAlign]="config.align"
  style="width: 100%; margin-bottom: -6px"
>
  <button
    *ngIf="config.style === 'basic'"
    mat-button
    [disabled]="disabled(resource.rightSets)"
    [color]="config.color"
    [style.fontSize.px]="config.fontSize"
    [style.lineHeight.px]="config.fontSize + 20"
    [style.width.%]="config.stretch ? 100 : null"
    (click)="onClick()"
    [matTooltip]="config.description | translate"
  >
    <mat-icon
      *ngIf="config.icon"
      [style.fontSize.px]="config.fontSize + 4"
      style="margin-right: 2px; vertical-align: text-top"
      >{{ config.icon }}
    </mat-icon>
    <span>{{ config.text | translate }}</span>
  </button>
  <button
    *ngIf="config.style === 'raised'"
    mat-raised-button
    [disabled]="disabled(resource.rightSets)"
    [color]="config.color"
    [style.fontSize.px]="config.fontSize"
    [style.lineHeight.px]="config.fontSize + 20"
    [style.width.%]="config.stretch ? 100 : null"
    (click)="onClick()"
    [matTooltip]="config.description | translate"
  >
    <mat-icon
      *ngIf="config.icon"
      [style.fontSize.px]="config.fontSize + 4"
      style="margin-right: 2px; vertical-align: text-top"
      >{{ config.icon }}
    </mat-icon>
    <span>{{ config.text | translate }}</span>
  </button>
  <button
    *ngIf="config.style === 'stroked'"
    mat-stroked-button
    [disabled]="disabled(resource.rightSets)"
    [color]="config.color"
    [style.fontSize.px]="config.fontSize"
    [style.lineHeight.px]="config.fontSize + 20"
    [style.width.%]="config.stretch ? 100 : null"
    (click)="onClick()"
    [matTooltip]="config.description | translate"
  >
    <mat-icon
      *ngIf="config.icon"
      [style.fontSize.px]="config.fontSize + 4"
      style="margin-right: 2px; vertical-align: text-top"
      >{{ config.icon }}
    </mat-icon>
    <span>{{ config.text | translate }}</span>
  </button>
  <button
    *ngIf="config.style === 'flat'"
    mat-flat-button
    [disabled]="disabled(resource.rightSets)"
    [color]="config.color"
    [style.fontSize.px]="config.fontSize"
    [style.lineHeight.px]="config.fontSize + 20"
    [style.width.%]="config.stretch ? 100 : null"
    (click)="onClick()"
    [matTooltip]="config.description | translate"
  >
    <mat-icon
      *ngIf="config.icon"
      [style.fontSize.px]="config.fontSize + 4"
      style="margin-right: 2px; vertical-align: text-top"
      >{{ config.icon }}
    </mat-icon>
    <span>{{ config.text | translate }}</span>
  </button>
  <button
    *ngIf="config.style === 'icon'"
    mat-icon-button
    [disabled]="disabled(resource.rightSets)"
    [color]="config.color"
    [style.fontSize.px]="config.fontSize"
    (click)="onClick()"
    [matTooltip]="config.description | translate"
  >
    <mat-icon [style.fontSize.px]="config.fontSize + 4"
      >{{ config.icon ? config.icon : 'menu' }}
    </mat-icon>
  </button>
</div>
