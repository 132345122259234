import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IdentityEditorConfig } from 'src/app/core/models/editorContract.model';
import { UtilsService } from 'src/app/core/services/utils.service';
import { EditorIdentityComponent } from '../../editor-identity/editor-identity.component';
import { WorkflowActivityView } from 'src/app/core/models/dynamicEditor.interface';

@Component({
  selector: 'app-activity-dataflow',
  templateUrl: './activity-dataflow.component.html',
  styleUrls: ['./activity-dataflow.component.scss'],
})
export class ActivityDataflowComponent
  extends WorkflowActivityView
  implements AfterViewInit
{
  @ViewChild('idpDataflow')
  idpDataflow: EditorIdentityComponent;

  flowId: string;
  @Input()
  get dataflowRuleId() {
    return this.flowId;
  }
  set dataflowRuleId(value) {
    this.flowId = value;
    this.dataflowRuleIdChange.emit(this.flowId);
  }
  @Output()
  dataflowRuleIdChange = new EventEmitter();

  disableTriggering: boolean;
  @Input()
  get disableFurtherDataflowTriggering() {
    return this.disableTriggering;
  }
  set disableFurtherDataflowTriggering(value) {
    this.disableTriggering = value;
    this.disableFurtherDataflowTriggeringChange.emit(this.disableTriggering);
  }
  @Output()
  disableFurtherDataflowTriggeringChange = new EventEmitter();

  targetDataflow = {
    dataType: 'Reference',
    displayName: 'key_dataflowRuleId',
    description: '',
    multivalued: false,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'TargetDataFlowRuleId',
    value: null,
  };

  rdpConfig: IdentityEditorConfig = {
    showPhoto: true,
    required: true,
    popupWidth: 460,
    queryNormalSearch: `/DataFlowRule[starts-with(DisplayName,'%SearchText%')]`,
    allowEmptySearch: true,
    queryEmptySearch: '/DataFlowRule',
    attributesToShow: [
      {
        field: 'DisplayName',
        sortable: true,
        filterable: true,
        filter: 'text',
        width: 0,
      },
    ],
  };

  constructor(private utils: UtilsService) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.dataflowRuleId && this.idpDataflow) {
      this.idpDataflow.value = this.dataflowRuleId;
    }
  }

  onDataflowChange(dataflow: Array<any>) {
    if (dataflow && dataflow.length === 1) {
      this.dataflowRuleId = this.utils.ExtraValue(dataflow[0], 'ObjectID');
    } else {
      this.dataflowRuleId = null;
    }
  }
}
