import {
  AfterViewInit,
  Component,
  forwardRef,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AttributeEditor,
  EditorResult,
} from '../../models/dynamicEditor.interface';
import {
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import { SwapService } from '../../services/swap.service';
import {
  EditorCreationConfig,
  ForEachEditorConfig,
} from '../../models/editorContract.model';
import { EditorForEachConfigComponent } from './editor-for-each-config.component';
import {
  WindowCloseResult,
  WindowService,
} from '@progress/kendo-angular-dialog';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { AnyProperties } from '../../models/dataContract.model';
import { EditorCreatorComponent } from '../editor-creator/editor-creator.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-editor-for-each',
  templateUrl: './editor-for-each.component.html',
  styleUrls: ['./editor-for-each.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorForEachComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EditorForEachComponent),
      multi: true,
    },
  ],
})
export class EditorForEachComponent
  extends AttributeEditor
  implements OnInit, AfterViewInit, OnDestroy
{
  private subscription = new Subscription();

  private conf = new ForEachEditorConfig();
  public get config() {
    return this.conf;
  }
  public set config(value) {
    this.conf = value;
    this.configChange.emit(this.conf);
  }

  get value() {
    return this.editorAttribute ? this.editorAttribute.value : null;
  }
  set value(value) {
    this.editorAttribute.value = value;
    this.propagateChange(this.editorAttribute);
  }

  attributeArray: Array<EditorResult> = [];

  resourceToCreate: AnyProperties = {};

  constructor(
    public injector: Injector,
    private swap: SwapService,
    private window: WindowService,
    private dialog: MatDialog
  ) {
    super(injector);
  }

  setDisplay(usedFor: string = null, optionValue: boolean = null) {
    this.applyDisplaySettings(this.swap, this.resource, usedFor, optionValue);
  }

  applyConfig() {
    setTimeout(() => {
      this.setDisplay();
    });
  }

  ngOnInit(): void {
    if (this.configMode) {
      this.resourceToCreate = this.resource.schema['person'].bindings;
      Object.keys(this.resourceToCreate).forEach((key) => {
        this.resourceToCreate[key].permissionHint =
          'Add, Create, Modify, Delete, Read, Remove';
        this.resourceToCreate[key].value = '';
        this.resourceToCreate[key].values = [];
      });
      console.log(this.resourceToCreate);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const ngControl: NgControl = this.injector.get<NgControl>(NgControl);
      if (ngControl) {
        this.control = ngControl.control as FormControl;
      }

      this.applyConfig();

      this.initComponent();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // #region AttributeEditor implementation

  initComponent() {
    if (this.editorAttribute && this.editorAttribute.required) {
      this.config.required = true;
      this.config.requiredFromSchema = true;
    }

    const initConfig = new ForEachEditorConfig();
    this.utils.CopyInto(this.config, initConfig, true, true, [
      'calculatedDisplayable',
      'calculatedEditable',
    ]);
    this.config = initConfig;

    return this.config;
  }

  configure() {
    const configCopy = this.utils.DeepCopy(this.config);

    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    const windowRef = this.window.open({
      content: EditorForEachConfigComponent,
      width: 700,
    });
    const windowIns = windowRef.content.instance;
    windowIns.data = {
      component: this,
      config: this.config,
      attribute: this.editorAttribute,
      viewMode: this.viewMode,
    };

    return windowRef.result.pipe(
      tap((result: any) => {
        if (result instanceof WindowCloseResult) {
          this.config = configCopy;
        } else {
          this.applyConfig();
        }
      }),
      switchMap(() => {
        return of(this.config);
      }),
      finalize(() => {
        this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });
      })
    );
  }

  // #endregion

  onAddComponent() {
    const dialogRef = this.dialog.open(EditorCreatorComponent, {
      minWidth: '420px',
      data: {},
    });

    this.subscription.add(
      dialogRef
        .afterClosed()
        .pipe(
          tap((result: EditorCreationConfig) => {
            if (result && String(result) !== 'cancel') {
              this.config.attributeDef.push({
                attributeName: result.attributeName,
                editorType: result.type,
                editorConfig: {
                  attributeName: result.attributeName,
                  units: result.layoutUnits,
                },
              });
              console.log(this.config.attributeDef);
            }
          })
        )
        .subscribe()
    );
  }
}
