import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { ModalComponent } from '../core/components/modal/modal.component';
import {
  ModalType,
  ResourceTableConfig,
} from '../core/models/componentContract.model';
import { TransService } from '../core/models/translation.model';
import { ConfigService } from '../core/services/config.service';
import { ModalService } from '../core/services/modal.service';
import { ResourceService } from '../core/services/resource.service';
import { UtilsService } from '../core/services/utils.service';

@Component({
  selector: 'app-config-converter',
  templateUrl: './config-converter.component.html',
  styleUrls: ['./config-converter.component.scss'],
})
export class ConfigConverterComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  private attributesToConvert: string[] = [];
  private unwantedTableAttributes = [
    'resources',
    'resolvedQuery',
    'resolvedEditableQuery',
  ];

  // private async asyncStringify(text: string) {
  //   return JSON.stringify(text);
  // }

  public configBefore: string;
  public configAfter: string;

  private adjustResourceTableConfig(editingView: any) {
    if (editingView) {
      if (editingView.components) {
        // dashboard
        editingView.components.forEach((conf: any) => {
          if (
            conf.componentType === 'ResourceTableComponent' &&
            conf.componentConfig &&
            conf.componentConfig.resources
          ) {
            delete conf.componentConfig.resources;
          }
        });
      } else {
        // iteration through types
        Object.getOwnPropertyNames(editingView).forEach(
          (propertyName: string) => {
            if (editingView[propertyName].sections) {
              // iteration through sections
              editingView[propertyName].sections.forEach((section: any) => {
                if (section.attributes) {
                  // iteration through attributes
                  section.attributes.forEach((attribute: any) => {
                    // converter action
                    switch (attribute.editorType) {
                      case 'identity':
                        {
                          if (
                            attribute.editorConfig &&
                            attribute.editorConfig.attributesToShow
                          ) {
                            attribute.editorConfig.attributesToShow.forEach(
                              (ats: any) => {
                                if (ats.name) {
                                  ats.field = ats.name;
                                  delete ats.name;
                                }
                              }
                            );
                          }
                        }
                        break;
                      case 'identities':
                      case 'xpath':
                        {
                          if (attribute.editorConfig) {
                            // direct- / back-link
                            if (
                              attribute.editorConfig.name &&
                              attribute.editorConfig.name
                                .toLowerCase()
                                .startsWith('#directmember-')
                            ) {
                              attribute.editorConfig.linkType = 'directlink';
                              attribute.editorConfig.linkedAttribute =
                                attribute.attributeName;
                            }
                            if (
                              attribute.editorConfig.name &&
                              attribute.editorConfig.name
                                .toLowerCase()
                                .startsWith('#backlinkmember-')
                            ) {
                              attribute.editorConfig.linkType = 'backlink';
                              attribute.editorConfig.linkedAttribute =
                                attribute.attributeName;
                            }
                            // idp config
                            if (
                              attribute.editorConfig.idpConfig &&
                              attribute.editorConfig.idpConfig.attributesToShow
                            ) {
                              attribute.editorConfig.idpConfig.attributesToShow.forEach(
                                (ats: any) => {
                                  if (ats.name) {
                                    ats.field = ats.name;
                                    delete ats.name;
                                  }
                                }
                              );
                            }
                            // table config
                            if (!attribute.editorConfig.tableConfig) {
                              attribute.editorConfig.tableConfig = {};
                            }
                            Object.getOwnPropertyNames(
                              attribute.editorConfig
                            ).forEach((configName: string) => {
                              if (
                                this.attributesToConvert.indexOf(configName) >=
                                0
                              ) {
                                attribute.editorConfig.tableConfig[configName] =
                                  attribute.editorConfig[configName];
                                delete attribute.editorConfig[configName];
                              }
                            });
                            // remove unwanted attributes
                            this.unwantedTableAttributes.forEach(
                              (a: string) => {
                                if (attribute.editorConfig.tableConfig[a]) {
                                  delete attribute.editorConfig.tableConfig[a];
                                }
                              }
                            );
                          }
                        }
                        break;
                      default:
                        break;
                    }
                  });
                }
              });
            }
          }
        );
      }
    }
  }

  constructor(
    private modal: ModalService,
    private config: ConfigService,
    private clipboard: ClipboardService,
    private snackbar: MatSnackBar,
    private translate: TransService,
    private utils: UtilsService,
    private resource: ResourceService
  ) {}

  ngOnInit(): void {
    this.attributesToConvert = Object.getOwnPropertyNames(
      new ResourceTableConfig()
    );

    this.subscription.add(
      this.clipboard.copyResponse$.subscribe((res: IClipboardResponse) => {
        if (res.isSuccess) {
          this.snackbar.open(this.translate.instant('key_textCopied'), 'OK', {
            duration: 2000,
          });
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onConvert() {
    setTimeout(() => {
      this.configAfter = '';
    });

    setTimeout(() => {
      let jsonBefore: any = null;
      try {
        jsonBefore = this.configBefore.startsWith('-ZipB64-')
          ? JSON.parse(this.utils.unzipString(this.configBefore.substring(8)))
          : JSON.parse(this.configBefore);
      } catch (error) {
        this.modal.show(
          ModalType.error,
          'key_warning',
          'l10n_inputNotInJsonFormat'
        );
        return;
      }

      if (jsonBefore) {
        jsonBefore.version = this.utils.configVersion;

        if (jsonBefore.dashboard) {
          this.adjustResourceTableConfig(jsonBefore.dashboard);
        }
        if (jsonBefore.editingView) {
          this.adjustResourceTableConfig(jsonBefore.editingView);
        }
        if (jsonBefore.creationView) {
          this.adjustResourceTableConfig(jsonBefore.creationView);
        }
      }

      setTimeout(() => {
        this.configAfter = JSON.stringify(jsonBefore);
      });
    }, this.config.getConfig('intervalSmall', 300));
  }

  onCopy() {
    this.clipboard.copy(this.configAfter);
  }

  onImport() {
    let process: MatDialogRef<ModalComponent, any>;

    this.subscription.add(
      this.modal
        .show(
          ModalType.confirm,
          'key_confirmation',
          'key_confirmImportSettings'
        )
        .afterClosed()
        .pipe(
          switchMap((dialogResult: any) => {
            if (dialogResult && dialogResult === 'yes') {
              this.resource.primaryViewSetting = this.configAfter.startsWith(
                '-ZipB64-'
              )
                ? JSON.parse(
                    this.utils.unzipString(this.configAfter.substring(8))
                  )
                : JSON.parse(this.configAfter);
              process = this.modal.show(
                ModalType.progress,
                'key_savingChanges',
                '',
                '300px'
              );
              return this.resource.updateUISettings();
            }
            return EMPTY;
          }),
          tap((updateResult: string) => {
            if (updateResult === 'expired') {
              this.modal.show(
                ModalType.error,
                'key_error',
                'key_uiRefreshNeeded'
              );
            } else {
              location.reload();
            }
          }),
          catchError((error: any) => {
            this.modal.show(
              ModalType.error,
              'key_error',
              this.utils.getServiceError(error)
            );
            return EMPTY;
          }),
          finalize(() => {
            if (process) {
              process.close();
            }
          })
        )
        .subscribe()
    );
  }

  onUnzip() {
    if (this.configBefore && this.configBefore.startsWith('-ZipB64-')) {
      try {
        this.configBefore = this.utils.unzipString(
          this.configBefore.substring(8)
        );
      } catch (error) {
        this.modal.show(
          ModalType.error,
          'key_warning',
          'l10n_failedToDecompress'
        );
        return;
      }
    }
  }
}
