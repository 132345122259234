import {
  Component,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
  Input,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { delay, tap } from 'rxjs/operators';
import { TreeViewComponent } from '../core/components/tree-view/tree-view.component';
import { Subject, Subscription } from 'rxjs';
import { UtilsService } from '../core/services/utils.service';
import { SwapService } from '../core/services/swap.service';
import { ConfigService } from '../core/services/config.service';
import { ResourceService } from '../core/services/resource.service';
import { BroadcastEvent } from '../core/models/dataContract.model';

@Component({
  selector: 'app-structure-view',
  templateUrl: './structure-view.component.html',
  styleUrls: ['./structure-view.component.scss'],
})
export class StructureViewComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private subscription: Subscription = new Subscription();

  @ViewChild('orgStructure', { static: true }) orgStructure: TreeViewComponent;

  @Input()
  initQuery: string;

  @Input()
  childrenQuery: string;

  hasOU = false;

  sortConfig = this.config.getConfig('structureViewSort', []);
  attributeStyles = this.config.getConfig('structureViewAttributeStyles', []);
  pageSize: number = this.config.getConfig('structureViewPageSize', 20);

  private refreshToken = new Subject();

  constructor(
    private route: ActivatedRoute,
    private utils: UtilsService,
    private swap: SwapService,
    private config: ConfigService,
    private resource: ResourceService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.swap.broadcasted
        .pipe(
          tap((event: BroadcastEvent) => {
            if (event && event.name === 'refresh-attribute') {
              this.refreshToken.next(undefined);
            }
          })
        )
        .subscribe()
    );
  }

  ngAfterViewInit() {
    this.subscription.add(
      this.refreshToken
        .pipe(
          tap(() => {
            const objectID = this.route.snapshot.paramMap.get('id');

            if (this.initQuery && this.childrenQuery && this.orgStructure) {
              const initQuery = this.initQuery
                .replace(/\[#CurrentID\]/gi, objectID)
                .replace(
                  /\[#LoginID\]/gi,
                  this.utils.ExtraValue(
                    this.resource.loginUser,
                    'ObjectID:value'
                  )
                );

              const childrenQuery = this.childrenQuery
                .replace(/\[#CurrentID\]/gi, objectID)
                .replace(
                  /\[#LoginID\]/gi,
                  this.utils.ExtraValue(
                    this.resource.loginUser,
                    'ObjectID:value'
                  )
                );

              this.orgStructure.config.initQuery = initQuery;
              this.orgStructure.config.childrenQuery = childrenQuery;
              this.orgStructure.refresh();
              this.cdr.detectChanges();
            }
          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.route.params
        .pipe(
          delay(0),
          tap(() => {
            setTimeout(() => {
              this.refreshToken.next(undefined);
            }, this.config.getConfig('intervalMiddle', 500));
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onAfterTreeViewInit() {
    setTimeout(() => {
      this.hasOU =
        this.orgStructure && this.orgStructure.getSelectedItem() !== null;
    }, this.config.getConfig('intervalTiny', 200));
  }

  onGotoOU() {
    if (!this.orgStructure) {
      return;
    }

    const selectedItem = this.orgStructure.getSelectedItem();

    if (!selectedItem) {
      return;
    }

    setTimeout(() => {
      this.swap.broadcast({
        name: 'goto-ou',
        parameter: this.utils.ExtraValue(
          selectedItem.item.dataItem,
          'ObjectID'
        ),
      });
    });

    this.utils.NavigateToIdentity(
      selectedItem.item.dataItem,
      'orgstructure/empty'
    );
  }
}
