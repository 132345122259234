import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { EChartsOption } from 'echarts';
import { EMPTY, forkJoin, of, Subject, Subscription } from 'rxjs';
import { Resource, ResourceSet } from '../../models/dataContract.model';
import { ResourceService } from '../../services/resource.service';
import { catchError, expand, switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from '../../services/utils.service';
import { Observable } from 'rxjs-compat';

@Component({
  selector: 'app-object-explorer',
  templateUrl: './object-explorer.component.html',
  styleUrls: ['./object-explorer.component.scss'],
})
export class ObjectExplorerComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  private refreshToken = new Subject();

  private objectData: Array<Resource> = [];

  options: EChartsOption;

  hasData = false;

  graphData = {
    nodes: [],
    links: [],
    categories: [],
  };

  private buildCategories(data: Array<Resource>) {
    const arrType = data
      .map((item) => item.objecttype)
      .filter((value, index, self) => self.indexOf(value) === index);
    this.graphData.categories = arrType.map((item: string) => {
      if (this.resource.schema[item]) {
        return {
          id: item,
          name: this.resource.schema[item].displayName,
        };
      }
    });
    this.categories.emit(this.graphData.categories);
  }

  private buildNodes(data: Array<Resource>) {
    this.graphData.nodes = data.map((item) => {
      let size = this.nodeSizeNormal;
      if (this.highlightCategories) {
        size =
          this.highlightCategories.findIndex(
            (c: string) =>
              item.objecttype &&
              c.toLowerCase() === item.objecttype.toLowerCase()
          ) >= 0
            ? this.nodeSizeLarge
            : this.nodeSizeSmall;
      }
      return {
        id: item.objectid,
        type: item.objecttype,
        name: item.displayname,
        x: Math.random() * (300 - -300) + -300,
        y: Math.random() * (300 - -300) + -300,
        symbolSize: size,
        category: this.graphData.categories.findIndex(
          (c) => c.id === item.objecttype
        ),
      };
    });
  }

  private buildLinks(data: Array<Resource>) {
    const ids = data.map((item) => item.objectid);
    for (const id of ids) {
      for (const result of data) {
        const key = Object.keys(result).find((k: string) => {
          if (k !== 'objectid') {
            if (typeof result[k] === 'string') {
              return result[k] === id;
            } else if (Array.isArray(result[k])) {
              return result[k].indexOf(id) >= 0;
            }
          }
          return false;
        });
        if (key) {
          this.graphData.links.push({
            source: id,
            target: result.objectid,
            name: this.resource.attributeSchema[key]
              ? this.resource.attributeSchema[key].displayName
              : key,
          });
        }
      }
    }
  }

  private buildGraphData(data: Array<Resource>) {
    this.buildCategories(data);
    this.buildNodes(data);
    this.buildLinks(data);
  }

  private clearData() {
    this.objectData = [];
    this.hasData = false;
    this.highlightCategories = [];
    this.graphData = {
      nodes: [],
      links: [],
      categories: [],
    };
  }

  private addToData(res: Resource) {
    if (
      this.objectData.findIndex(
        (o) =>
          this.utils.ExtraValue(o, 'objectID') ===
          this.utils.ExtraValue(res, 'objectId')
      ) < 0
    ) {
      this.objectData.push(res);
    }
  }

  recursiveGetObjects(rootId: string): Observable<any> {
    return this.resource.getResourceByID(rootId, ['displayname']).pipe(
      expand((result: Resource | ResourceSet | Array<ResourceSet>) => {
        if (Array.isArray(result)) {
          const arrObs = [];
          result.forEach((r: ResourceSet) => {
            if (r.results && r.results.length > 0) {
              r.results.forEach((s: Resource) => {
                this.addToData(s);
                const id = this.utils.ExtraValue(s, 'objectID');
                if (id) {
                  arrObs.push(
                    this.resource.getResourceByQuery(
                      this.travelQuery.replace(/%id%/gi, id),
                      this.linkedAttributes
                    )
                  );
                }
              });
            }
          });
          return forkJoin(arrObs);
        } else if (result.results && result.results.length > 0) {
          const arrObs = [];
          result.results.forEach((r: Resource) => {
            this.addToData(r);
            const id = this.utils.ExtraValue(r, 'objectID');
            if (id) {
              arrObs.push(
                this.resource.getResourceByQuery(
                  this.travelQuery.replace(/%id%/gi, id),
                  this.linkedAttributes
                )
              );
            }
          });
          return forkJoin(arrObs);
        } else {
          const id = this.utils.ExtraValue(result, 'objectID');
          if (id) {
            this.addToData(result);
            return this.resource.getResourceByQuery(
              this.travelQuery.replace(/%id%/gi, id),
              this.linkedAttributes
            );
          } else {
            return EMPTY;
          }
        }
      })
    );
  }

  @Input()
  title: string;

  @Input()
  query: string;

  @Input()
  linkedAttributes: string[] = [];

  @Input()
  travelQuery: string;

  @Input()
  highlightCategories = [];

  @Input()
  height: number;

  @Input()
  showPlaceHolder = true;

  @Input()
  nodeSizeNormal = 20;

  @Input()
  nodeSizeSmall = 15;

  @Input()
  nodeSizeLarge = 25;

  @Output()
  itemClick = new EventEmitter();

  @Output()
  categories = new EventEmitter();

  adjustTooltip = (param: any) => {
    if (param && param.data && param.data.name) {
      return param.data.name;
    }
    return '';
  };

  constructor(private resource: ResourceService, private utils: UtilsService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.refreshToken
        .pipe(
          switchMap(() => {
            if (!this.query) {
              this.clearData();
              return EMPTY;
            }
            return this.resource.getResourceByQuery(
              this.query,
              this.linkedAttributes
            );
          }),
          switchMap((result: ResourceSet) => {
            if (result && result.results && result.results.length > 0) {
              if (this.travelQuery) {
                const arrObs = [];
                result.results.forEach((r: Resource) => {
                  const id = this.utils.ExtraValue(r, 'objectID');
                  if (id) {
                    this.addToData(r);
                    arrObs.push(this.recursiveGetObjects(id));
                  }
                });
                return forkJoin(arrObs);
              } else {
                result.results.forEach((r: Resource) => this.addToData(r));
                return of(null);
              }
            } else {
              return of(null);
            }
          }),
          tap(() => {
            if (this.objectData && this.objectData.length > 0) {
              this.hasData = true;
              this.buildGraphData(this.objectData);
              this.graphData.nodes.forEach((node) => {
                node.label = {
                  show: node.symbolSize >= this.nodeSizeLarge,
                };
              });
              this.options = {
                title: {
                  text: this.title,
                  subtext: 'Default layout',
                  top: 'bottom',
                  left: 'right',
                },
                tooltip: {
                  formatter: this.adjustTooltip,
                },
                legend: [
                  {
                    data: this.graphData.categories.map(function (a: {
                      name: string;
                    }) {
                      return a.name;
                    }),
                  },
                ],
                animationDuration: 1500,
                animationEasingUpdate: 'quinticInOut',
                series: [
                  {
                    name: 'Les Miserables',
                    type: 'graph',
                    legendHoverLink: false,
                    // layout: 'none',
                    layout: 'force',
                    data: this.graphData.nodes,
                    links: this.graphData.links,
                    categories: this.graphData.categories,
                    roam: true,
                    label: {
                      position: 'right',
                      formatter: '{b}',
                    },
                    lineStyle: {
                      color: 'source',
                      curveness: 0.3,
                    },
                    emphasis: {
                      focus: 'adjacency',
                      lineStyle: {
                        width: 10,
                      },
                    },
                    force: {
                      repulsion: 500,
                    },
                  },
                ],
              };
            }
          }),
          catchError((err: HttpErrorResponse) => {
            console.log(err);
            return EMPTY;
          })
        )
        .subscribe()
    );

    this.refresh();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  refresh() {
    this.refreshToken.next(undefined);
  }

  onChartClick(event: any) {
    this.itemClick.emit(event);
  }
}
