import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EMPTY, of, Subscription } from 'rxjs';

import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';

import {
  ApprovalViewCloudConfig,
  ModalType,
} from '../../models/componentContract.model';
import {
  ApiDef,
  ApprovalCloudRequest,
  PopupConfig,
} from '../../models/dataContract.model';
import { DynamicComponent } from '../../models/dynamicComponent.interface';
import { ConfigService } from '../../services/config.service';
import { UtilsService } from '../../services/utils.service';
import { ResourceService } from '../../services/resource.service';
import { catchError, delay, finalize, switchMap, tap } from 'rxjs/operators';
import { ModalService } from '../../services/modal.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../modal/modal.component';
import { WindowCloseResult } from '@progress/kendo-angular-dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { ApprovalCheckCloudConfigComponent } from './approval-check-cloud-config.component';

@Component({
  selector: 'app-approval-check-cloud',
  templateUrl: './approval-check-cloud.component.html',
  styleUrls: ['./approval-check-cloud.component.scss'],
})
export class ApprovalCheckCloudComponent
  implements DynamicComponent, OnInit, OnDestroy
{
  private subscription = new Subscription();

  @Input()
  config: ApprovalViewCloudConfig;

  localConfig: ApprovalViewCloudConfig;
  approvalRequests: Array<ApprovalCloudRequest> = [];
  spinnerType = SPINNER;

  blurLevel = this.configService.getConfig('blurLevel', 1);
  uiLoader = this.configService.getConfig(
    'uiLoader',
    this.configService.defaultUiLoader
  );

  displayMode = 'pending';

  attributeAssignments: Array<{
    attribute: string;
    action: string;
    type: string;
    oldValue: string;
    newValue: string;
  }> = [];

  private loadApprovalRequests(displayMode = '') {
    let showAll = this.localConfig.showAll;
    if (displayMode) {
      switch (displayMode.toLowerCase()) {
        case 'all':
          showAll = true;
          break;
        case 'pending':
          showAll = false;
          break;
        default:
          break;
      }
    }
    this.approvalRequests = [];
    const objectID = this.utils.ExtraValue(this.resource.loginUser, 'ObjectID');
    if (objectID) {
      this.spinner.startLoader(this.localConfig.name);

      let apiApproval: ApiDef;
      switch (this.localConfig.type) {
        case 'requestor':
          apiApproval = {
            method: 'get',
            path: 'approval/search',
            param: {
              requestor: objectID,
              approvalProcessStatus: showAll
                ? ['Open', 'Approved', 'Rejected', 'Expired', 'Aborted']
                : ['Open'],
              take: this.localConfig.limit,
              approvalInclusion: 'All',
              responseInclusion: 'All',
              orderBy: 'RequestCreationTime',
              sortOrder: 'Descending',
              includeDelegates: this.localConfig.includeDelegates,
            },
          };
          break;
        case 'emergency':
          {
            if (showAll) {
              apiApproval = {
                method: 'get',
                path: 'approval/search',
                param: {
                  emergencyApproverOrResponder: objectID,
                  approvalProcessStatus: [
                    'Open',
                    'Approved',
                    'Rejected',
                    'Expired',
                    'Aborted',
                  ],
                  take: this.localConfig.limit,
                  approvalInclusion: 'All',
                  responseInclusion: 'All',
                  orderBy: 'RequestCreationTime',
                  sortOrder: 'Descending',
                  includeDelegates: this.localConfig.includeDelegates,
                },
              };
            } else {
              apiApproval = {
                method: 'get',
                path: 'approval/search',
                param: {
                  emergencyApprover: objectID,
                  approvalProcessStatus: ['Open'],
                  take: this.localConfig.limit,
                  approvalInclusion: 'All',
                  responseInclusion: 'All',
                  orderBy: 'RequestCreationTime',
                  sortOrder: 'Descending',
                  includeDelegates: this.localConfig.includeDelegates,
                },
              };
            }
          }
          break;
        case 'approver':
        default:
          {
            if (showAll) {
              apiApproval = {
                method: 'get',
                path: 'approval/search',
                param: {
                  responderOrApprover: objectID,
                  approvalProcessStatus: [
                    'Open',
                    'Approved',
                    'Rejected',
                    'Expired',
                    'Aborted',
                  ],
                  take: this.localConfig.limit,
                  approvalInclusion: 'All',
                  responseInclusion: 'All',
                  orderBy: 'RequestCreationTime',
                  sortOrder: 'Descending',
                  includeDelegates: this.localConfig.includeDelegates,
                },
              };
            } else {
              apiApproval = {
                method: 'get',
                path: 'approval/search',
                param: {
                  approver: objectID,
                  approvalProcessStatus: ['Open'],
                  take: this.localConfig.limit,
                  approvalInclusion: 'All',
                  responseInclusion: 'All',
                  orderBy: 'RequestCreationTime',
                  sortOrder: 'Descending',
                  includeDelegates: this.localConfig.includeDelegates,
                },
              };
            }
          }
          break;
      }

      this.subscription.add(
        this.resource
          .callApi(apiApproval.method, apiApproval.path, apiApproval.param)
          .pipe(
            tap((result: Array<any>) => {
              this.approvalRequests = result;
            }),
            finalize(() => {
              try {
                this.spinner.stopLoader(this.localConfig.name);
              } catch {}
            })
          )
          .subscribe()
      );
    }
  }

  constructor(
    private configService: ConfigService,
    private utils: UtilsService,
    private resource: ResourceService,
    private spinner: NgxUiLoaderService,
    private modal: ModalService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.initComponent();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initComponent() {
    this.localConfig = new ApprovalViewCloudConfig();
    this.utils.CopyInto(this.config, this.localConfig, true, true);

    this.displayMode = this.localConfig.showAll ? 'all' : 'pending';

    this.loadApprovalRequests();

    return this.localConfig;
  }

  configure() {
    const configCopy = this.utils.DeepCopy(this.localConfig);

    const dialogRef = this.dialog.open(ApprovalCheckCloudConfigComponent, {
      minWidth: '650px',
      data: {
        component: this,
        config: this.localConfig,
      },
    });

    return dialogRef.afterClosed().pipe(
      tap((result) => {
        if (!result || (result && result === 'cancel')) {
          this.localConfig = configCopy;
        }
        this.updateDataSource();
      }),
      switchMap(() => {
        return of(this.localConfig);
      })
    );
  }

  updateDataSource() {
    this.loadApprovalRequests();
  }

  resize: (size: number[]) => void;

  refresh() {
    this.loadApprovalRequests(this.displayMode);
  }

  canApprove(request: ApprovalCloudRequest): boolean {
    const objectID = this.utils.ExtraValue(this.resource.loginUser, 'ObjectID');

    if (
      objectID &&
      request &&
      request.approvals &&
      request.approvals.length > 0
    ) {
      const currentApproval = request.approvals[request.approvals.length - 1];
      if (!currentApproval.approvalDecision) {
        if (
          currentApproval.currentApprovers &&
          currentApproval.currentApprovers.indexOf(objectID) >= 0
        ) {
          return true;
        }
        if (
          currentApproval.emergencyApprovers &&
          currentApproval.emergencyApprovers.indexOf(objectID) >= 0
        ) {
          return true;
        }
        if (
          currentApproval.onBehalfOfs &&
          Object.keys(currentApproval.onBehalfOfs).indexOf(objectID) >= 0
        ) {
          return true;
        }
      }
    }

    return false;
  }

  onExpandRequest(request: ApprovalCloudRequest) {
    request.showContent = !request.showContent;
    if (request.showContent) {
      switch (this.localConfig.defaultContent) {
        case 'detail':
          request.showDetail = true;
          break;
        case 'response':
          request.showResponse = true;
          break;
        default:
          break;
      }
    }
  }

  onApprove(request: ApprovalCloudRequest, decision: boolean) {
    if (request.approvals && request.approvals.length > 0) {
      const objApproval = request.approvals[request.approvals.length - 1];
      if (
        objApproval.approvalDecision === null &&
        objApproval.approvalObjectId
      ) {
        const approvalID = objApproval.approvalObjectId;

        const popupConfig: PopupConfig = new PopupConfig();
        popupConfig.title = decision
          ? 'key_approveRequest'
          : 'key_rejectRequest';
        popupConfig.style = 'fill';
        popupConfig.singleButton = true;
        popupConfig.confirmButton = decision
          ? {
              text: 'key_approve',
              color: 'primary',
            }
          : {
              text: 'key_reject',
              color: 'warn',
            };
        popupConfig.data = {
          reason: {
            text: 'key_reason',
            value: '',
            type: 'textarea',
            focused: true,
            required: this.localConfig.reasonRequired,
          },
          approve: {
            value: decision,
            visible: false,
          },
        };

        const objectID = this.utils.ExtraValue(
          this.resource.loginUser,
          'ObjectID'
        );
        if (objectID) {
          const delegatorOptions: any[] = [];
          if (objApproval.onBehalfOfs) {
            if (Object.keys(objApproval.onBehalfOfs).indexOf(objectID) >= 0) {
              objApproval.onBehalfOfs[objectID].forEach((d: any) => {
                delegatorOptions.push({
                  text: d.displayName,
                  value: d.id,
                });
              });
            }
            if (delegatorOptions.length > 0) {
              if (
                objApproval.currentApprovers &&
                objApproval.currentApprovers.indexOf(objectID) >= 0
              ) {
                delegatorOptions.splice(0, 0, {
                  text: 'key_self',
                  value: objectID,
                });
              }

              popupConfig.data['delegators'] = {
                text: 'key_onBehalfOf',
                value: delegatorOptions[0].value,
                type: 'objectselect',
                options: delegatorOptions,
              };
            }
          }
        }

        let prg: MatDialogRef<ModalComponent, any>;

        this.subscription.add(
          this.modal
            .popup(popupConfig)
            .pipe(
              switchMap((windowResult: any) => {
                if (!(windowResult instanceof WindowCloseResult)) {
                  prg = this.modal.show(
                    ModalType.progress,
                    'key_processing',
                    '',
                    '300px'
                  );
                  return this.resource
                    .approve(
                      approvalID,
                      decision,
                      windowResult.reason.value,
                      windowResult.delegators &&
                        windowResult.delegators.value !== objectID
                        ? windowResult.delegators.value
                        : '',
                      this.localConfig.type === 'emergency'
                    )
                    .pipe(
                      delay(1000),
                      finalize(() => {
                        if (prg) {
                          prg.close();
                        }
                        this.loadApprovalRequests();
                      })
                    );
                } else {
                  return EMPTY;
                }
              }),
              catchError((error: HttpErrorResponse) => {
                this.modal.show(ModalType.error, 'key_error', error.error);
                if (prg) {
                  prg.close();
                }
                return EMPTY;
              })
            )
            .subscribe(
              () => {},
              (err) => {
                console.log(err);
              }
            )
        );
      }
    } else {
      this.modal.show(
        ModalType.error,
        'key_error',
        'key_requestCannotBeApproved'
      );
    }
  }

  onDisplayModeChange() {
    this.loadApprovalRequests(this.displayMode);
  }
}
