import { Injectable, Output, EventEmitter } from '@angular/core';

import { Subject } from 'rxjs';

import {
  AuthMode,
  BroadcastEvent,
  EditorEvent,
  MenuEvent,
  ObjectViewEvent,
  TabViewEvent,
  WizardEvent,
} from '../models/dataContract.model';

/**
 * Service used to communicate between components
 */
@Injectable({
  providedIn: 'root',
})
export class SwapService {
  /** Event emitter for window resized */
  @Output()
  windowResized: EventEmitter<string> = new EventEmitter();

  /** Error settings */
  private errorMessage = '';
  private isErrorState = false;

  /** Communication between components for broadcasting */
  private broadcastedSource = new Subject();
  broadcasted = this.broadcastedSource.asObservable();

  /** Editor event */
  private onEditorEventSource = new Subject();
  onEditorEvent = this.onEditorEventSource.asObservable();

  /** Object view event */
  private onObjectViewEventSource = new Subject();
  onObjectViewEvent = this.onObjectViewEventSource.asObservable();

  /** Tab view event */
  private onTabViewEventSource = new Subject();
  onTabViewEvent = this.onTabViewEventSource.asObservable();

  /** Menu event */
  private onMenuEventSource = new Subject();
  onMenuEvent = this.onMenuEventSource.asObservable();

  /** Wizard event */
  private onWizardEventSource = new Subject();
  onWizardEvent = this.onWizardEventSource.asObservable();

  /** Sidebar event */
  private onSidebarEventSource = new Subject();
  onSidebarEvent = this.onSidebarEventSource.asObservable();

  /** Card event */
  private onCardEventSource = new Subject();
  onCardEvent = this.onCardEventSource.asObservable();

  /** Link event */
  private onLinkEventSource = new Subject();
  onLinkEvent = this.onLinkEventSource.asObservable();

  /** Communication between components for editor config change */
  private editorConfigChangedSource = new Subject();
  editorConfigChanged = this.editorConfigChangedSource.asObservable();

  /** Communication between components for showing / hiding editor */
  private editorDisplayChangedSource = new Subject();
  editorDisplayChanged = this.editorDisplayChangedSource.asObservable();

  /** Event names */
  EVENT_ATTRIBUTE_VIEW_AFTER_INIT = 'attributeView_after_init';
  EVENT_ATTRIBUTE_VIEW_BEFORE_SAVE = 'attributeView_before_save';

  /** Current language */
  currentLanguage = 'en';

  /** Indicate global page edit mode */
  isEditMode = false;
  get editMode() {
    return this.isEditMode;
  }
  set editMode(value: boolean) {
    this.isEditMode = value;
  }

  /** Persist tree view expansion state */
  expansion: { [name: string]: Array<string> } = {};
  get expansionState() {
    return this.expansion;
  }
  set expansionState(value: { [name: string]: Array<string> }) {
    this.expansion = value;
  }

  /** Form names for which the deactivation guard should not apply */
  private deactivationExceptions: Array<string> = [];
  get deactivationGuardExceptions() {
    return this.deactivationExceptions;
  }
  set deactivationGuardExceptions(value: Array<string>) {
    this.deactivationExceptions = value;
  }

  public authenticationMode: AuthMode;

  /** @ignore */
  constructor() {}

  /**
   * Emit the event for window resized
   * @param size Window size
   */
  resizeWindow(size: string) {
    this.windowResized.emit(size);
  }

  /**
   * Common broadcast event
   * @param event Broadcast event
   */
  broadcast(event: BroadcastEvent | EditorEvent) {
    this.broadcastedSource.next(event);
  }

  /** Propagate editor event */
  editorEvent(event: EditorEvent) {
    this.onEditorEventSource.next(event);
  }

  /** Propagate object view event */
  objectViewEvent(event: ObjectViewEvent) {
    this.onObjectViewEventSource.next(event);
  }

  /** Propagate tab view event */
  tabViewEvent(event: TabViewEvent) {
    this.onTabViewEventSource.next(event);
  }

  /** Propagate menu event */
  menuEvent(event: MenuEvent) {
    this.onMenuEventSource.next(event);
  }

  /** Propagate wizard event */
  wizardEvent(event: WizardEvent) {
    this.onWizardEventSource.next(event);
  }

  /** Propagate sidebar event */
  sidebarEvent(event: BroadcastEvent) {
    this.onSidebarEventSource.next(event);
  }

  /** Propagate card event */
  cardEvent(event: BroadcastEvent) {
    this.onCardEventSource.next(event);
  }

  /** Propagate link event */
  linkEvent(event: BroadcastEvent) {
    this.onLinkEventSource.next(event);
  }

  /**
   * Prpagate editor config change
   * @param attributeName Attrubte name of the editor
   */
  propagateEditorConfigChanged(
    attributeName: string,
    configName: string,
    expression: string
  ) {
    const config: {
      [key: string]: Array<{ name: string; value: string }>;
    } = {};
    config[attributeName] = [{ name: configName, value: expression }];
    this.editorConfigChangedSource.next(config);
  }

  /**
   * Prpagate editor display change
   * @param attributeName Attrubte name of the editor
   */
  propagateEditorDisplayChanged(option: {
    attributeName: string;
    usedFor: string;
    optionValue: boolean;
    readAccess: boolean;
  }) {
    this.editorDisplayChangedSource.next(option);
  }

  /**
   * Determin [window size]{@link https://github.com/angular/flex-layout/wiki/Responsive-API} (xs, sm, md, lg)
   */
  verifyWindowSize() {
    if (window.innerWidth < 600) {
      this.resizeWindow('xs');
    } else if (window.innerWidth <= 960 && window.innerWidth >= 600) {
      this.resizeWindow('sm');
    } else if (window.innerWidth >= 960 && window.innerWidth < 1600) {
      this.resizeWindow('md');
    } else {
      this.resizeWindow('lg');
    }
  }

  setError(message: string) {
    this.errorMessage = message;
    if (message) {
      this.isErrorState = true;
    } else {
      this.isErrorState = false;
    }
  }

  hasError(): boolean {
    return this.isErrorState;
  }

  getErrorMessage(): string {
    return this.errorMessage;
  }
}
