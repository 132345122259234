<div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="20px">
  <div fxFlex="auto" fxLayout="column" fxLayoutAlign="start start">
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>{{ 'l10n_originalConfig' | translate }}</mat-label>
      <textarea
        matInput
        rows="30"
        [(ngModel)]="configBefore"
        spellcheck="false"
      ></textarea>
    </mat-form-field>
    <div fxLayout="row" style="width: 100%">
      <div fxFlex="auto"></div>
      <button
        mat-stroked-button
        color="primary"
        (click)="onUnzip()"
        [disabled]="!configBefore"
      >
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <div>{{ 'l10n_unzipSetting' | translate }}</div>
          <span class="material-icons-outlined">expand</span>
        </div>
      </button>
    </div>
  </div>
  <div fxFlex="none" fxLayout="column">
    <div fxFlex="auto"></div>
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      (click)="onConvert()"
      [disabled]="!configBefore"
    >
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div>{{ 'l10n_convert' | translate }}</div>
        <span class="material-icons-outlined">double_arrow</span>
      </div>
    </button>
    <div fxFlex="auto"></div>
  </div>
  <div fxFlex="auto" fxLayout="column" fxLayoutAlign="start start">
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>{{ 'l10n_convertedConfig' | translate }}</mat-label>
      <textarea
        matInput
        rows="30"
        [(ngModel)]="configAfter"
        spellcheck="false"
      ></textarea>
    </mat-form-field>
    <div fxLayout="row">
      <button
        mat-stroked-button
        color="primary"
        (click)="onImport()"
        [disabled]="!configAfter"
        style="margin-right: 20px"
      >
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <div>{{ 'key_import' | translate }}</div>
          <span class="material-icons-outlined">upload</span>
        </div>
      </button>
      <button
        mat-stroked-button
        color="primary"
        (click)="onCopy()"
        [disabled]="!configAfter"
      >
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <div>{{ 'key_copy' | translate }}</div>
          <span class="material-icons-outlined">content_copy</span>
        </div>
      </button>
    </div>
  </div>
</div>
