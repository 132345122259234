<div fxLayout="row" fxLayoutAlign="start start" style="margin-top: 10px">
  <mat-form-field
    fxFlex="40"
    style="margin-right: 20px; margin-top: 1.5px"
    matTooltip="{{ 'key_statusDes' | translate }}"
  >
    <mat-label>{{ 'key_status' | translate }}</mat-label>
    <mat-select [(ngModel)]="status">
      <mat-option [value]="'Success'">Success</mat-option>
      <mat-option [value]="'Failure'">Failure</mat-option>
      <mat-option [value]="'Denied'">Denied</mat-option>
    </mat-select>
  </mat-form-field>
  <app-editor-text
    fxFlex="60"
    style="margin-top: 3px"
    [name]="'txtStatusMessage'"
    [(ngModel)]="message"
    [config]="{
      isSimpleValue: true,
      isCode: true,
      hintLimit: 50,
      customDisplayName: 'key_statusMessage',
      customDescription: 'key_statusMessageDes',
      updateOn: updateOnBlure,
      validationKey: 'workflow/Status/Message'
    }"
    appExpressionValidator
  ></app-editor-text>
</div>
