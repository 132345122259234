import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LocalizationModule } from './localization.module';
import { GridsterModule } from 'angular-gridster2';
import { ColorPickerModule } from 'ngx-color-picker';
import { DragulaModule } from 'ng2-dragula';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
// import { ParticlesModule } from 'angular-particle';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxMasonryModule } from 'ngx-masonry';
import { MglTimelineModule } from 'angular-mgl-timeline';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgDompurifyModule } from '@tinkoff/ng-dompurify';
import { NgxEchartsModule } from 'ngx-echarts';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRippleModule } from '@angular/material/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';

import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { PopupModule } from '@progress/kendo-angular-popup';
import { UploadModule } from '@progress/kendo-angular-upload';
import { SplitterModule } from '@progress/kendo-angular-layout';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { TreeListModule } from '@progress/kendo-angular-treelist';

import { TransService } from '../models/translation.model';
import { ConfigService } from '../services/config.service';
import { ResourceService } from '../services/resource.service';
import { AuthService } from '../services/auth.service';
import { StartupService } from '../services/startup.service';
import { RouteGuardService } from '../services/route-guard.service';
import { SwapService } from '../services/swap.service';
import { ComponentIndexService } from '../services/component-index.service';
import { ComponentService } from '../services/component.service';
import { ModalService } from '../services/modal.service';
import { InfoService } from '../services/info.service';
import { HelperService } from '../services/helper.service';
import { SignalService } from '../services/signal.service';

import { ExtraValuePipe } from '../pipes/extra-value.pipe';
import { ExamValuePipe } from '../pipes/exam-value.pipe';
import { StringToDatePipe } from '../pipes/string-to-date.pipe';
import { TranslateAttributePipe } from '../pipes/translate-attribute.pipe';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { SafeUrlPipe } from '../pipes/safe-url.pipe';
import { EllipsisPipe } from '../pipes/ellipsis.pipe';

import { DynamicContainerDirective } from '../directives/dynamic-container.directive';
import { AttributeLayoutDirective } from '../directives/attribute-layout.directive';
import { UniquenessValidatorDirective } from '../validators/validators';
import { DistinctValidatorDirective } from '../validators/validators';
import { ApiValidatorDirective } from '../validators/validators';
import { XpathValidatorDirective } from '../validators/validators';
import { ExpressionValidatorDirective } from '../validators/validators';
import { DelayedHoverDirective } from '../directives/delayed-hover.directive';
import { AutoFocusDirective } from '../directives/auto-focus.directive';

import { EditorCreatorComponent } from '../components/editor-creator/editor-creator.component';
import { EditorTextComponent } from '../components/editor-text/editor-text.component';
import { EditorTextConfigComponent } from '../components/editor-text/editor-text-config.component';
import { EditorBooleanComponent } from '../components/editor-boolean/editor-boolean.component';
import { EditorBooleanConfigComponent } from '../components/editor-boolean/editor-boolean-config.component';
import { EditorSelectComponent } from '../components/editor-select/editor-select.component';
import { EditorSelectConfigComponent } from '../components/editor-select/editor-select-config.component';
import { EditorDateComponent } from '../components/editor-date/editor-date.component';
import { EditorDateConfigComponent } from '../components/editor-date/editor-date-config.component';
import { EditorIdentityComponent } from '../components/editor-identity/editor-identity.component';
import { EditorIdentityConfigComponent } from '../components/editor-identity/editor-identity-config.component';
import { EditorIdentitiesComponent } from '../components/editor-identities/editor-identities.component';
import { EditorIdentitiesConfigComponent } from '../components/editor-identities/editor-identities-config.component';
import { EditorSeparatorComponent } from '../components/editor-separator/editor-separator.component';
import { EditorSeparatorConfigComponent } from '../components/editor-separator/editor-separator-config.component';
import { EditorButtonComponent } from '../components/editor-button/editor-button.component';
import { EditorButtonConfigComponent } from '../components/editor-button/editor-button-config.component';
import { EditorXpathComponent } from '../components/editor-xpath/editor-xpath.component';
import { EditorXpathConfigComponent } from '../components/editor-xpath/editor-xpath-config.component';
import { EditorFrameComponent } from '../components/editor-frame/editor-frame.component';
import { EditorFrameConfigComponent } from '../components/editor-frame/editor-frame-config.component';
import { EditorIterablesComponent } from '../components/editor-iterables/editor-iterables.component';
import { EditorIterablesConfigComponent } from '../components/editor-iterables/editor-iterables-config.component';
import { EditorObjectComponent } from '../components/editor-object/editor-object.component';
import { EditorObjectConfigComponent } from '../components/editor-object/editor-object-config.component';
import { EditorForEachComponent } from '../components/editor-for-each/editor-for-each.component';
import { EditorForEachConfigComponent } from '../components/editor-for-each/editor-for-each-config.component';

import { SigninComponent } from '../components/signin/signin.component';
import { EditMenuComponent } from '../components/edit-menu/edit-menu.component';
import { SearchComponent } from '../components/search/search.component';
import { SimpleSearchComponent } from '../components/simple-search/simple-search.component';
import { AccountComponent } from '../components/account/account.component';
import { ModalComponent } from '../components/modal/modal.component';
import { WidgetCreatorComponent } from '../components/widget-creator/widget-creator.component';
import { AttributeViewComponent } from '../components/attribute-view/attribute-view.component';
import { ViewConfiguratorComponent } from '../components/view-configurator/view-configurator.component';
import { PopupTitleComponent } from '../components/popup-title/popup-title.component';
import { PopupBodyComponent } from '../components/popup-body/popup-body.component';
import { PopupWizardComponent } from '../components/popup-wizard/popup-wizard.component';
import { XpathBuilderComponent } from '../components/xpath-builder/xpath-builder.component';

import { StateCardComponent } from '../components/state-card/state-card.component';
import { StateCardConfigComponent } from '../components/state-card/state-card-config.component';
import { ActionCardComponent } from '../components/action-card/action-card.component';
import { ResourceTableComponent } from '../components/resource-table/resource-table.component';
import { ResourceTableConfigComponent } from '../components/resource-table/resource-table-config.component';
import { ResourceTableDetailComponent } from '../components/resource-table-detail/resource-table-detail.component';
import { ResourceChartComponent } from '../components/resource-chart/resource-chart.component';
import { ResourceChartConfigComponent } from '../components/resource-chart/resource-chart-config.component';
import { TreeViewComponent } from '../components/tree-view/tree-view.component';
import { TreeListComponent } from '../components/tree-list/tree-list.component';
import { AttributePickerComponent } from '../components/attribute-picker/attribute-picker.component';
import { ActionMenuComponent } from '../components/action-menu/action-menu.component';
import { IdentityLinkComponent } from '../components/identity-link/identity-link.component';
import { ObjectHistoryComponent } from '../components/object-history/object-history.component';
import { EventGraphComponent } from '../components/event-graph/event-graph.component';
import { ApprovalCheckComponent } from '../components/approval-check/approval-check.component';
import { ApprovalCheckConfigComponent } from '../components/approval-check/approval-check-config.component';
import { FrameViewComponent } from '../components/frame-view/frame-view.component';
import { FrameViewConfigComponent } from '../components/frame-view/frame-view-config.component';
import { WorkflowDesignComponent } from '../components/workflow-design/workflow-design.component';
import { DataFlowRuleTypeComponent } from '../components/data-flow-rule-type/data-flow-rule-type.component';
import { ResourcePreviewComponent } from '../components/activities/resource-preview/resource-preview.component';
import { GridFilterComponent } from '../components/grid-filter/grid-filter.component';
import { IdentityBrowserComponent } from '../components/identity-browser/identity-browser.component';
import { BreadCrumbComponent } from '../components/bread-crumb/bread-crumb.component';
import { TypePickerComponent } from '../components/type-picker/type-picker.component';
import { HtmlCardComponent } from '../components/html-card/html-card.component';
import { HtmlCardConfigComponent } from '../components/html-card/html-card-config.component';
import { PhotoEditorComponent } from '../components/photo-editor/photo-editor.component';
import { PhotoCropperComponent } from '../components/photo-cropper/photo-cropper.component';
import { ReportCardComponent } from '../components/report-card/report-card.component';
import { ReportCardConfigComponent } from '../components/report-card/report-card-config.component';
import { ReportCreatorComponent } from '../components/report-creator/report-creator.component';
import { ReportSeparatorComponent } from '../components/report-separator/report-separator.component';
import { ReportSeparatorConfigComponent } from '../components/report-separator-config/report-separator-config.component';
import { WorkflowStarterComponent } from '../components/workflow-starter/workflow-starter.component';
import { TagsExplorerComponent } from '../components/tags-explorer/tags-explorer.component';
import { ObjectExplorerComponent } from '../components/object-explorer/object-explorer.component';
import { ConflictsResolverComponent } from '../components/conflicts-resolver/conflicts-resolver.component';

import { ActivityCreatorComponent } from '../components/activity-creator/activity-creator.component';
import { ActivityGeneralComponent } from '../components/activities/activity-general/activity-general.component';
import { ActivityAddDelayComponent } from '../components/activities/activity-add-delay/activity-add-delay.component';
import { ActivityApprovalComponent } from '../components/activities/activity-approval/activity-approval.component';
import { ActivityForEachComponent } from '../components/activities/activity-for-each/activity-for-each.component';
import { ActivityUpdateResourceComponent } from '../components/activities/activity-update-resource/activity-update-resource.component';
import { ActivityCreateResourceComponent } from '../components/activities/activity-create-resource/activity-create-resource.component';
import { ActivityDeleteResourceComponent } from '../components/activities/activity-delete-resource/activity-delete-resource.component';
import { ActivityRestApiCallComponent } from '../components/activities/activity-rest-api-call/activity-rest-api-call.component';
import { ActivitySendEmailComponent } from '../components/activities/activity-send-email/activity-send-email.component';
import { ActivityIfThenElseComponent } from '../components/activities/activity-if-then-else/activity-if-then-else.component';
import { ActivityTemplateComponent } from '../components/activities/activity-template/activity-template.component';
import { ActivityDataflowComponent } from '../components/activities/activity-dataflow/activity-dataflow.component';
import { ApprovalCheckCloudComponent } from '../components/approval-check-cloud/approval-check-cloud.component';
import { ApprovalCheckCloudConfigComponent } from '../components/approval-check-cloud/approval-check-cloud-config.component';
import { ApprovalInfoComponent } from '../components/approval-info/approval-info.component';
import { ApprovalDelegationComponent } from '../components/approval-delegation/approval-delegation.component';
import { ActivityStatusComponent } from '../components/activity-status/activity-status.component';
import { ActivityPlaceholderComponent } from '../components/activity-placeholder/activity-placeholder.component';
import { ActivitySimulationComponent } from '../components/activities/activity-simulation/activity-simulation.component';
import { ActivitySimulateUpdatesComponent } from '../components/activities/activity-simulate-updates/activity-simulate-updates.component';
import { ActivityManualTaskComponent } from '../components/activities/activity-manual-task/activity-manual-task.component';
import { SodMatrixComponent } from '../components/sod-matrix/sod-matrix.component';
import { ActivityWorkflowDataComponent } from '../components/activities/activity-workflow-data/activity-workflow-data.component';

@NgModule({
  declarations: [
    ExtraValuePipe,
    ExamValuePipe,
    StringToDatePipe,
    TranslateAttributePipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    EllipsisPipe,

    DynamicContainerDirective,
    AttributeLayoutDirective,
    UniquenessValidatorDirective,
    DistinctValidatorDirective,
    ApiValidatorDirective,
    XpathValidatorDirective,
    ExpressionValidatorDirective,
    DelayedHoverDirective,
    AutoFocusDirective,

    EditorCreatorComponent,
    EditorTextComponent,
    EditorTextConfigComponent,
    EditorBooleanComponent,
    EditorBooleanConfigComponent,
    EditorSelectComponent,
    EditorSelectConfigComponent,
    EditorDateComponent,
    EditorDateConfigComponent,
    EditorIdentityComponent,
    EditorIdentityConfigComponent,
    EditorIdentitiesComponent,
    EditorIdentitiesConfigComponent,
    EditorSeparatorComponent,
    EditorSeparatorConfigComponent,
    EditorButtonComponent,
    EditorButtonConfigComponent,
    EditorXpathComponent,
    EditorXpathConfigComponent,
    EditorFrameComponent,
    EditorFrameConfigComponent,
    EditorIterablesComponent,
    EditorIterablesConfigComponent,
    EditorObjectComponent,
    EditorObjectConfigComponent,
    EditorForEachComponent,
    EditorForEachConfigComponent,

    SigninComponent,
    EditMenuComponent,
    SearchComponent,
    SimpleSearchComponent,
    AccountComponent,
    WidgetCreatorComponent,
    AttributeViewComponent,
    ViewConfiguratorComponent,
    PopupTitleComponent,
    PopupBodyComponent,
    PopupWizardComponent,
    XpathBuilderComponent,

    ModalComponent,
    StateCardComponent,
    StateCardConfigComponent,
    HtmlCardConfigComponent,
    PhotoEditorComponent,
    ActionCardComponent,
    ResourceTableComponent,
    ResourceTableConfigComponent,
    ResourceTableDetailComponent,
    ResourceChartComponent,
    ResourceChartConfigComponent,
    TreeViewComponent,
    TreeListComponent,
    AttributePickerComponent,
    ActionMenuComponent,
    IdentityLinkComponent,
    ObjectHistoryComponent,
    EventGraphComponent,
    ApprovalCheckComponent,
    ApprovalCheckConfigComponent,
    ApprovalCheckCloudComponent,
    ApprovalCheckCloudConfigComponent,
    ApprovalInfoComponent,
    ApprovalDelegationComponent,
    FrameViewComponent,
    FrameViewConfigComponent,
    WorkflowDesignComponent,
    DataFlowRuleTypeComponent,
    ResourcePreviewComponent,
    GridFilterComponent,
    IdentityBrowserComponent,
    BreadCrumbComponent,
    TypePickerComponent,
    HtmlCardComponent,
    PhotoCropperComponent,
    ReportCardComponent,
    ReportCardConfigComponent,
    ReportCreatorComponent,
    ReportSeparatorComponent,
    ReportSeparatorConfigComponent,
    WorkflowStarterComponent,
    TagsExplorerComponent,
    ObjectExplorerComponent,
    ConflictsResolverComponent,

    ActivityCreatorComponent,
    ActivityAddDelayComponent,
    ActivityApprovalComponent,
    ActivityGeneralComponent,
    ActivityForEachComponent,
    ActivityUpdateResourceComponent,
    ActivityCreateResourceComponent,
    ActivityDeleteResourceComponent,
    ActivityRestApiCallComponent,
    ActivitySendEmailComponent,
    ActivityIfThenElseComponent,
    ActivityTemplateComponent,
    ActivityDataflowComponent,
    ActivityStatusComponent,
    ActivityPlaceholderComponent,
    ActivitySimulationComponent,
    ActivitySimulateUpdatesComponent,
    ActivityManualTaskComponent,
    ActivityWorkflowDataComponent,
    EditorForEachComponent,
    EditorForEachConfigComponent,
    SodMatrixComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    FlexLayoutModule,
    LocalizationModule,
    FontAwesomeModule,
    GridsterModule,
    ColorPickerModule,
    DragulaModule,
    NgxUiLoaderModule,
    // ParticlesModule,
    ClipboardModule,
    NgxMasonryModule,
    MglTimelineModule,
    NgxGraphModule,
    ImageCropperModule,
    NgDompurifyModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),

    DragDropModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatTabsModule,
    MatDialogModule,
    MatDividerModule,
    MatGridListModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSelectModule,
    MatStepperModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatRippleModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatListModule,
    MatSnackBarModule,
    MatMenuModule,
    MatSidenavModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatBadgeModule,

    GridModule,
    PDFModule,
    ExcelModule,
    ChartsModule,
    DropDownsModule,
    PopupModule,
    UploadModule,
    SplitterModule,
    DateInputsModule,
    InputsModule,
    TreeViewModule,
    DialogsModule,
    ButtonsModule,
    TreeListModule,
  ],
  providers: [
    ExtraValuePipe,
    ExamValuePipe,
    StringToDatePipe,
    TranslateAttributePipe,
    SafeHtmlPipe,
    SafeUrlPipe,

    TransService,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,

    FlexLayoutModule,
    LocalizationModule,
    FontAwesomeModule,
    GridsterModule,
    ColorPickerModule,
    NgxUiLoaderModule,
    // ParticlesModule,
    ClipboardModule,
    NgxMasonryModule,
    MglTimelineModule,
    NgxGraphModule,

    DragDropModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatTabsModule,
    MatDialogModule,
    MatDividerModule,
    MatGridListModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSelectModule,
    MatStepperModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatRippleModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatListModule,
    MatSnackBarModule,
    MatMenuModule,
    MatSidenavModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatBadgeModule,

    GridModule,
    PDFModule,
    ExcelModule,
    ChartsModule,
    DropDownsModule,
    PopupModule,
    UploadModule,
    SplitterModule,
    DateInputsModule,
    InputsModule,
    TreeViewModule,
    DialogsModule,
    ButtonsModule,
    TreeListModule,

    ExtraValuePipe,
    ExamValuePipe,
    StringToDatePipe,
    TranslateAttributePipe,
    SafeHtmlPipe,
    SafeUrlPipe,

    DynamicContainerDirective,
    AttributeLayoutDirective,
    UniquenessValidatorDirective,
    DistinctValidatorDirective,
    ApiValidatorDirective,
    XpathValidatorDirective,
    ExpressionValidatorDirective,
    DelayedHoverDirective,
    AutoFocusDirective,

    EditorCreatorComponent,
    EditorTextComponent,
    EditorTextConfigComponent,
    EditorBooleanComponent,
    EditorBooleanConfigComponent,
    EditorSelectComponent,
    EditorSelectConfigComponent,
    EditorDateComponent,
    EditorDateConfigComponent,
    EditorIdentityComponent,
    EditorIdentityConfigComponent,
    EditorIdentitiesComponent,
    EditorIdentitiesConfigComponent,
    EditorSeparatorComponent,
    EditorSeparatorConfigComponent,
    EditorButtonComponent,
    EditorButtonConfigComponent,
    EditorXpathComponent,
    EditorXpathConfigComponent,
    EditorFrameComponent,
    EditorFrameConfigComponent,
    EditorIterablesComponent,
    EditorIterablesConfigComponent,
    EditorObjectComponent,
    EditorObjectConfigComponent,

    SigninComponent,
    EditMenuComponent,
    SearchComponent,
    SimpleSearchComponent,
    AccountComponent,
    WidgetCreatorComponent,
    AttributeViewComponent,
    ViewConfiguratorComponent,
    PopupTitleComponent,
    PopupBodyComponent,
    PopupWizardComponent,
    XpathBuilderComponent,

    ModalComponent,
    StateCardComponent,
    StateCardConfigComponent,
    HtmlCardConfigComponent,
    PhotoEditorComponent,
    ActionCardComponent,
    ResourceTableComponent,
    ResourceTableConfigComponent,
    ResourceChartComponent,
    ResourceChartConfigComponent,
    TreeViewComponent,
    TreeListComponent,
    AttributePickerComponent,
    ActionMenuComponent,
    IdentityLinkComponent,
    ObjectHistoryComponent,
    EventGraphComponent,
    ApprovalCheckComponent,
    ApprovalCheckConfigComponent,
    ApprovalCheckCloudComponent,
    ApprovalCheckCloudConfigComponent,
    FrameViewComponent,
    FrameViewConfigComponent,
    WorkflowDesignComponent,
    DataFlowRuleTypeComponent,
    ResourcePreviewComponent,
    BreadCrumbComponent,
    TypePickerComponent,
    HtmlCardComponent,
    ReportCardComponent,
    ReportCardConfigComponent,
    ReportSeparatorComponent,
    ReportSeparatorConfigComponent,
    TagsExplorerComponent,
    ObjectExplorerComponent,
    ConflictsResolverComponent,

    ActivityCreatorComponent,
    ActivityAddDelayComponent,
    ActivityApprovalComponent,
    ActivityGeneralComponent,
    ActivityForEachComponent,
    ActivityUpdateResourceComponent,
    ActivityCreateResourceComponent,
    ActivityDeleteResourceComponent,
    ActivityRestApiCallComponent,
    ActivitySendEmailComponent,
    ActivityIfThenElseComponent,
    ActivityTemplateComponent,
    ActivityDataflowComponent,
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ConfigService,
        ResourceService,
        AuthService,
        StartupService,
        RouteGuardService,
        SwapService,
        ComponentIndexService,
        ComponentService,
        ModalService,
        InfoService,
        HelperService,
        SignalService,
      ],
    };
  }
}
