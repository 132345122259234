import {
  FormControl,
  FormGroup,
  FormArray,
  ControlValueAccessor,
  NgForm,
} from '@angular/forms';
import {
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  IterableDiffers,
  Injector,
  ViewChild,
  Directive,
  TemplateRef,
  Component,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatDialog } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';

import { EMPTY, Observable, Subscription } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { DragulaService } from 'ng2-dragula';

import {
  AttributeResource,
  Resource,
  BroadcastEvent,
  EditorEvent,
  ObjectViewEvent,
  FormConfig,
  ModelUpdateMode,
  WizardEvent,
} from './dataContract.model';
import { ModalType } from './componentContract.model';
import { EditorConfig, EditorCreationConfig } from './editorContract.model';
import { TransService } from './translation.model';

import { ResourceService } from '../services/resource.service';
import { SwapService } from '../services/swap.service';
import { UtilsService } from '../services/utils.service';
import { ModalService } from '../services/modal.service';

import { TabViewComponent } from 'src/app/tab-view/tab-view.component';
import { EditMenuComponent } from '../components/edit-menu/edit-menu.component';
import { EditorCreatorComponent } from '../components/editor-creator/editor-creator.component';
import { ViewConfiguratorComponent } from '../components/view-configurator/view-configurator.component';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import {
  WindowCloseResult,
  WindowRef,
  WindowService,
} from '@progress/kendo-angular-dialog';
import { GridsterViewComponent } from 'src/app/gridster-view/gridster-view.component';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PopupWizardComponent } from '../components/popup-wizard/popup-wizard.component';

/**
 * Interface for editor, which can be created dynamically
 */
export interface DynamicEditor {
  /** Attribute of the editor */
  attribute: AttributeResource;
  /** Editor configuration */
  config: EditorConfig;

  /** Initialize editor */
  initComponent: () => EditorConfig;
  /** configure editor */
  configure: () => Observable<EditorConfig>;
}

/**
 * Interface for editor result, holding all information of editor attribute and form control
 */
export interface EditorResult {
  type: string;
  config: EditorConfig;
  attribute: AttributeResource;
  controller: FormControl;
}

@Directive()
export class AttributeEditor implements DynamicEditor, ControlValueAccessor {
  public utils: UtilsService;
  public resource: ResourceService;

  editorConfig: EditorConfig;
  @Input()
  get config() {
    return this.editorConfig;
  }
  set config(value) {
    this.editorConfig = value;
    this.configChange.emit(this.editorConfig);
  }
  @Output()
  configChange = new EventEmitter();

  @Input()
  editorAttribute: AttributeResource;

  get attribute() {
    return this.editorAttribute;
  }
  set attribute(value) {
    this.editorAttribute = value;
    this.propagateChange(this.editorAttribute);
  }

  get value() {
    return this.editorAttribute ? this.editorAttribute.value : null;
  }
  set value(value) {
    this.editorAttribute.value = value;
    this.propagateChange(this.editorAttribute);
  }

  @Input()
  configMode = false;

  @Input()
  creationMode = false;

  @Input()
  simpleMode = false;

  @Input()
  viewMode = 'tab';

  @Input()
  currentID: string;

  @Input()
  currentType: string;

  @Input()
  currentResource: Resource;

  @Input()
  parameters: { [name: string]: string } = {};

  @Output()
  valueChange = new EventEmitter<any>();

  validationFn: (c: FormControl) => any;

  control: FormControl;

  private permissionCanRead(permissionHint: string): boolean {
    if (permissionHint === null || permissionHint === undefined) {
      return true;
    } else {
      if (permissionHint.match(/read/i)) {
        return true;
      } else {
        return false;
      }
    }
  }

  private permissionCanModify(permissionHint: string): boolean {
    if (permissionHint === null || permissionHint === undefined) {
      return true;
    } else {
      if (
        permissionHint.match(/modify/i) ||
        permissionHint.match(/add/i) ||
        permissionHint.match(/remove/i)
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  private inDeniedList(rightSets: string[]) {
    if (rightSets && rightSets.length > 0) {
      if (this.config.accessDenied && this.config.accessDenied.length > 0) {
        // if (rightSets.indexOf('Administrators') >= 0) {
        //   return false;
        // }
        for (const deniedSet of this.config.accessDenied) {
          if (rightSets.indexOf(deniedSet) >= 0) {
            return true;
          }
        }
      }
    } else {
      return true;
    }

    return false;
  }

  private inAllowedList(rightSets: string[]) {
    if (rightSets && rightSets.length > 0) {
      if (this.config.accessAllowed && this.config.accessAllowed.length > 0) {
        // if (rightSets.indexOf('Administrators') >= 0) {
        //   return true;
        // }
        for (const deniedSet of this.config.accessAllowed) {
          if (rightSets.indexOf(deniedSet) >= 0) {
            return true;
          }
        }
      } else {
        return true;
      }
    }

    return false;
  }

  protected applyDisplaySettings(
    swap: SwapService,
    resource: ResourceService,
    usedFor: string = null,
    optionValue: boolean = null
  ) {
    if (usedFor !== null && optionValue !== null) {
      if (usedFor === 'visibility') {
        this.config.calculatedDisplayable = optionValue;
      } else if (usedFor === 'editability') {
        this.config.calculatedEditable = optionValue;
      }

      return;
    }

    if (!this.configMode && !this.showEditor(resource.rightSets)) {
      swap.propagateEditorDisplayChanged({
        attributeName: this.config.attributeName,
        usedFor: this.config.accessUsedFor,
        optionValue: false,
        readAccess: this.readAccess,
      });
    } else {
      swap.propagateEditorDisplayChanged({
        attributeName: this.config.attributeName,
        usedFor: this.config.accessUsedFor,
        optionValue: true,
        readAccess: this.readAccess,
      });
    }

    if (this.config.accessExpression) {
      const regEx = /\[#\w+(-\w+)?#?\]/g;
      const match = regEx.exec(this.config.accessExpression);
      if (match && match.length > 0) {
        const attributeName = match[0].substr(2, match[0].length - 3);
        swap.propagateEditorDisplayChanged({
          attributeName,
          usedFor: this.config.accessUsedFor,
          optionValue: undefined,
          readAccess: this.readAccess,
        });
      }
    }
  }

  protected resolveExpression(expression: any) {
    const regEx = /\[#\w+(-\w+)?#?\]/g;

    let match = regEx.exec(expression);
    let resolvedExpression = expression;

    while (match) {
      const replaceName = match[0].substr(2, match[0].length - 3);

      if (replaceName.toLowerCase() === 'currentid') {
        resolvedExpression = resolvedExpression.replace(
          match[0],
          this.currentID
        );
      } else if (replaceName.toLowerCase() === 'currenttype') {
        resolvedExpression = resolvedExpression.replace(
          match[0],
          this.currentType
        );
      } else if (replaceName.toLowerCase() === 'loginid') {
        resolvedExpression = resolvedExpression.replace(
          match[0],
          this.utils.ExtraValue(this.resource.loginUser, 'ObjectID:value')
        );
      } else if (replaceName.toLowerCase() === 'loginsets') {
        resolvedExpression = resolvedExpression.replace(
          match[0],
          this.resource.rightSets ? this.resource.rightSets.join(',') : ''
        );
      } else {
        if (
          this.parameters &&
          this.parameters[replaceName] !== null &&
          this.parameters[replaceName] !== undefined
        ) {
          if (typeof this.parameters[replaceName] === 'object') {
            resolvedExpression = resolvedExpression.replace(
              match[0],
              JSON.stringify(this.parameters[replaceName])
            );
          } else {
            resolvedExpression = resolvedExpression.replace(
              match[0],
              this.parameters[replaceName]
            );
          }
        } else {
          const replaceValue = this.utils.ExtraValue(
            this.currentResource,
            `${replaceName}:value`
          );
          resolvedExpression = resolvedExpression.replace(
            match[0],
            replaceValue ?? ''
          );
        }
      }

      match = regEx.exec(expression);
    }

    if (
      resolvedExpression.startsWith('<') &&
      resolvedExpression.endsWith('>')
    ) {
      try {
        resolvedExpression = this.utils.evaluate(
          resolvedExpression.substring(1, resolvedExpression.length - 1)
        );
      } catch {}
    }

    return resolvedExpression;
  }

  get readAccess() {
    if (!this.editorAttribute) {
      return false;
    }

    return this.permissionCanRead(this.editorAttribute.permissionHint);
  }

  get writeAccess() {
    if (!this.editorAttribute) {
      return false;
    }

    return this.permissionCanModify(this.editorAttribute.permissionHint);
  }

  get displayName() {
    if (this.config.showDisplayName && this.editorAttribute) {
      if (this.config.customDisplayName) {
        return this.config.customDisplayName;
      } else {
        if (this.editorAttribute.displayName) {
          return this.editorAttribute.displayName;
        } else {
          if (
            this.editorAttribute.systemName &&
            this.editorAttribute.permissionHint
          ) {
            return this.editorAttribute.systemName;
          }
        }
        // return this.editorAttribute.displayName
        //   ? this.editorAttribute.displayName
        //   : this.editorAttribute.systemName;
      }
    }

    return undefined;
  }

  get description() {
    if (this.config.showDescription && this.editorAttribute) {
      if (this.config.customDescription) {
        return this.config.customDescription;
      } else {
        return this.editorAttribute.description;
      }
    } else {
      return undefined;
    }
  }

  get tooltip() {
    if (this.config.showTooltip && this.editorAttribute) {
      switch (this.config.tooltipContent) {
        case 'description':
          return this.editorAttribute.description;
        case 'systemname':
        default:
          return this.editorAttribute.systemName;
      }
    } else {
      return null;
    }
  }

  constructor(injector: Injector) {
    this.utils = injector.get(UtilsService);
    this.resource = injector.get(ResourceService);
  }

  disabled(rightSets: string[] = []) {
    if (!this.config.calculatedEditable) {
      return true;
    }

    if (this.config.readOnly || !this.writeAccess) {
      return true;
    }

    if (this.config.accessUsedFor === 'editability') {
      if (this.inDeniedList(rightSets)) {
        return true;
      }
      if (this.inAllowedList(rightSets)) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }

  showEditor(rightSets: string[] = []) {
    if (this.configMode === true && !this.config.calculatedDisplayable) {
      return false;
    }

    if (this.config.isHidden) {
      return false;
    }

    if (this.config.hideIfNoAccess && !this.readAccess) {
      return false;
    }

    if (this.config.accessUsedFor === 'visibility') {
      if (this.inDeniedList(rightSets)) {
        return false;
      }
      if (this.inAllowedList(rightSets)) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setConfig(config: any = null) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDisplay(usedFor: string = null, optionValue: boolean = null) {}

  // #region DynamicEditor implementation

  initComponent() {
    return null;
  }

  configure() {
    return null;
  }

  applyConfig() {}

  validateValue() {
    if (this.control) {
      this.control.updateValueAndValidity();
    }
    // this.propagateChange(this.attribute);
  }

  setError(hasError: boolean, errorMessage?: string) {
    this.config.hasError = hasError;
    this.config.errorMsg = errorMessage ? errorMessage : undefined;
  }

  // #endregion

  // #region ControlValueAccessor implementation

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  propagateChange = (_: any) => {};
  propagateTouched = () => {};

  writeValue(value: any) {
    this.editorAttribute = value;
  }

  registerOnChange(fn: (_: any) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.propagateTouched = fn;
  }

  // #endregion

  // #region Validator implementation

  validate(c: FormControl) {
    if (this.validationFn) {
      return this.validationFn(c);
    }
  }

  // #endregion
}

@Component({ template: '' })
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class WorkflowActivityView implements AfterViewInit, OnDestroy {
  protected subscription: Subscription = new Subscription();

  public updateOnChange = ModelUpdateMode.change;
  public updateOnBlure = ModelUpdateMode.blur;

  @ViewChild('activityForm') activityForm: NgForm;

  @Output() activityValidationChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  ngAfterViewInit(): void {
    if (this.activityForm) {
      this.subscription.add(
        this.activityForm.statusChanges.subscribe((value: string) => {
          if (value === 'VALID') {
            this.activityValidationChange.emit(true);
          } else if (value === 'INVALID') {
            this.activityValidationChange.emit(false);
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

@Component({ template: '' })
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AttributeEditorConfig {
  data: {
    component: DynamicEditor;
    config: EditorConfig;
    attribute: AttributeResource;
  };

  protected subscription: Subscription = new Subscription();

  protected windowRef: WindowRef;
  protected clipboard: ClipboardService;
  protected snackbar: MatSnackBar;
  protected translate: TransService;

  @ViewChild('titleBar') titleBar: TemplateRef<any>;

  leftPadding = 0;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  protected initComponent(padding = 60) {
    if (this.windowRef) {
      this.windowRef.window.instance.stateChange.subscribe((state: any) => {
        if (state === 'maximized') {
          this.leftPadding = padding;
        }
        if (state === 'default') {
          this.leftPadding = 0;
        }
      });
      if (this.titleBar) {
        this.windowRef.window.instance.titleBarTemplate = this.titleBar;
      }
    }

    this.subscription.add(
      this.clipboard.copyResponse$.subscribe((res: IClipboardResponse) => {
        if (res.isSuccess) {
          this.snackbar.open(this.translate.instant('key_textCopied'), 'OK', {
            duration: 2000,
          });
        }
      })
    );
  }

  constructor(injector: Injector) {
    this.windowRef = injector.get(WindowRef);
    this.clipboard = injector.get(ClipboardService);
    this.snackbar = injector.get(MatSnackBar);
    this.translate = injector.get(TransService);
  }

  onRefresh() {}

  setDefaultValidation() {
    if (this.data.attribute && this.data.attribute.stringRegex) {
      this.data.config.validation = this.data.attribute.stringRegex;
    }
  }

  onAddDeniedSet(event: MatChipInputEvent) {
    const input = event.chipInput.inputElement;
    const value = event.value;

    if ((value || '').trim()) {
      const index = this.data.config.accessDenied.indexOf(value.trim());
      if (index < 0) {
        this.data.config.accessDenied.push(value.trim());
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  onRemoveDeniedSet(setName: string) {
    const index = this.data.config.accessDenied.indexOf(setName);
    if (index >= 0) {
      this.data.config.accessDenied.splice(index, 1);
    }
  }

  onAddAllowedSet(event: MatChipInputEvent) {
    const input = event.chipInput.inputElement;
    const value = event.value;

    if ((value || '').trim()) {
      const index = this.data.config.accessAllowed.indexOf(value.trim());
      if (index < 0) {
        this.data.config.accessAllowed.push(value.trim());
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  onRemoveAllowedSet(setName: string) {
    const index = this.data.config.accessAllowed.indexOf(setName);
    if (index >= 0) {
      this.data.config.accessAllowed.splice(index, 1);
    }
  }

  onExportSetting() {
    this.clipboard.copy(JSON.stringify(this.data.config));
  }

  onCancel() {
    if (this.windowRef) {
      this.windowRef.close();
    }
  }

  onClose() {
    if (this.windowRef) {
      this.windowRef.close(this.data.config);
    }
  }
}

@Directive()
export class AttributeView {
  protected subscription: Subscription = new Subscription();

  protected utils: UtilsService;
  protected swap: SwapService;
  protected differs: IterableDiffers;
  protected dragula: DragulaService;
  protected modal: ModalService;
  protected resource: ResourceService;

  @ViewChildren('editor') editors: QueryList<AttributeEditor>;

  attDefs: Array<any> = [];
  @Input()
  get attributeDefs() {
    return this.attDefs;
  }
  set attributeDefs(value) {
    this.attDefs = value;
    this.attributeDefsChange.emit(this.attDefs);
  }
  @Output()
  attributeDefsChange = new EventEmitter();

  @Input()
  configMode = false;

  @Input()
  creationMode = false;

  @Input()
  simpleMode = false;

  @Input()
  columnNumber = 1;

  @Input()
  tabName: string;

  @Input()
  gridsterName: string;

  @Input()
  parameters: { [name: string]: string } = {};

  @Input()
  viewType = 'tab';

  @Input()
  attributesToLoad = ['DisplayName'];

  attArray: Array<EditorResult> = [];
  @Input()
  get attributeArray() {
    return this.attArray;
  }
  set attributeArray(value) {
    this.attArray = value;
    this.attributeArrayChange.emit(this.attArray);
  }
  @Output()
  attributeArrayChange = new EventEmitter();

  resource2Create: Resource;
  @Input()
  get resourceToCreate() {
    return this.resource2Create;
  }
  set resourceToCreate(value) {
    this.resource2Create = value;
    this.resourceToCreateChange.emit(this.resource2Create);
  }
  @Output()
  resourceToCreateChange = new EventEmitter();

  currentResource: Resource;
  obsCurrentResource: Observable<Resource>;

  get currentID() {
    if (this.currentResource) {
      return this.utils.ExtraValue(this.currentResource, 'ObjectID:value');
    } else {
      return undefined;
    }
  }

  get currentType() {
    if (this.currentResource) {
      return this.utils.ExtraValue(this.currentResource, 'ObjectType:value');
    } else {
      return undefined;
    }
  }

  resourceForm: FormGroup = new FormGroup({
    controls: new FormArray([]),
  });
  get controls() {
    return this.resourceForm.get('controls') as FormArray;
  }

  differ: any;

  hiddenAttributes: Array<string> = [];
  noReadAttributes: Array<string> = [];

  displayOptions: {
    [name: string]: { hideAttribute: boolean; removeAttribute: boolean };
  } = {};

  attributeInsertions: Array<{ name: string; value: string }> = [];
  attributeRemovals: Array<{ name: string; value: string }> = [];

  private inAllowList(allowed: string[]) {
    if (allowed && allowed.length > 0) {
      for (const item of allowed) {
        if (this.resource.rightSets.indexOf(item) >= 0) {
          return true;
        }
      }
    } else {
      return true;
    }

    return false;
  }

  private inDenyList(denied: string[]) {
    if (denied && denied.length > 0) {
      for (const item of denied) {
        if (this.resource.rightSets.indexOf(item) >= 0) {
          return true;
        }
      }
    } else {
      return false;
    }

    return false;
  }

  private setDisplayOption(attribute: string, showAttribute: boolean) {
    let attributeKey = '';
    for (const key of Object.keys(this.displayOptions)) {
      if (key.toLowerCase() === attribute.toLowerCase()) {
        attributeKey = key;
      }
    }
    if (attributeKey) {
      const pos = this.attributeDefs.findIndex(
        (a) => a.attributeName.toLowerCase() === attributeKey.toLowerCase()
      );
      if (pos >= 0) {
        const theDef = this.attributeDefs[pos];
        if (theDef.editorConfig) {
          // display setting
          if (showAttribute) {
            this.displayOptions[attributeKey].hideAttribute = false;
            this.displayOptions[attributeKey].removeAttribute = false;

            // permission hint
            const theAttr = this.attributeArray.filter(
              (a) =>
                a.attribute &&
                a.attribute.systemName &&
                a.attribute.systemName.toLowerCase() ===
                  attributeKey.toLowerCase()
            );
            if (theAttr.length === 1) {
              if (
                theAttr[0].attribute.permissionHint === undefined ||
                theAttr[0].attribute.permissionHint === null
              ) {
                this.displayOptions[attributeKey] = {
                  hideAttribute: false,
                  removeAttribute: false,
                };
              } else {
                if (theAttr[0].attribute.permissionHint.indexOf('Read') >= 0) {
                  this.displayOptions[attributeKey] = {
                    hideAttribute: false,
                    removeAttribute: false,
                  };
                } else {
                  this.displayOptions[attributeKey] = {
                    hideAttribute: theDef.editorConfig.hideIfNoAccess,
                    removeAttribute: false,
                  };
                }
              }
            } else {
              this.displayOptions[attributeKey] = {
                hideAttribute: false,
                removeAttribute: false,
              };
            }
          } else {
            this.displayOptions[attributeKey].hideAttribute =
              !theDef.editorConfig.hideFromDOM;
            this.displayOptions[attributeKey].removeAttribute =
              theDef.editorConfig.hideFromDOM;

            if (theDef.editorConfig.hideFromDOM) {
              this.currentResource[attributeKey].value = null;
              this.currentResource[attributeKey].values = null;
            }
          }
        } else {
          this.displayOptions[attributeKey].hideAttribute = !showAttribute;
          this.displayOptions[attributeKey].removeAttribute = false;
        }
      } else {
        this.displayOptions[attributeKey].hideAttribute = !showAttribute;
        this.displayOptions[attributeKey].removeAttribute = false;
      }
    }
  }

  protected initDisplayOptions(hideAttribute = true) {
    if (this.attributeDefs && this.attributeDefs.length > 0) {
      this.attributeDefs.forEach((attributeDef: any) => {
        if (attributeDef.attributeName) {
          this.displayOptions[attributeDef.attributeName] = {
            hideAttribute,
            removeAttribute: false,
          };
        }
      });
    }
  }

  protected clearFormArray(formArray: FormArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  protected maintainHiddenAttributes(option: {
    attributeName: string;
    optionValue: boolean;
  }) {
    if (option) {
      const index = this.hiddenAttributes.indexOf(option.attributeName);
      if (option.optionValue) {
        if (index >= 0) {
          this.hiddenAttributes.splice(index, 1);
        }
        this.setDisplayOption(option.attributeName, true);
      } else {
        if (index < 0) {
          this.hiddenAttributes.push(option.attributeName);
          this.setDisplayOption(option.attributeName, false);
        }
      }
    }
  }

  // protected prepareAttributes(arrayBackup: EditorResult[]) {
  //   this.clearFormArray(this.controls);
  //   this.attributeArray.splice(0, this.attributeArray.length);

  //   this.attributeDefs.forEach((a) => {
  //     let attribute = this.utils.ExtraValue(
  //       this.currentResource,
  //       a.attributeName
  //     );

  //     // let validatorFn: ValidatorFn;
  //     // switch (a.editorType) {
  //     //   case 'text':
  //     //     validatorFn = createTextEditorValidator(a.editorConfig);
  //     //     break;
  //     //   case 'boolean':
  //     //     validatorFn = createBooleanEditorValidator(a.editorConfig);
  //     //     break;
  //     //   case 'select':
  //     //     validatorFn = createSelectEditorValidator(a.editorConfig);
  //     //     break;
  //     //   default:
  //     //     break;
  //     // }
  //     // const controller = new FormControl(attribute, validatorFn);

  //     if (!attribute) {
  //       attribute = {
  //         systemName: a.editorConfig.attributeName,
  //         dataType: 'string',
  //         multivalued: false,
  //         value: null,
  //         values: null,
  //       };
  //     }

  //     const pos = arrayBackup.findIndex(
  //       (attr) => attr.attribute.systemName === attribute.systemName
  //     );

  //     const controller = new FormControl(attribute);
  //     this.attributeArray.push({
  //       type: a.editorType,
  //       config: pos >= 0 ? arrayBackup[pos].config : a.editorConfig,
  //       attribute,
  //       controller,
  //     });
  //     this.controls.push(controller);
  //   });

  //   this.registerChangeHandler();
  // }

  protected registerChangeHandler() {}

  protected handleResourceChangeResponse(res: HttpResponse<any>) {}

  protected resolveExpression(expression: string) {
    const regEx = /\[#\w+(-\w+)?#?\]/g;

    let match = regEx.exec(expression);
    let resolvedExpression = expression;

    while (match) {
      const replaceName = match[0].substr(2, match[0].length - 3);

      if (replaceName.toLowerCase() === 'currentid') {
        resolvedExpression = resolvedExpression.replace(
          match[0],
          this.currentID
        );
      } else if (replaceName.toLowerCase() === 'currenttype') {
        resolvedExpression = resolvedExpression.replace(
          match[0],
          this.currentType
        );
      } else if (replaceName.toLowerCase() === 'loginid') {
        resolvedExpression = resolvedExpression.replace(
          match[0],
          this.utils.ExtraValue(this.resource.loginUser, 'ObjectID:value')
        );
      } else if (replaceName.toLowerCase() === 'loginsets') {
        resolvedExpression = resolvedExpression.replace(
          match[0],
          this.resource.rightSets ? this.resource.rightSets.join(',') : ''
        );
      } else {
        if (
          this.parameters &&
          this.parameters[replaceName] !== null &&
          this.parameters[replaceName] !== undefined
        ) {
          resolvedExpression = resolvedExpression.replace(
            match[0],
            this.parameters[replaceName]
          );
        } else {
          const v = this.getValue(replaceName);
          resolvedExpression = resolvedExpression.replace(
            match[0],
            v === null || v === undefined ? '' : v
          );
        }
      }

      match = regEx.exec(expression);
    }

    return resolvedExpression;
  }

  protected applyDisplay(valueExpressionDic: {
    [key: string]: Array<{
      name: string;
      value: string;
      allowed: string[];
      denied: string[];
    }>;
  }) {
    if (Object.keys(valueExpressionDic).length > 0) {
      Object.keys(valueExpressionDic).forEach((dicKey) => {
        valueExpressionDic[dicKey].forEach((expression) => {
          const expressionValue = this.resolveExpression(expression.value);
          if (
            expressionValue.startsWith('<') &&
            expressionValue.endsWith('>')
          ) {
            try {
              const optionValue: boolean = this.utils.evaluate(
                expressionValue.substring(1, expressionValue.length - 1)
              );

              const setAllowed =
                !this.inDenyList(expression.denied) &&
                this.inAllowList(expression.allowed);

              const editor = this.getEditor(dicKey);
              if (editor) {
                editor.setDisplay(
                  editor.config.accessUsedFor,
                  optionValue && setAllowed
                );
                if (editor.config.accessUsedFor === 'visibility') {
                  this.maintainHiddenAttributes({
                    attributeName: dicKey,
                    optionValue: optionValue && setAllowed,
                  });
                }
              } else {
                this.maintainHiddenAttributes({
                  attributeName: dicKey,
                  optionValue: optionValue && setAllowed,
                });
              }
            } catch (err) {
              console.log(err);
            }
          } else {
            // only expression within '<' and '>' is allowed
          }
        });
      });
    }
  }

  protected applyValue(valueExpressionDic: {
    [key: string]: Array<{ name: string; value: string }>;
  }) {
    if (Object.keys(valueExpressionDic).length > 0) {
      Object.keys(valueExpressionDic).forEach((dicKey) => {
        valueExpressionDic[dicKey].forEach((expression) => {
          const expressionValue = this.resolveExpression(expression.value);
          if (
            expressionValue.startsWith('<') &&
            expressionValue.endsWith('>')
          ) {
            this.setValue(
              dicKey,
              this.utils.evaluate(
                expressionValue.substring(1, expressionValue.length - 1)
              )
            );
          } else {
            this.setValue(dicKey, expressionValue);
          }
        });
      });
    }
  }

  protected applyConfig(configExpressionDic: {
    [key: string]: Array<{ name: string; value: string }>;
  }) {
    if (Object.keys(configExpressionDic).length > 0) {
      Object.keys(configExpressionDic).forEach((dicKey) => {
        configExpressionDic[dicKey].forEach((expression) => {
          const expressionValue = this.resolveExpression(expression.value);
          const editor = this.getEditor(dicKey);
          if (
            expressionValue.startsWith('<') &&
            expressionValue.endsWith('>')
          ) {
            if (editor) {
              editor.setConfig({
                name: expression.name,
                value: this.utils.evaluate(
                  expressionValue.substring(1, expressionValue.length - 1)
                ),
              });
            } else {
              this.swap.broadcast({
                name: dicKey,
                parameter: {
                  name: 'setConfig',
                  value: this.utils.evaluate(
                    expressionValue.substring(1, expressionValue.length - 1)
                  ),
                },
              });
            }
          } else {
            if (editor) {
              editor.setConfig({
                name: expression.name,
                value: expressionValue,
              });
            } else {
              this.swap.broadcast({
                name: dicKey,
                parameter: {
                  method: 'setConfig',
                  name: expression.name,
                  value: expressionValue,
                },
              });
            }
          }
        });
      });
    }
  }

  protected initComponent() {
    this.subscription.add(
      this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
        if (event) {
          switch (event.name) {
            case 'save-attribute':
              {
                // prepare multivalue insertions and removals
                const multivalueInsertions: { [name: string]: string[] } = {};
                if (
                  this.attributeInsertions &&
                  this.attributeInsertions.length > 0
                ) {
                  this.attributeInsertions.forEach(
                    (insertion: { name: string; value: string }) => {
                      if (!insertion.name.endsWith('#')) {
                        if (multivalueInsertions[insertion.name]) {
                          multivalueInsertions[insertion.name].push(
                            insertion.value
                          );
                        } else {
                          multivalueInsertions[insertion.name] = [
                            insertion.value,
                          ];
                        }
                      }
                    }
                  );
                }

                const multivalueRemovals: { [name: string]: string[] } = {};
                if (
                  this.attributeRemovals &&
                  this.attributeRemovals.length > 0
                ) {
                  this.attributeRemovals.forEach(
                    (removal: { name: string; value: string }) => {
                      if (!removal.name.endsWith('#')) {
                        if (multivalueRemovals[removal.name]) {
                          multivalueRemovals[removal.name].push(removal.value);
                        } else {
                          multivalueRemovals[removal.name] = [removal.value];
                        }
                      }
                    }
                  );
                }

                if (
                  event.parameter === this.tabName ||
                  event.parameter === '!preview!'
                ) {
                  const attributeResult: { [key: string]: any } = {};

                  this.attributeArray.forEach((result) => {
                    if (!result.attribute.systemName.endsWith('#')) {
                      if (result.controller.dirty) {
                        if (
                          !multivalueInsertions[result.attribute.systemName] &&
                          !multivalueRemovals[result.attribute.systemName]
                        ) {
                          attributeResult[result.attribute.systemName] =
                            this.utils.ToSaveValue(result.controller.value);
                        }
                      }
                    }
                  });

                  if (Object.keys(multivalueInsertions).length > 0) {
                    attributeResult['$MultivalueInsertions'] =
                      multivalueInsertions;
                  }
                  if (Object.keys(multivalueRemovals).length > 0) {
                    attributeResult['$MultivalueRemovals'] = multivalueRemovals;
                  }

                  attributeResult.ObjectID = this.currentID;
                  attributeResult.ObjectType = this.currentType;

                  if (event.parameter === '!preview!') {
                    this.swap.broadcast({
                      name: 'preview-resource',
                      parameter: attributeResult,
                    });
                  } else {
                    const progress = this.modal.show(
                      ModalType.progress,
                      'key_savingChanges',
                      '',
                      '300px'
                    );
                    this.subscription.add(
                      this.resource
                        .updateResource(attributeResult)
                        .pipe(
                          finalize(() => {
                            if (progress) {
                              progress.close();
                            }
                          })
                        )
                        .subscribe(
                          (result: HttpResponse<string>) => {
                            if (result.status === 202) {
                              this.handleResourceChangeResponse(result);
                            }
                            if (this.tabName === '!advancedView!') {
                              this.swap.broadcast({
                                name: 'close-advancedView',
                              });
                            } else {
                              this.swap.broadcast({
                                name: 'refresh-attribute',
                              });
                            }
                            this.swap.broadcast({
                              name: 'refresh-after-change',
                            });
                          },
                          (error: HttpErrorResponse) => {
                            this.modal.show(
                              ModalType.error,
                              'key_error',
                              error.error
                            );
                          }
                        )
                    );
                  }
                }
              }
              break;
            case 'refresh-attribute':
              // if (event.parameter === this.tabName) {
              //   this.refresh();
              // }
              this.refreshSelf();
              break;
            case 'refresh-language':
              this.swap.broadcast({
                name: 'refresh-attribute',
              });
              break;
            default:
              break;
          }
        }
      })
    );

    if (!this.simpleMode) {
      this.subscription.add(
        this.swap.editorDisplayChanged.subscribe(
          (option: {
            attributeName: string;
            usedFor: string;
            optionValue: boolean;
            readAccess: boolean;
          }) => {
            if (option && option.attributeName) {
              if (!option.readAccess) {
                this.noReadAttributes.push(option.attributeName);
              }
              if (option.optionValue === undefined && this.attributeArray) {
                const configs = this.attributeArray.map((a) => a.config);
                const displayExpressionDic = this.utils.GetEditorExpressions(
                  option.attributeName,
                  configs,
                  'accessExpression'
                );
                this.applyDisplay(displayExpressionDic);
              } else {
                this.maintainHiddenAttributes(option);
                const editor = this.getEditor(option.attributeName);
                if (editor) {
                  editor.setDisplay(
                    editor.config.accessUsedFor,
                    option.optionValue
                  );
                }
              }
            }
          }
        )
      );
    }

    if (!this.simpleMode) {
      this.subscription.add(
        this.swap.onEditorEvent.subscribe((event: EditorEvent) => {
          if (
            event &&
            (event.name === 'change' ||
              event.name === 'addValue' ||
              event.name === 'removeValue')
          ) {
            if (!this.attributeArray) {
              return;
            }

            const configs = this.attributeArray.map((a) => a.config);

            const displayExpressionDic = this.utils.GetEditorExpressions(
              event.attributeName,
              configs,
              'accessExpression'
            );
            this.applyDisplay(displayExpressionDic);

            const configExpressionDic = this.utils.GetEditorExpressions(
              event.attributeName,
              configs,
              'query'
            );
            this.applyConfig(configExpressionDic);

            const valueExpressionDic = this.utils.GetEditorExpressions(
              event.attributeName,
              configs,
              'expression'
            );
            this.applyValue(valueExpressionDic);
          }
        })
      );
    }

    if (!this.simpleMode) {
      this.subscription.add(
        this.swap.editorConfigChanged.subscribe(
          (config: {
            [key: string]: Array<{ name: string; value: string }>;
          }) => {
            if (!this.attributeArray) {
              return;
            }
            if (config) {
              this.applyConfig(config);
            }
          }
        )
      );
    }
  }

  constructor(injector: Injector) {
    this.utils = injector.get(UtilsService);
    this.swap = injector.get(SwapService);
    this.differs = injector.get(IterableDiffers);
    this.dragula = injector.get(DragulaService);
    this.modal = injector.get(ModalService);
    this.resource = injector.get(ResourceService);

    this.differ = this.differs.find([]).create(null);

    try {
      this.dragula.createGroup('ATTRIBUTECOLUMN', {
        moves: (el, container, handle) => {
          return (
            handle.classList.contains('handle') ||
            (handle.parentNode as Element).classList.contains('handle')
          );
        },
      });
    } catch {}
  }

  isRemovedAttribute(name: string) {
    return (
      Object.entries(this.displayOptions).findIndex(([key, value]) => {
        return (
          key.toLowerCase() === name.toLowerCase() &&
          value.removeAttribute === true
        );
      }) >= 0
    );
  }

  isHiddenAttribute(name: string) {
    return (
      Object.entries(this.displayOptions).findIndex(([key, value]) => {
        return (
          key.toLowerCase() === name.toLowerCase() &&
          value.hideAttribute === true
        );
      }) >= 0
    );
  }

  onConfig(attributeName: string) {
    const editor = this.getEditor(attributeName);

    if (editor) {
      editor.configure().subscribe((config: EditorConfig) => {
        editor.config = config;
      });
    }
  }

  onDelete(attribute: EditorResult) {
    let pos = this.attributeArray.findIndex(
      (a) => a.attribute.systemName === attribute.attribute.systemName
    );
    if (pos >= 0) {
      this.attributeArray.splice(pos, 1);
    }

    pos = this.attributeDefs.findIndex(
      (d) => d.attributeName === attribute.attribute.systemName
    );
    if (pos >= 0) {
      this.attributeDefs.splice(pos, 1);
    }
  }

  refresh() {}

  refreshSelf() {}

  getFlexUnits(result: EditorResult) {
    if (this.viewType === 'gridster') {
      return (100 * result.config.gridsterUnits) / 12;
    }
    if (result.config.units) {
      return (100 * result.config.units) / 12;
    }
    return 100;
  }

  getEditor(attributeName: string): AttributeEditor {
    if (attributeName) {
      if (this.editors) {
        const attEditor = this.editors.find(
          (e) =>
            e.attribute &&
            e.attribute.systemName &&
            e.attribute.systemName.toLowerCase() === attributeName.toLowerCase()
        );
        if (attEditor) {
          return attEditor;
        } else {
          return this.editors.find(
            (e) =>
              e.config &&
              e.config.attributeName &&
              e.config.attributeName.toLowerCase() ===
                attributeName.toLowerCase()
          );
        }
      }
    }
    return null;
  }

  getControl(attributeName: string): FormControl {
    const attribute = this.attributeArray.find(
      (a) => a.config.attributeName === attributeName
    );
    return attribute ? attribute.controller : undefined;
  }

  getControllerIndex(attributeName: string) {
    return this.attributeArray.findIndex(
      (a) => a.attribute.systemName === attributeName
    );
  }

  getValue(attributeName: string) {
    const editor = this.getEditor(attributeName);
    return editor ? this.utils.ToSaveValue(editor.attribute) : undefined;
  }

  setValue(attributeName: string, value: any) {
    const editor = this.getEditor(attributeName);
    if (editor) {
      editor.value = value;
    }
  }

  showAttributeEditor(attributeName: string) {
    if (this.configMode) {
      return true;
    }
    return this.hiddenAttributes.indexOf(attributeName) >= 0 ? false : true;
  }

  showAttributeEditorInDOM(config: EditorConfig) {
    if (this.configMode) {
      return true;
    }
    return this.hiddenAttributes.indexOf(config.attributeName) >= 0 &&
      config.hideFromDOM
      ? false
      : true;
  }
}

@Directive()
export class ObjectView {
  protected genericEditingForm = {
    objectType: '',
    type: 'tab',
    sampleSetting: true,
    icon: 'fingerprint',
    brandView: {
      attributes: [],
      enabled: false,
      mainAttribute: 'DisplayName',
      photoAttribute: 'Photo',
      secondaryAttribute: '',
      showButtons: false,
      useCustomComponent: false,
      customComponentData: '',
    },
    structureView: {
      enabled: false,
      initQuery: "/*[ObjectID='[#CurrentID]']",
    },
    sections: [
      {
        cols: 2,
        rows: 2,
        x: 0,
        y: 0,
        name: 'general',
        displayName: 'key_general',
        columnNumber: 2,
        attributes: [
          {
            attributeName: 'DisplayName',
            editorType: 'text',
            editorConfig: {
              name: 'text',
              attributeName: 'DisplayName',
              units: 6,
              gridsterUnits: 12,
              editMode: true,
              isHidden: false,
              hideFromDOM: false,
              showTooltip: true,
              showDisplayName: true,
              showDescription: true,
              tooltipContent: 'systemname',
              readOnly: false,
              required: false,
              requiredFromSchema: false,
              hideIfNoAccess: true,
              accessAllowed: [],
              accessDenied: [],
              accessUsedFor: 'visibility',
              calculatedDisplayable: true,
              calculatedEditable: true,
              hasError: false,
              isMultivalue: false,
              isPassword: false,
              isNumber: false,
              prefix: '',
              savePrefix: false,
              noPrefixIfEmpty: true,
              rows: 1,
              autoComplete: false,
              autoCompleteOptions: [],
            },
          },
          {
            attributeName: 'ObjectID',
            editorType: 'text',
            editorConfig: {
              name: 'text',
              attributeName: 'ObjectID',
              units: 6,
              gridsterUnits: 12,
              editMode: true,
              isHidden: false,
              hideFromDOM: false,
              showTooltip: true,
              showDisplayName: true,
              showDescription: false,
              tooltipContent: 'systemname',
              readOnly: true,
              required: false,
              requiredFromSchema: false,
              hideIfNoAccess: true,
              accessAllowed: [],
              accessDenied: [],
              accessUsedFor: 'visibility',
              calculatedDisplayable: true,
              calculatedEditable: true,
              hasError: false,
              isMultivalue: false,
              isPassword: false,
              isNumber: false,
              prefix: '',
              savePrefix: false,
              noPrefixIfEmpty: true,
              rows: 1,
              autoComplete: false,
              autoCompleteOptions: [],
            },
          },
          {
            attributeName: 'CreatedTime',
            editorType: 'date',
            editorConfig: {
              name: 'date',
              attributeName: 'CreatedTime',
              units: 6,
              gridsterUnits: 12,
              editMode: true,
              isHidden: false,
              hideFromDOM: false,
              showTooltip: true,
              showDisplayName: true,
              showDescription: false,
              tooltipContent: 'systemname',
              readOnly: true,
              required: false,
              requiredFromSchema: false,
              hideIfNoAccess: true,
              accessAllowed: [],
              accessDenied: [],
              accessUsedFor: 'visibility',
              calculatedDisplayable: true,
              calculatedEditable: true,
              hasError: false,
              showNavigation: true,
              showWeekNumber: false,
              showTime: false,
              useMinDate: false,
              useMaxDate: false,
              minDate: null,
              maxDate: null,
              configMinDate: null,
              configMaxDate: null,
            },
          },
          {
            attributeName: 'Creator',
            editorType: 'identity',
            editorConfig: {
              name: 'identity',
              attributeName: 'Creator',
              units: 6,
              gridsterUnits: 12,
              editMode: true,
              isHidden: false,
              hideFromDOM: false,
              showTooltip: true,
              showDisplayName: true,
              showDescription: false,
              tooltipContent: 'systemname',
              readOnly: true,
              required: false,
              requiredFromSchema: false,
              hideIfNoAccess: true,
              accessAllowed: [],
              accessDenied: [],
              accessUsedFor: 'visibility',
              calculatedDisplayable: true,
              calculatedEditable: true,
              hasError: false,
              objectType: 'Person',
              isMultivalue: false,
              lettersToTrigger: 3,
              suggestionNumber: 6,
              allowEmptySearch: false,
              attributesToShow: [
                {
                  field: 'DisplayName',
                  width: null,
                  filterable: false,
                  filter: 'text',
                  sortable: true,
                  locked: false,
                },
              ],
              showPhoto: false,
              photoAttribute: 'Photo',
              photoPlaceHolder: 'photo',
              popupWidth: 0,
              popupHeight: 0,
              emptySearchMaxCount: 50,
              browserShowTypePicker: false,
              browserDefaultType: 'Person',
              browserScrollMode: 'basic',
              browserScrollHeight: 36,
            },
          },
          {
            attributeName: 'Description',
            editorType: 'text',
            editorConfig: {
              name: 'text',
              attributeName: 'Description',
              units: 12,
              gridsterUnits: 12,
              editMode: true,
              isHidden: false,
              hideFromDOM: false,
              showTooltip: true,
              showDisplayName: true,
              showDescription: true,
              tooltipContent: 'systemname',
              readOnly: false,
              required: false,
              requiredFromSchema: false,
              hideIfNoAccess: true,
              accessAllowed: [],
              accessDenied: [],
              accessUsedFor: 'visibility',
              calculatedDisplayable: true,
              calculatedEditable: true,
              hasError: false,
              isMultivalue: false,
              isPassword: false,
              isNumber: false,
              prefix: '',
              savePrefix: false,
              noPrefixIfEmpty: true,
              rows: 3,
              autoComplete: false,
              autoCompleteOptions: [],
            },
          },
        ],
      },
    ],
  };
  protected genericCreationForm = {
    objectType: '',
    type: 'stepper',
    icon: 'fingerprint',
    sections: [
      {
        name: 'general',
        displayName: 'key_general',
        attributes: [
          {
            attributeName: 'DisplayName',
            editorType: 'text',
            editorConfig: {
              name: 'text',
              attributeName: 'DisplayName',
              units: 12,
              gridsterUnits: 12,
              editMode: true,
              isHidden: false,
              hideFromDOM: false,
              showTooltip: true,
              showDisplayName: true,
              showDescription: true,
              tooltipContent: 'systemname',
              readOnly: false,
              required: false,
              requiredFromSchema: false,
              hideIfNoAccess: true,
              accessAllowed: [],
              accessDenied: [],
              accessUsedFor: 'visibility',
              calculatedDisplayable: true,
              calculatedEditable: true,
              hasError: false,
              isMultivalue: false,
              isPassword: false,
              isNumber: false,
              prefix: '',
              savePrefix: false,
              noPrefixIfEmpty: true,
              rows: 1,
              autoComplete: false,
              autoCompleteOptions: [],
            },
          },
          {
            attributeName: 'Description',
            editorType: 'text',
            editorConfig: {
              name: 'text',
              attributeName: 'Description',
              units: 12,
              gridsterUnits: 12,
              editMode: true,
              isHidden: false,
              hideFromDOM: false,
              showTooltip: true,
              showDisplayName: true,
              showDescription: true,
              tooltipContent: 'systemname',
              readOnly: false,
              required: false,
              requiredFromSchema: false,
              hideIfNoAccess: true,
              accessAllowed: [],
              accessDenied: [],
              accessUsedFor: 'visibility',
              calculatedDisplayable: true,
              calculatedEditable: true,
              hasError: false,
              isMultivalue: false,
              isPassword: false,
              isNumber: false,
              prefix: '',
              savePrefix: false,
              noPrefixIfEmpty: true,
              rows: 3,
              autoComplete: false,
              autoCompleteOptions: [],
            },
          },
        ],
      },
    ],
    isCreation: true,
    width: 800,
    displayName: 'key_creationWizard',
    maxHeight: 600,
  };

  protected subscription: Subscription = new Subscription();

  protected router: Router;
  protected route: ActivatedRoute;
  protected dialog: MatDialog;
  protected modal: ModalService;
  protected resource: ResourceService;
  protected swap: SwapService;
  protected utils: UtilsService;
  protected window: WindowService;

  @ViewChild('tabView') tabView: TabViewComponent;
  @ViewChild('gridView') gridView: GridsterViewComponent;
  @ViewChild('editMenu') editMenu: EditMenuComponent;

  viewName: string;
  sectionName: string;
  viewSetting: FormConfig;
  viewSettingCopy: FormConfig;

  showEditMenu = false;
  menuActions: Array<string> = [];
  configMode = false;

  brandWidth = 20;
  detailWidth = 80;
  showBrand = true;

  viewResults: { [key: string]: Array<EditorResult> } = {};

  protected loadSettings() {
    try {
      if (!this.sectionName) {
        this.viewSetting = this.resource.primaryViewSetting[this.viewName];
      } else {
        this.viewSetting =
          this.resource.primaryViewSetting[this.sectionName][this.viewName];
      }
    } catch {
      this.viewSetting = this.genericEditingForm;
      this.viewSetting.objectType = this.viewName;
    }
  }

  protected initComponent(
    sectionName: string,
    viewName: string,
    subscribe = true
  ) {
    this.viewResults = {};

    this.showEditMenu = this.swap.editMode;

    this.viewName = viewName;
    this.sectionName = sectionName;

    this.loadSettings();

    if (!this.viewSetting) {
      if (sectionName === 'editingView') {
        this.viewSetting = this.genericEditingForm;
      } else if (sectionName === 'creationView') {
        this.viewSetting = this.genericCreationForm;
      }
      this.viewSetting.objectType = this.viewName;
    }

    this.viewSetting.sections.forEach((s: any) => {
      this.viewResults[s.name] = [];
    });

    if (this.viewSetting.type === 'tab') {
      this.menuActions = ['save', 'setting', 'add'];
    } else if (this.viewSetting.type === 'gridster') {
      this.menuActions = ['save', 'setting'];
    }

    if (subscribe) {
      // Subscribe editor events
      this.subscription.add(
        this.swap.onEditorEvent.subscribe((event: EditorEvent) => {
          this.swap.objectViewEvent(
            new ObjectViewEvent('editor', event, this.tabView)
          );
        })
      );

      // Subscribe view after init
      this.subscription.add(
        this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
          if (
            event &&
            event.name === this.swap.EVENT_ATTRIBUTE_VIEW_AFTER_INIT
          ) {
            // editing view
            if (this.tabView) {
              this.swap.objectViewEvent(
                new ObjectViewEvent('afterInit', null, this.tabView)
              );
            }
            // wizard view
            else {
              // eslint-disable-next-line @typescript-eslint/no-this-alias
              const wizard: any = this;
              this.swap.wizardEvent(
                new WizardEvent('afterInit', wizard as PopupWizardComponent)
              );
            }
          }
        })
      );

      // Subscribe view before save
      this.subscription.add(
        this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
          if (
            event &&
            event.name === this.swap.EVENT_ATTRIBUTE_VIEW_BEFORE_SAVE
          ) {
            this.swap.objectViewEvent(
              new ObjectViewEvent(
                'beforeSave',
                null,
                this.tabView,
                event.parameter
              )
            );
          }
        })
      );

      this.subscription.add(
        this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
          if (event) {
            switch (event.name) {
              case 'start-edit':
                this.onEditSetting();
                break;
              case 'exit-edit':
                this.onCancelSetting();
                break;
              case 'edit-add':
                this.onAddAttribute();
                break;
              case 'edit-save':
                this.onSaveSetting();
                break;
              case 'edit-setting':
                this.onSetting();
                break;
              case 'navigate-to-identity':
                if (this.tabView) {
                  if (this.tabView.isTabDirty()) {
                    const confirm = this.modal.show(
                      ModalType.confirm,
                      'key_confirmation',
                      'l10n_changesNotSaved'
                    );
                    confirm.afterClosed().subscribe((result) => {
                      if (result && result === 'yes') {
                        this.dbClick(event.parameter);
                      }
                    });
                  } else {
                    this.dbClick(event.parameter);
                  }
                }
                break;
              case 'add-identities':
                this.swap.objectViewEvent(
                  new ObjectViewEvent(
                    'addIdentities',
                    null,
                    null,
                    event.parameter
                  )
                );
                break;
              case 'remove-identities':
                this.swap.objectViewEvent(
                  new ObjectViewEvent(
                    'removeIdentities',
                    null,
                    null,
                    event.parameter
                  )
                );
                break;
              case 'remove-all-identities':
                this.swap.objectViewEvent(
                  new ObjectViewEvent(
                    'removeAllIdentities',
                    null,
                    null,
                    event.parameter
                  )
                );
                break;
              default:
                break;
            }
          }
        })
      );
    }

    if (this.route && this.route.parent) {
      this.subscription.add(
        this.route.parent.url.subscribe((segments: UrlSegment[]) => {
          if (this.router.url.indexOf('sidenav:') > 0) {
            this.brandWidth = 0;
            this.detailWidth = 100;
            this.showBrand = false;
          } else if (segments && segments.length === 1) {
            if (
              (this.viewSetting.brandView &&
                this.viewSetting.brandView.enabled) ||
              (this.viewSetting.structureView &&
                this.viewSetting.structureView.enabled)
            ) {
              this.brandWidth = 20;
              this.detailWidth = 80;
              this.showBrand = true;
            } else {
              this.brandWidth = 0;
              this.detailWidth = 100;
              this.showBrand = false;
            }
          } else {
            this.brandWidth = 0;
            this.detailWidth = 100;
            this.showBrand = false;
          }
        })
      );
    }

    return true;
  }

  constructor(injector: Injector) {
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.dialog = injector.get(MatDialog);
    this.modal = injector.get(ModalService);
    this.resource = injector.get(ResourceService);
    this.swap = injector.get(SwapService);
    this.utils = injector.get(UtilsService);
    this.window = injector.get(WindowService);
  }

  dbClick(param: any) {
    this.utils.NavigateToIdentity(param);
  }

  onEditSetting() {
    this.loadSettings();

    // editing not allowed if no view config
    if (this.viewSetting.sampleSetting) {
      this.configMode = false;
      this.swap.broadcast({ name: 'toggle-edit-mode', parameter: null });
      this.modal.show(
        ModalType.info,
        'key_info',
        'key_editModeNotAllowedForAutoConfig'
      );
      return;
    }
    // editing not allowed if in advanced view
    if (this.tabView && this.tabView.advancedView) {
      this.configMode = false;
      this.swap.broadcast({ name: 'toggle-edit-mode', parameter: null });
      this.modal.show(
        ModalType.info,
        'key_info',
        'key_editModeNotAllowedForAdvancedView'
      );
      return;
    }

    this.viewSettingCopy = this.utils.DeepCopy(this.viewSetting);

    if (this.tabView) {
      if (this.tabView.isTabDirty()) {
        const confirm = this.modal.show(
          ModalType.confirm,
          'key_confirmation',
          'l10n_changesNotSaved'
        );
        confirm.afterClosed().subscribe((result) => {
          if (result && result === 'yes') {
            this.configMode = true;
          } else {
            this.swap.broadcast({ name: 'toggle-edit-mode', parameter: null });
          }
        });
      } else {
        this.configMode = true;
      }
    } else {
      this.configMode = true;
    }
  }

  onAddAttribute(index?: number) {
    let sectionIndex: number;
    if (this.tabView) {
      sectionIndex = this.tabView.getCurrentTabIndex();
    } else if (index >= 0) {
      sectionIndex = index;
    } else {
      return;
    }

    const dialogRef = this.dialog.open(EditorCreatorComponent, {
      minWidth: '420px',
      data: {},
    });
    dialogRef.afterClosed().subscribe((result: EditorCreationConfig) => {
      if (result && String(result) !== 'cancel') {
        if (
          this.viewSetting.sections[sectionIndex].attributes.findIndex(
            (a: any) => {
              if (
                this.resource.authenticationMode &&
                this.resource.authenticationMode === 'azure'
              ) {
                return (
                  a.attributeName &&
                  result.attributeName &&
                  a.attributeName.toLowerCase() ===
                    result.attributeName.toLowerCase()
                );
              } else {
                return a.attributeName === result.attributeName;
              }
            }
          ) >= 0
        ) {
          this.modal.show(
            ModalType.error,
            'key_error',
            'l10n_attributeAlreadyExists'
          );
        } else {
          if (result.importSetting) {
            const setting: EditorConfig = JSON.parse(result.setting);
            if (setting) {
              if (setting.name === result.type) {
                const importedConfig = setting;
                importedConfig.attributeName = result.attributeName;
                importedConfig.units = result.layoutUnits;
                this.viewSetting.sections[sectionIndex].attributes.push({
                  attributeName: result.attributeName,
                  editorType: result.type,
                  editorConfig: importedConfig,
                });
              } else {
                this.modal.show(
                  ModalType.error,
                  'key_error',
                  'key_typeNotMatch'
                );
              }
            } else {
              this.modal.show(
                ModalType.error,
                'key_error',
                'key_invalidSetting'
              );
            }
          } else {
            this.viewSetting.sections[sectionIndex].attributes.push({
              attributeName: result.attributeName,
              editorType: result.type,
              editorConfig: {
                attributeName: result.attributeName,
                units: result.layoutUnits,
              },
            });
          }
        }
      }
    });
  }

  onSetting(creationMode?: boolean) {
    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    const windowRef = this.window.open({
      content: ViewConfiguratorComponent,
      width: 700,
    });
    const windowIns = windowRef.content.instance;
    windowIns.data = { viewSetting: this.viewSetting, creationMode };

    this.subscription.add(
      windowRef.result
        .pipe(
          tap((result: any) => {
            if (!(result instanceof WindowCloseResult)) {
              this.viewSetting.sections.forEach((s: any) => {
                if (!this.viewResults[s.name]) {
                  this.viewResults[s.name] = [];
                }
              });
            }
          }),
          finalize(() => {
            this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });
          })
        )
        .subscribe()
    );
  }

  onSaveSetting(remainConfigMode?: boolean) {
    const process = this.modal.show(
      ModalType.progress,
      'key_savingChanges',
      '',
      '300px'
    );

    this.viewSetting.sections.forEach((s: any) => {
      const result = this.viewResults[s.name];
      if (result && result.length > 0) {
        s.attributes.splice(0, s.attributes.length);
        result.forEach((r: any) => {
          s.attributes.push({
            attributeName: r.config.attributeName,
            editorType: r.type,
            editorConfig: r.config,
          });
        });
      }
    });

    if (!this.sectionName) {
      this.resource.primaryViewSetting[this.viewName] = this.viewSetting;
    } else {
      this.resource.primaryViewSetting[this.sectionName][this.viewName] =
        this.viewSetting;
    }

    this.subscription.add(
      this.resource
        .updateUISettings()
        .pipe(
          tap((updateResult: any) => {
            if (updateResult === 'expired') {
              this.modal.show(
                ModalType.error,
                'key_warning',
                'key_uiRefreshNeeded'
              );
              setTimeout(() => {
                this.onEditSetting();
              }, 500);
            } else {
              this.viewSettingCopy = undefined;
              if (!remainConfigMode) {
                this.configMode = false;
              } else {
                this.onEditSetting();
              }
            }
          }),
          catchError((err: HttpErrorResponse) => {
            this.onCancelSetting(remainConfigMode);
            this.modal.show(ModalType.error, 'key_error', err.error);
            return EMPTY;
          }),
          finalize(() => {
            process.close();
            if (this.viewSetting.type === 'tab') {
              this.menuActions = ['save', 'setting', 'add'];
            } else if (this.viewSetting.type === 'gridster') {
              this.menuActions = ['save', 'setting'];
            }
          })
        )
        .subscribe()
    );
  }

  onCancelSetting(remainConfigMode?: boolean) {
    if (this.viewSetting && this.viewSettingCopy) {
      this.viewSetting = this.viewSettingCopy;
      if (this.viewSetting.sections) {
        this.viewSetting.sections.forEach((s: any) => {
          this.viewResults[s.name] = [];
        });
      }

      if (!this.sectionName) {
        this.resource.primaryViewSetting[this.viewName] = this.viewSetting;
      } else {
        this.resource.primaryViewSetting[this.sectionName][this.viewName] =
          this.viewSetting;
      }

      if (!remainConfigMode) {
        this.configMode = false;
      } else {
        this.onEditSetting();
      }
    }
  }
}

@Directive()
export class PopupWindow {
  protected windowRef: WindowRef;

  @ViewChild('titleBar') titleBar: TemplateRef<any>;

  leftPadding = 0;

  protected initComponent(padding = 60) {
    if (this.windowRef) {
      this.windowRef.window.instance.stateChange.subscribe((state: any) => {
        if (state === 'maximized') {
          this.leftPadding = padding;
        }
        if (state === 'default') {
          this.leftPadding = 0;
        }
      });
      if (this.titleBar) {
        this.windowRef.window.instance.titleBarTemplate = this.titleBar;
      }
    }
  }

  constructor(injector: Injector) {
    this.windowRef = injector.get(WindowRef);
  }

  onCancel() {
    if (this.windowRef) {
      this.windowRef.close();
    }
  }

  onClose() {}
}

@Directive()
export class CustomComponent {
  protected subscription: Subscription = new Subscription();

  protected swap: SwapService;
  protected translate: TransService;

  private value: any;
  @Input()
  get componentValue() {
    return this.value;
  }
  set componentValue(value) {
    this.value = value;
    this.valueChange.emit(this.value);
  }
  @Output()
  valueChange = new EventEmitter();

  data: any;

  currentResource: Resource;

  constructor(injector: Injector) {
    this.swap = injector.get(SwapService);
    this.translate = injector.get(TransService);

    this.translate.use(this.swap.currentLanguage);
  }
}
