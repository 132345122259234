<div *ngIf="viewType === 'gridster'" class="gridster-header">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="none">
      {{ gridsterName | translate }}
    </div>
    <div fxFlex="auto"></div>
    <div
      *ngIf="!configMode"
      fxFlex="none"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <button
        mat-icon-button
        (click)="onSave()"
        [disabled]="!isDirty() || hasError()"
        [matTooltip]="'key_save' | translate"
        [ngClass]="{ 'disabled-reversed': !isDirty() || hasError() }"
      >
        <mat-icon style="font-size: 16px">save</mat-icon>
      </button>
    </div>
    <div fxFlex="none" fxLayout="row" fxLayoutAlign="start center">
      <button
        mat-icon-button
        (click)="refreshSelf()"
        [matTooltip]="'key_refresh' | translate"
      >
        <mat-icon style="font-size: 16px">refresh</mat-icon>
      </button>
    </div>
    <div
      *ngIf="configMode"
      fxFlex="none"
      fxLayout="row"
      fxLayoutAlign="start center"
      (click)="onAddAttribute()"
    >
      <button mat-icon-button [matTooltip]="'key_add' | translate">
        <mat-icon style="font-size: 16px">add_box</mat-icon>
      </button>
    </div>
    <div
      *ngIf="!configMode"
      fxFlex="none"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <button
        mat-icon-button
        (click)="onResize()"
        [matTooltip]="(isMinimized ? 'key_expand' : 'key_collapse') | translate"
      >
        <mat-icon style="font-size: 16px">{{
          isMinimized ? 'unfold_more' : 'unfold_less'
        }}</mat-icon>
      </button>
    </div>
  </div>
</div>
<div
  [style.margin.px]="viewType === 'gridster' ? 20 : 0"
  [style.overflowX]="'hidden'"
  [style.overflowY]="viewType === 'gridster' ? 'auto' : 'hidden'"
  [style.height]="viewType === 'gridster' ? 'calc(100% - 80px)' : 'auto'"
  [ngClass]="{ 'display-hidden': isMinimized }"
  style="padding-bottom: 20px; padding-top: 10px"
>
  <form
    [formGroup]="resourceForm"
    autocomplete="off"
    (keydown.enter)="onEnterKey($event)"
    (keydown.shift.enter)="onEnterKey($event)"
  >
    <div
      fxLayout="row wrap"
      fxLayoutGap="20px grid"
      fxLayoutAlign="start start"
      formArrayName="controls"
      dragula="ATTRIBUTECOLUMN"
      [(dragulaModel)]="attributeArray"
    >
      <ng-container
        *ngFor="let attribute of attributeArray; index as i"
        [ngSwitch]="attribute.type"
      >
        <div
          *ngIf="
            configMode || !isRemovedAttribute(attribute.attribute.systemName)
          "
          [ngClass]="{
            'display-hidden':
              !configMode && isHiddenAttribute(attribute.attribute.systemName)
          }"
          [style.font-size.px]="fontSize"
          [fxFlex]="getFlexUnits(attribute)"
          [fxFlex.lt-md]="100"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <app-editor-text
            #editor
            fxFlex="100%"
            *ngSwitchCase="'text'"
            [configMode]="configMode"
            [creationMode]="creationMode"
            [simpleMode]="simpleMode"
            [loadAsReadonly]="loadAsReadonly"
            [viewMode]="viewType"
            [currentID]="currentID"
            [currentType]="currentType"
            [currentResource]="currentResource"
            [parameters]="parameters"
            [formControlName]="i"
            [(config)]="attribute.config"
            [appUniquenessValidator]="{
              objectType: currentType,
              config: uniquenessCheckes,
              creationMode: this.creationMode,
              currentID: currentID
            }"
            appExpressionValidator
            (addValue)="onAddValue(attribute.attribute.systemName, $event)"
            (removeValue)="
              onRemoveValue(attribute.attribute.systemName, $event)
            "
          ></app-editor-text>
          <app-editor-boolean
            #editor
            fxFlex="auto"
            *ngSwitchCase="'boolean'"
            [configMode]="configMode"
            [creationMode]="creationMode"
            [simpleMode]="simpleMode"
            [loadAsReadonly]="loadAsReadonly"
            [viewMode]="viewType"
            [currentID]="currentID"
            [currentType]="currentType"
            [currentResource]="currentResource"
            [parameters]="parameters"
            [formControlName]="i"
            [(config)]="attribute.config"
          ></app-editor-boolean>
          <app-editor-select
            #editor
            fxFlex="auto"
            *ngSwitchCase="'select'"
            [configMode]="configMode"
            [creationMode]="creationMode"
            [simpleMode]="simpleMode"
            [viewMode]="viewType"
            [currentID]="currentID"
            [currentType]="currentType"
            [currentResource]="currentResource"
            [parameters]="parameters"
            [formControlName]="i"
            [(config)]="attribute.config"
          ></app-editor-select>
          <div
            *ngIf="
              simpleMode && loadAsReadonly && attribute.type === 'date';
              else fullDate
            "
            style="width: 100%"
          >
            <app-editor-text
              #editor
              fxFlex="auto"
              *ngSwitchCase="'date'"
              [configMode]="configMode"
              [creationMode]="creationMode"
              [simpleMode]="simpleMode"
              [loadAsReadonly]="loadAsReadonly"
              [viewMode]="viewType"
              [currentID]="currentID"
              [currentType]="currentType"
              [currentResource]="currentResource"
              [parameters]="parameters"
              [formControlName]="i"
              [(config)]="attribute.config"
              [appUniquenessValidator]="{
                objectType: currentType,
                config: uniquenessCheckes,
                creationMode: this.creationMode,
                currentID: currentID
              }"
              appExpressionValidator
              (addValue)="onAddValue(attribute.attribute.systemName, $event)"
              (removeValue)="
                onRemoveValue(attribute.attribute.systemName, $event)
              "
            ></app-editor-text>
          </div>
          <ng-template #fullDate>
            <app-editor-date
              #editor
              fxFlex="auto"
              *ngSwitchCase="'date'"
              [configMode]="configMode"
              [creationMode]="creationMode"
              [simpleMode]="simpleMode"
              [viewMode]="viewType"
              [currentID]="currentID"
              [currentType]="currentType"
              [currentResource]="currentResource"
              [parameters]="parameters"
              [formControlName]="i"
              [(config)]="attribute.config"
            ></app-editor-date>
          </ng-template>
          <app-editor-identity
            #editor
            fxFlex="auto"
            *ngSwitchCase="'identity'"
            [configMode]="configMode"
            [creationMode]="creationMode"
            [simpleMode]="simpleMode"
            [loadAsReadonly]="loadAsReadonly"
            [viewMode]="viewType"
            [currentID]="currentID"
            [currentType]="currentType"
            [currentResource]="currentResource"
            [parameters]="parameters"
            [formControlName]="i"
            [(config)]="attribute.config"
            (dbClick)="onIdentityDoubleClick($event)"
            (addValue)="onAddValue(attribute.attribute.systemName, $event)"
            (removeValue)="
              onRemoveValue(attribute.attribute.systemName, $event)
            "
          ></app-editor-identity>
          <app-editor-identities
            #editor
            fxFlex="auto"
            *ngSwitchCase="'identities'"
            class="editor-width"
            [configMode]="configMode"
            [creationMode]="creationMode"
            [simpleMode]="simpleMode"
            [loadAsReadonly]="loadAsReadonly"
            [viewMode]="viewType"
            [currentID]="currentID"
            [currentType]="currentType"
            [currentResource]="currentResource"
            [parameters]="parameters"
            [formControlName]="i"
            [(config)]="attribute.config"
            (dbClick)="onIdentitiesDoubleClick($event)"
            (addIdentities)="onAddIdentities($event)"
            (removeIdentities)="onRemoveIdentities($event)"
            (removeAllIdentities)="onRemoveAllIdentities($event)"
          ></app-editor-identities>
          <app-editor-separator
            #editor
            fxFlex="auto"
            *ngSwitchCase="'separator'"
            [configMode]="configMode"
            [creationMode]="creationMode"
            [simpleMode]="simpleMode"
            [viewMode]="viewType"
            [currentID]="currentID"
            [currentType]="currentType"
            [currentResource]="currentResource"
            [parameters]="parameters"
            [formControlName]="i"
            [(config)]="attribute.config"
            [ngClass]="
              configMode ? 'separator-edit-width' : 'separator-full-width'
            "
            style="width: 200px"
          ></app-editor-separator>
          <app-editor-button
            #editor
            fxFlex="auto"
            *ngSwitchCase="'button'"
            [configMode]="configMode"
            [creationMode]="creationMode"
            [simpleMode]="simpleMode"
            [viewMode]="viewType"
            [currentID]="currentID"
            [currentType]="currentType"
            [currentResource]="currentResource"
            [parameters]="parameters"
            [formControlName]="i"
            [(config)]="attribute.config"
          ></app-editor-button>
          <app-editor-xpath
            #editor
            fxFlex="auto"
            *ngSwitchCase="'xpath'"
            [configMode]="configMode"
            [creationMode]="creationMode"
            [simpleMode]="simpleMode"
            [viewMode]="viewType"
            [currentID]="currentID"
            [currentType]="currentType"
            [currentResource]="currentResource"
            [parameters]="parameters"
            [formControlName]="i"
            [(config)]="attribute.config"
            [appXpathValidator]="attribute.config"
          ></app-editor-xpath>
          <app-editor-frame
            #editor
            fxFlex="auto"
            *ngSwitchCase="'frame'"
            [configMode]="configMode"
            [creationMode]="creationMode"
            [simpleMode]="simpleMode"
            [viewMode]="viewType"
            [currentID]="currentID"
            [currentType]="currentType"
            [currentResource]="currentResource"
            [parameters]="parameters"
            [formControlName]="i"
            [(config)]="attribute.config"
          ></app-editor-frame>
          <app-editor-iterables
            #editor
            fxFlex="auto"
            *ngSwitchCase="'iterables'"
            [configMode]="configMode"
            [creationMode]="creationMode"
            [simpleMode]="simpleMode"
            [viewMode]="viewType"
            [currentID]="currentID"
            [currentType]="currentType"
            [currentResource]="currentResource"
            [parameters]="parameters"
            [formControlName]="i"
            [(config)]="attribute.config"
          ></app-editor-iterables>
          <app-editor-object
            #editor
            fxFlex="auto"
            *ngSwitchCase="'object'"
            [configMode]="configMode"
            [creationMode]="creationMode"
            [simpleMode]="simpleMode"
            [viewMode]="viewType"
            [currentID]="currentID"
            [currentType]="currentType"
            [currentResource]="currentResource"
            [parameters]="parameters"
            [formControlName]="i"
            [(config)]="attribute.config"
          ></app-editor-object>
          <app-editor-for-each
            #editor
            fxFlex="'auto"
            *ngSwitchCase="'foreach'"
            [configMode]="configMode"
            [creationMode]="creationMode"
            [simpleMode]="simpleMode"
            [viewMode]="viewType"
            [currentID]="currentID"
            [currentType]="currentType"
            [currentResource]="currentResource"
            [parameters]="parameters"
            [formControlName]="i"
            [(config)]="attribute.config"
          >
          </app-editor-for-each>
          <div
            *ngIf="configMode"
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            style="
              width: 36px;
              height: 36px;
              text-align: center;
              line-height: 56px;
              cursor: move;
              color: lightgray;
            "
          >
            <mat-icon
              [matTooltip]="'key_move' | translate"
              fxFlex="none"
              class="handle"
              >menu</mat-icon
            >
            <app-action-menu
              fxFlex="none"
              [menuIcon]="'more_vert'"
              [menuItems]="actionItems"
              [menuTooltip]="'key_settings' | translate"
              style="margin-top: -4px"
              (action)="onAction($event, attribute)"
            ></app-action-menu>
          </div>
          <!-- <button
            *ngIf="configMode"
            type="button"
            mat-icon-button
            color="primary"
            fxFlex="none"
            (click)="onConfig(attribute.attribute.systemName)"
          >
            <mat-icon>settings</mat-icon>
          </button>
          <button
            *ngIf="configMode"
            type="button"
            mat-icon-button
            color="warn"
            fxFlex="none"
            (click)="onDelete(attribute)"
          >
            <mat-icon>delete</mat-icon>
          </button> -->
          <div fxFlex="20px"></div>
        </div>
      </ng-container>
    </div>
  </form>
</div>
