import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkflowActivityView } from 'src/app/core/models/dynamicEditor.interface';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-activity-delete-resource',
  templateUrl: './activity-delete-resource.component.html',
  styleUrls: ['./activity-delete-resource.component.scss'],
})
export class ActivityDeleteResourceComponent
  extends WorkflowActivityView
  implements OnInit
{
  disablePostTrigger: boolean;
  disablePreTrigger: boolean;
  requestorAsActor: boolean;
  skipPermission: boolean;
  target: string;

  waitCompletion = false;
  maxWait = '';
  maxWaitSeconds = 0;
  waitMessage = '';

  @Input()
  get disablePreupdateTrigger() {
    return this.disablePreTrigger;
  }
  set disablePreupdateTrigger(value: boolean) {
    this.disablePreTrigger = value;
    this.disablePreupdateTriggerChange.emit(this.disablePreTrigger);
  }
  @Output()
  disablePreupdateTriggerChange = new EventEmitter();

  @Input()
  get disablePostupdateTrigger() {
    return this.disablePostTrigger;
  }
  set disablePostupdateTrigger(value: boolean) {
    this.disablePostTrigger = value;
    this.disablePostupdateTriggerChange.emit(this.disablePostTrigger);
  }
  @Output()
  disablePostupdateTriggerChange = new EventEmitter();

  @Input()
  get useRequestorAsActor() {
    return this.requestorAsActor;
  }
  set useRequestorAsActor(value: boolean) {
    this.requestorAsActor = value;
    this.useRequestorAsActorChange.emit(this.requestorAsActor);
  }
  @Output()
  useRequestorAsActorChange = new EventEmitter();

  @Input()
  get skipPermissionCheck() {
    return this.skipPermission;
  }
  set skipPermissionCheck(value: boolean) {
    this.skipPermission = value;
    this.skipPermissionCheckChange.emit(this.skipPermission);
  }
  @Output()
  skipPermissionCheckChange = new EventEmitter();

  @Input()
  get deletionTarget() {
    return this.target;
  }
  set deletionTarget(value: string) {
    this.target = value;
    this.deletionTargetChange.emit(this.target);
  }
  @Output()
  deletionTargetChange = new EventEmitter();

  @Input()
  get waitForCompletion() {
    return this.waitCompletion;
  }
  set waitForCompletion(value: boolean) {
    this.waitCompletion = value;
    this.waitForCompletionChange.emit(this.waitCompletion);
  }
  @Output()
  waitForCompletionChange = new EventEmitter();

  @Input()
  get maxWaitTime() {
    return this.maxWait;
  }
  set maxWaitTime(value: string) {
    this.maxWait = value;
    this.maxWaitTimeChange.emit(this.maxWait);
  }
  @Output()
  maxWaitTimeChange = new EventEmitter();

  @Input()
  get waitForCompletionMessage() {
    return this.waitMessage;
  }
  set waitForCompletionMessage(value: string) {
    this.waitMessage = value;
    this.waitForCompletionMessageChange.emit(this.waitMessage);
  }
  @Output()
  waitForCompletionMessageChange = new EventEmitter();

  constructor(private utils: UtilsService) {
    super();
  }

  ngOnInit(): void {
    this.maxWaitSeconds = this.utils.durationToSeconds(this.maxWaitTime);
  }

  onImpersonationChange() {
    if (!this.useRequestorAsActor) {
      this.skipPermissionCheck = false;
    }
  }

  onMaxTimeChange() {
    this.maxWaitTime = this.utils.secondsToDuration(this.maxWaitSeconds);
  }
}
