import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AttestationCheckElement,
  AttestationInfo,
  AttestationItem,
  Resource,
  ResourceSet,
  SodConflict,
} from '../../models/dataContract.model';
import { CustomComponent } from '../../models/dynamicEditor.interface';
import { UtilsService } from '../../services/utils.service';
import { ResourceService } from '../../services/resource.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-conflicts-resolver',
  templateUrl: './conflicts-resolver.component.html',
  styleUrls: ['./conflicts-resolver.component.scss'],
})
export class ConflictsResolverComponent
  extends CustomComponent
  implements OnInit
{
  private arrResolves: Array<any>;

  private colorApprove = 'green';
  private colorEscalate = 'darkkhaki';
  private colorReject = 'coral';
  private colorNormal = 'gray';

  private iconApprove = 'done';
  private iconEscalate = 'support';
  private iconReject = 'bolt';

  private buildDecision() {
    this.arrResolves = this.conflicts.map((c: AttestationItem) => {
      return {
        target: c.target,
        sods: c.sods
          ? c.sods.map((s: SodConflict) => {
              return {
                id: s.conflictid,
                type: 'sod',
                decision: s.decision,
                comment: s.comment,
                ishidden: s.ishidden,
              };
            })
          : [],
        roles: c.roles
          ? c.roles.map((r: AttestationCheckElement) => {
              return {
                id: r.objectid,
                type: 'role',
                decision: r.decision,
                Comment: r.comment,
              };
            })
          : [],
      };
    });
    this.attestationInfo.decisions = this.arrResolves;
    this.componentValue = this.attestationInfo;

    if (this.canSubmit()) {
      this.swap.broadcast({ name: 'enable-popup-submit' });
    } else {
      this.swap.broadcast({ name: 'disable-popup-submit' });
    }
  }

  private canSubmit() {
    switch (this.mode) {
      case 'resolver':
        if (!this.attestationInfo) {
          return false;
        }
        if (
          this.attestationInfo.decision !== null &&
          this.attestationInfo.decision !== undefined
        ) {
          return true;
        } else {
          return false;
        }
      case 'attestation':
        if (!this.arrResolves) {
          return false;
        }
        for (const resolve of this.arrResolves) {
          if (resolve.roles) {
            const pos = resolve.roles.findIndex((r: any) => !r.decision);
            if (pos >= 0) {
              return false;
            }
          }
          if (resolve.sods) {
            const pos = resolve.sods.findIndex(
              (s: any) => !s.decision && !s.ishidden
            );
            if (pos >= 0) {
              return false;
            }
          }
        }
        break;
      case 'info':
        return this.infoConfirmed;
      default:
        return false;
    }

    return true;
  }

  private prepareAttastationData(value: Array<any>) {
    const parsedValue = value.reduce((rv, x) => {
      if (Array.isArray(rv)) {
        const y = { ...x };
        delete y.target;
        const pos = rv.findIndex(
          (r) => x.target && r.target && x.target.objectid === r.target.objectid
        );
        if (pos >= 0) {
          if (y.role) {
            rv[pos].roles.push({ ...y.role, isdirect: y.isdirect });
          } else {
            rv[pos].sods.push(y);
          }
        } else {
          const result = {
            target: x.target,
            roles: [],
            sods: [],
          };
          if (y.role) {
            result.roles.push({ ...y.role, isdirect: y.isdirect });
          } else {
            result.sods.push(y);
          }
          rv.push(result);
        }
        return rv;
      } else {
        const y = { ...rv };
        const z = { ...x };
        delete y.target;
        delete z.target;
        if (x.target && rv.target && x.target.objectid === rv.target.objectid) {
          const result: any = {
            target: rv.target,
            roles: [],
            sods: [],
          };
          if (y.role) {
            result.roles.push({ ...y.role, isdirect: y.isdirect });
          } else {
            result.sods.push(y);
          }
          if (z.role) {
            result.roles.push({ ...z.role, isdirect: z.isdirect });
          } else {
            result.sods.push(z);
          }
          return [result];
        } else {
          const result = [
            {
              target: rv.target,
              sods: [],
              roles: [],
            },
            {
              target: x.target,
              sods: [],
              roles: [],
            },
          ];
          if (y.role) {
            result[0].roles.push({ ...y.role, isdirect: y.isdirect });
          } else {
            result[0].sods.push(y);
          }
          if (z.role) {
            result[1].roles.push({ ...z.role, isdirect: z.isdirect });
          } else {
            result[1].sods.push(z);
          }
          return result;
        }
      }
    });
    if (!Array.isArray(parsedValue)) {
      const y = { ...parsedValue };
      delete y.target;
      const item = {
        target: parsedValue.target,
        roles: [],
        sods: [],
      };
      if (y.role) {
        item.roles.push({ ...y.role, isdirect: y.isdirect });
      } else {
        item.sods.push(y);
      }
      this.attestationInfo = {
        items: [item],
      };
    } else {
      parsedValue.sort((a, b) => {
        const aName = this.utils.ExtraValue(a.target, 'displayName');
        const bName = this.utils.ExtraValue(b.target, 'displayName');
        if (aName && bName && aName.toLowerCase() > bName.toLowerCase()) {
          return 1;
        } else {
          return -1;
        }
      });
      parsedValue.forEach((p: AttestationItem) => {
        p.roles.sort((a, b) => {
          if (
            a.displayname &&
            b.displayname &&
            a.displayname.toLowerCase() > b.displayname.toLowerCase()
          ) {
            return 1;
          } else {
            return -1;
          }
        });
        // p.sods.sort((a, b) => {
        //   if (
        //     a.conflictname &&
        //     b.conflictname &&
        //     a.conflictname.toLowerCase() > b.conflictname.toLowerCase()
        //   ) {
        //     return 1;
        //   } else {
        //     return -1;
        //   }
        // });
      });
      this.attestationInfo = {
        items: parsedValue,
      };
    }
  }

  @Input()
  attestationInfo: AttestationInfo;

  @Input()
  mode: 'resolver' | 'attestation' | 'info' = 'attestation';

  @Input()
  contentHeight = 320;

  @Input()
  showDetail = true;

  @Input()
  infoText = '';

  @Input()
  get resolves() {
    return this.arrResolves;
  }
  set resolves(value: Array<any>) {
    this.arrResolves = value;
    this.resolvesChange.emit(this.arrResolves);
  }
  @Output()
  resolvesChange = new EventEmitter();

  conflicts: Array<AttestationItem>;

  summaryDecision: 'approve' | 'escalate' | 'reject';
  summaryComment: string;

  infoConfirmed = false;

  getConflictColor(c: any, d?: string) {
    if (c.existNoMore) {
      return this.colorApprove;
    }
    const v = c.decision ?? d;
    switch (v) {
      case 'approve':
        return this.colorApprove;
      case 'escalate':
        return this.colorEscalate;
      case 'normal':
        return this.colorNormal;
      default:
        return this.colorReject;
    }
  }

  getConflictIcon(c: any, d?: string) {
    if (c.existNoMore) {
      return this.iconApprove;
    }
    const v = c.decision ?? d;
    switch (v) {
      case 'approve':
        return this.iconApprove;
      case 'escalate':
        return this.iconEscalate;
      default:
        return this.iconReject;
    }
  }

  conflictColor = 'coral';
  conflictIcon = 'bolt';

  oeQuery: string;
  oeTravelQuery = `/*[ocgParentRef='%id%']`;
  oeLinkedAttributes = [
    'DisplayName',
    'ocgdirectrolerefs',
    'ocgParentRef',
    'ocgResultantObjectRefs',
  ];
  oeHeight = 300;

  constructor(
    injector: Injector,
    private utils: UtilsService,
    private resource: ResourceService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.swap.broadcast({ name: 'disable-popup-submit' });

    if (this.data) {
      const data = JSON.parse(this.data);
      if (data) {
        if (data.mode) {
          this.mode = data.mode;
        }
        if (data.contentHeight) {
          this.contentHeight = data.contentHeight;
        }
        if (data.infoText) {
          this.infoText = data.infoText;
        }
      }
    }
    if (this.componentValue) {
      const componentValue: Array<any> = JSON.parse(this.componentValue);
      if (componentValue) {
        this.prepareAttastationData(componentValue);
      }
    }
    if (this.attestationInfo) {
      this.conflicts = this.attestationInfo.items;

      this.conflicts.forEach((c: AttestationItem) => {
        c.showdetail = this.showDetail;
        if (c.sods) {
          c.sods.forEach((s: SodConflict) => {
            if (this.mode === 'info') {
              const tid = this.utils.ExtraValue(c.target, 'ObjectID');
              const cid1 = this.utils.ExtraValue(
                s.conflictingobject1,
                'ObjectID'
              );
              const cid2 = this.utils.ExtraValue(
                s.conflictingobject2,
                'ObjectID'
              );
              this.subscription.add(
                this.resource
                  .getResourceByQuery(
                    `/*[ObjectID='${tid}' and ocgResultantRoleRefs='${cid1}' and ocgResultantRoleRefs='${cid2}']`,
                    ['ObjectID']
                  )
                  .pipe(
                    tap((result: ResourceSet) => {
                      if (result && result.results.length === 0) {
                        s.existNoMore = true;
                      } else {
                        s.existNoMore = false;
                      }
                    })
                  )
                  .subscribe()
              );
            }
            if (s.exception) {
              s.decision = 'approve';
              s.decisioncreator = s.exception.creator;
              s.decisiondate = s.exception.createdtime;
              s.comment = s.exception.comment;
            }
          });
        }
      });
    }
  }

  onShowRoleExplorer(r: AttestationCheckElement, t: Resource) {
    r.showexplorer = !r.showexplorer;

    if (r.showexplorer) {
      const tid = this.utils.ExtraValue(t, 'objectID');
      const rid = this.utils.ExtraValue(r, 'objectID');
      this.oeQuery = `/*[ObjectID='${rid}' or ObjectID='${tid}' or (objectType='ocgOrgUnit' and ocgDirectRoleRefs='${rid}')]`;
    }
  }

  onShowConflictExplorer(c: SodConflict, t: Resource, mode: 'left' | 'right') {
    c.showresolver = false;

    if (c.showexplorer) {
      if (mode === c.explorerContent) {
        c.showexplorer = false;
        return;
      }
    }

    const tid = this.utils.ExtraValue(t, 'objectID');
    const cid =
      mode === 'left'
        ? this.utils.ExtraValue(c.conflictingobject1, 'objectID')
        : this.utils.ExtraValue(c.conflictingobject2, 'objectID');
    this.oeQuery = `/*[ObjectID='${cid}' or ObjectID='${tid}' or (objectType='ocgOrgUnit' and ocgDirectRoleRefs='${cid}')]`;

    if (c.showexplorer) {
      c.showexplorer = false;
      setTimeout(() => {
        c.showexplorer = true;
      });
    } else {
      c.showexplorer = true;
    }
    c.explorerContent = mode;
  }

  onShowResolver(c: SodConflict) {
    c.showexplorer = false;
    c.showresolver = !c.showresolver;
  }

  onResolve() {
    this.buildDecision();
  }

  onAttest(elem: AttestationCheckElement, item: AttestationItem) {
    this.buildDecision();

    if (item.sods) {
      item.sods.forEach((s: SodConflict) => {
        let otherId = '';
        if (
          this.utils.ExtraValue(s.conflictingobject1, 'objectId') ===
          elem.objectid
        ) {
          otherId = this.utils.ExtraValue(s.conflictingobject2, 'objectId');
        }
        if (
          this.utils.ExtraValue(s.conflictingobject2, 'objectId') ===
          elem.objectid
        ) {
          otherId = this.utils.ExtraValue(s.conflictingobject1, 'objectId');
        }
        if (otherId) {
          if (elem.decision === 'reject') {
            s.ishidden = true;
          } else {
            const pos = item.roles.findIndex(
              (r: AttestationCheckElement) =>
                r.objectid === otherId && r.decision === 'reject'
            );
            s.ishidden = pos >= 0;
          }
        }
      });
    }
  }

  onSummaryResolve() {
    if (this.summaryDecision) {
      this.attestationInfo.decision = this.summaryDecision === 'approve';
    }
    this.attestationInfo.comment = this.summaryComment;

    this.componentValue = this.attestationInfo;

    if (this.conflicts) {
      this.conflicts.forEach((item: AttestationItem) => {
        if (item.sods) {
          item.sods.forEach((c: SodConflict) => {
            c.decision = this.summaryDecision;
            c.comment = this.summaryComment;
          });
        }
      });
    }

    if (this.canSubmit()) {
      this.swap.broadcast({ name: 'enable-popup-submit' });
    } else {
      this.swap.broadcast({ name: 'disable-popup-submit' });
    }
  }

  onInfoConfirmation() {
    if (this.canSubmit()) {
      this.swap.broadcast({ name: 'enable-popup-submit' });
    } else {
      this.swap.broadcast({ name: 'disable-popup-submit' });
    }
  }

  onExpandAll() {
    this.conflicts.forEach((c: AttestationItem) => {
      c.showdetail = true;
    });
  }

  onCollapseAll() {
    this.conflicts.forEach((c: AttestationItem) => {
      c.showdetail = false;
    });
  }
}
