import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { WorkflowActivityView } from 'src/app/core/models/dynamicEditor.interface';
import { UtilsService } from 'src/app/core/services/utils.service';

class UpdateEntry {
  allownull?: boolean;
  target?: string;
  type?: string;
  valueexpression?: string;
}

@Component({
  selector: 'app-activity-update-resource',
  templateUrl: './activity-update-resource.component.html',
  styleUrls: ['./activity-update-resource.component.scss'],
})
export class ActivityUpdateResourceComponent
  extends WorkflowActivityView
  implements OnInit
{
  disablePostTrigger: boolean;
  disablePreTrigger: boolean;
  requestorAsActor: boolean;
  skipPermission: boolean;

  entries: Array<UpdateEntry>;

  waitCompletion = false;
  maxWait = '';
  maxWaitSeconds = 0;
  waitMessage = '';

  @Input()
  get disablePreupdateTrigger() {
    return this.disablePreTrigger;
  }
  set disablePreupdateTrigger(value: boolean) {
    this.disablePreTrigger = value;
    this.disablePreupdateTriggerChange.emit(this.disablePreTrigger);
  }
  @Output()
  disablePreupdateTriggerChange = new EventEmitter();

  @Input()
  get disablePostupdateTrigger() {
    return this.disablePostTrigger;
  }
  set disablePostupdateTrigger(value: boolean) {
    this.disablePostTrigger = value;
    this.disablePostupdateTriggerChange.emit(this.disablePostTrigger);
  }
  @Output()
  disablePostupdateTriggerChange = new EventEmitter();

  @Input()
  get useRequestorAsActor() {
    return this.requestorAsActor;
  }
  set useRequestorAsActor(value: boolean) {
    this.requestorAsActor = value;
    this.useRequestorAsActorChange.emit(this.requestorAsActor);
  }
  @Output()
  useRequestorAsActorChange = new EventEmitter();

  @Input()
  get skipPermissionCheck() {
    return this.skipPermission;
  }
  set skipPermissionCheck(value: boolean) {
    this.skipPermission = value;
    this.skipPermissionCheckChange.emit(this.skipPermission);
  }
  @Output()
  skipPermissionCheckChange = new EventEmitter();

  @Input()
  get updateResourcesEntries() {
    return this.entries;
  }
  set updateResourcesEntries(value: Array<UpdateEntry>) {
    this.entries = value;
    this.updateResourcesEntriesChange.emit(this.entries);
  }
  @Output()
  updateResourcesEntriesChange = new EventEmitter();

  @Input()
  get waitForCompletion() {
    return this.waitCompletion;
  }
  set waitForCompletion(value: boolean) {
    this.waitCompletion = value;
    this.waitForCompletionChange.emit(this.waitCompletion);
  }
  @Output()
  waitForCompletionChange = new EventEmitter();

  @Input()
  get maxWaitTime() {
    return this.maxWait;
  }
  set maxWaitTime(value: string) {
    this.maxWait = value;
    this.maxWaitTimeChange.emit(this.maxWait);
  }
  @Output()
  maxWaitTimeChange = new EventEmitter();

  @Input()
  get waitForCompletionMessage() {
    return this.waitMessage;
  }
  set waitForCompletionMessage(value: string) {
    this.waitMessage = value;
    this.waitForCompletionMessageChange.emit(this.waitMessage);
  }
  @Output()
  waitForCompletionMessageChange = new EventEmitter();

  constructor(private dragula: DragulaService, private utils: UtilsService) {
    super();

    try {
      this.dragula.createGroup('UPDATERESOURCESENTRIES', {
        moves: (el, container, handle) => {
          return (
            handle.classList.contains('handle') ||
            (handle.parentNode as Element).classList.contains('handle')
          );
        },
      });
    } catch {}
  }

  ngOnInit(): void {
    this.maxWaitSeconds = this.utils.durationToSeconds(this.maxWaitTime);
  }

  onAddEntry() {
    if (this.entries) {
      this.entries.push({
        allownull: false,
        type: 'UpdateResourcesEntry',
        target: '',
        valueexpression: '',
      });
    } else {
      this.entries = [
        {
          allownull: false,
          type: 'UpdateResourcesEntry',
          target: '',
          valueexpression: '',
        },
      ];
    }
  }

  onRemoveEntry(index: number) {
    this.entries.splice(index, 1);
  }

  onImpersonationChange() {
    if (!this.useRequestorAsActor) {
      this.skipPermissionCheck = false;
    }
  }

  onMaxTimeChange() {
    this.maxWaitTime = this.utils.secondsToDuration(this.maxWaitSeconds);
  }
}
