<div
  class="full-size-relative"
  ngxUiLoaderBlurred
  [blur]="blurLevel"
  [loaderId]="localConfig.name"
>
  <div fxLayout="row">
    <div
      fxFlex="auto"
      style="
        font-size: 20px;
        font-weight: 500;
        margin: 10px 0 0 10px;
        color: rgba(0, 0, 0, 0.54);
      "
    >
      {{ localConfig.title | translate }}
    </div>
    <mat-button-toggle-group
      [(ngModel)]="displayMode"
      (change)="onDisplayModeChange()"
      style="
        height: 26px;
        margin-top: 10px;
        margin-right: 15px;
        font-size: 15px;
      "
    >
      <mat-button-toggle value="all" style="margin-top: 0">{{
        'key_all2' | translate
      }}</mat-button-toggle>
      <mat-button-toggle value="pending" style="margin-top: 0">{{
        'key_pending2' | translate
      }}</mat-button-toggle>
    </mat-button-toggle-group>
    <div
      fxFlex="none"
      style="
        width: 36px;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.54);
        padding-top: 10px;
        margin-right: 10px;
      "
      [matTooltip]="'key_refresh' | translate"
      (click)="refresh()"
    >
      <mat-icon>refresh</mat-icon>
    </div>
  </div>
  <div
    *ngIf="approvalRequests.length === 0"
    fxLayout="column"
    fxLayoutAlign="center center"
    style="height: 90%; margin-top: 40px"
  >
    <mat-icon
      fxFlex="none"
      style="font-size: 100px; width: 100px; color: #cccccc"
      >flaky</mat-icon
    >
    <div
      fxFlex="none"
      style="
        margin-top: 50px;
        padding-top: 50px;
        font-size: 18px;
        color: #b0b0b0;
      "
    >
      {{ 'key_noPendingApprovals' | translate }}
    </div>
  </div>
  <div
    *ngIf="approvalRequests.length > 0"
    style="
      margin: 20px 10px 10px 10px;
      overflow-y: auto;
      height: calc(100% - 80px);
      padding-bottom: 10px;
    "
  >
    <mat-card
      *ngFor="let request of approvalRequests"
      style="
        border-left-style: solid;
        border-left-width: 5px;
        margin-top: 10px;
        margin-right: 5px;
        padding: 12px;
      "
      [ngStyle]="{
        'border-left-color':
          request.approvalProcessDecision === null
            ? 'goldenrod'
            : request.approvalProcessDecision === 'Approved'
            ? 'green'
            : 'red'
      }"
    >
      <mat-card-header fxLayout="row" fxLayoutAlign="start center">
        <div
          fxFlex="none"
          style="
            width: 36px;
            margin-left: -30px;
            cursor: pointer;
            color: lightgray;
          "
          (click)="onExpandRequest(request)"
        >
          <mat-icon>{{
            request.showContent ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
          }}</mat-icon>
        </div>
        <div
          fxFlex="auto"
          fxLayout="column"
          style="margin-top: -8px; font-size: 14px"
        >
          <div
            fxLayout="row"
            style="font-size: 12px; color: darkgray; margin-bottom: -6px"
          >
            <div fxFlex="none">
              {{ request | extraValue: 'requestDateTime' }}
            </div>
            <div fxFlex="none" style="margin-left: 5px">
              {{ 'key_requestedBy' | translate }}
            </div>
            <div fxFlex="none" style="margin-left: 5px">
              <app-identity-link
                [identity]="{
                  DisplayName: request.requestorDisplayName,
                  ObjectID: request.requestorId
                }"
                [linkActions]="['sideView', 'navigate']"
              ></app-identity-link>
            </div>
          </div>
          <div *ngIf="request.title; else noTitle">
            {{ request.title }}
          </div>
          <ng-template #noTitle>{{
            request.approvalProcessDisplayName
          }}</ng-template>
        </div>
        <div
          *ngIf="
            request.approvalProcessDecision === null &&
            localConfig.canApprove &&
            canApprove(request)
          "
          fxFlex="none"
          style="width: 36px"
        >
          <button
            mat-icon-button
            color="primary"
            [matTooltip]="'key_approveRequest' | translate"
            (click)="onApprove(request, true)"
          >
            <mat-icon>check_circle</mat-icon>
          </button>
        </div>
        <div
          *ngIf="
            request.approvalProcessDecision === null &&
            localConfig.canApprove &&
            canApprove(request)
          "
          fxFlex="none"
          style="width: 36px"
        >
          <button
            mat-icon-button
            color="warn"
            [matTooltip]="'key_rejectRequest' | translate"
            (click)="onApprove(request, false)"
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </mat-card-header>
      <mat-card-content
        *ngIf="request.showContent"
        fxLayout="column"
        style="margin-top: 5px; margin-bottom: 10px; font-size: 12px"
      >
        <div fxFlex="auto" fxLayout="column" fxLayoutAlign="start center">
          <div fxLayout="row" style="width: 90%; margin-bottom: 0">
            <div fxFlex="33" style="font-weight: 400">
              {{ 'key_target' | translate }}:
            </div>
            <div fxFlex="auto">
              <app-identity-link
                [identity]="{ ObjectID: request.targetId }"
                [linkActions]="['sideView', 'navigate']"
              ></app-identity-link>
            </div>
          </div>
          <div fxLayout="row" style="width: 90%; margin-bottom: 5px">
            <div fxFlex="33" style="font-weight: 400">
              {{ 'key_requestor' | translate }}:
            </div>
            <div fxFlex="auto">
              <app-identity-link
                [identity]="{ ObjectID: request.requestorId }"
                [linkActions]="['sideView', 'navigate']"
              ></app-identity-link>
            </div>
          </div>
          <app-approval-info
            [request]="request"
            style="width: 100%"
          ></app-approval-info>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<ngx-ui-loader
  [loaderId]="localConfig.name"
  [fgsType]="spinnerType.rectangleBounce"
  [fgsSize]="uiLoader.spinnerSize"
  [fgsColor]="uiLoader.spinnerColor"
  [overlayColor]="uiLoader.overlayColor"
  [pbColor]="uiLoader.progressBarColor"
></ngx-ui-loader>
