import {
  ResourceColumnConfig,
  ResourceTableConfig,
} from './componentContract.model';
import { ModelUpdateMode, ValidationSetting } from './dataContract.model';

export class EditorConfig {
  name?: string;
  attributeName?: string;
  units?: number;
  gridsterUnits?: number;
  editMode?: boolean;
  isHidden?: boolean;
  hideFromDOM?: boolean;
  readOnly?: boolean;
  required?: boolean;
  requiredFromSchema?: boolean;
  showTooltip?: boolean;
  showDisplayName?: boolean;
  customDisplayName?: string;
  showDescription?: boolean;
  customDescription?: string;
  hintLimit?: number;
  tooltipContent?: string;
  hideIfNoAccess?: boolean;
  initExpression?: string;
  expression?: string;
  validation?: string;
  accessAllowed?: Array<string>;
  accessDenied?: Array<string>;
  accessExpression?: string;
  accessUsedFor?: string;
  calculatedDisplayable?: boolean;
  calculatedEditable?: boolean;
  hasError?: boolean;
  errorMsg?: string;
  customErrorMsg?: string;

  constructor() {
    this.name = undefined;
    this.attributeName = undefined;
    this.units = 6;
    this.gridsterUnits = 12;
    this.editMode = true;
    this.isHidden = false;
    this.hideFromDOM = false;
    this.showTooltip = true;
    this.showDisplayName = true;
    this.customDisplayName = undefined;
    this.showDescription = true;
    this.customDescription = undefined;
    this.hintLimit = 80;
    this.tooltipContent = 'systemname';
    this.readOnly = false;
    this.required = false;
    this.requiredFromSchema = false;
    this.hideIfNoAccess = true;
    this.initExpression = undefined;
    this.expression = undefined;
    this.validation = undefined;
    this.accessAllowed = [];
    this.accessDenied = [];
    this.accessExpression = undefined;
    this.accessUsedFor = 'visibility';
    this.calculatedDisplayable = true;
    this.calculatedEditable = true;
    this.hasError = false;
    this.errorMsg = undefined;
    this.customErrorMsg = undefined;
  }
}

export class EditorCreationConfig {
  name?: string;
  attributeName?: string;
  type?: string;
  layoutUnits?: number;
  importSetting?: boolean;
  setting?: string;

  constructor() {
    this.name = '';
    this.attributeName = '';
    this.type = 'text';
    this.layoutUnits = 12;
    this.importSetting = false;
    this.setting = '';
  }
}

export class TextEditorConfig extends EditorConfig {
  maxLength?: number;
  isMultivalue?: boolean;
  caseSensitive?: boolean;
  isPassword?: boolean;
  isNumber?: boolean;
  isSimpleValue?: boolean;
  isHtml?: boolean;
  isCode?: boolean;
  placeholder?: string;
  prefix?: string;
  savePrefix?: boolean;
  noPrefixIfEmpty?: boolean;
  rows?: number;
  validationSetting?: ValidationSetting;
  validationKey?: string;
  updateOn?: ModelUpdateMode;
  autoComplete?: boolean;
  autoCompleteOptions?: Array<{ text: string; value: string }>;

  constructor() {
    super();

    this.name = 'text';
    this.maxLength = undefined;
    this.isMultivalue = false;
    this.caseSensitive = false;
    this.isPassword = false;
    this.isNumber = false;
    this.isSimpleValue = false;
    this.isHtml = false;
    this.isCode = false;
    this.placeholder = '';
    this.prefix = '';
    this.savePrefix = false;
    this.noPrefixIfEmpty = true;
    this.rows = 1;
    this.validationSetting = null;
    this.validationKey = null;
    this.updateOn = undefined;
    this.autoComplete = false;
    this.autoCompleteOptions = [];
  }
}

export class BooleanEditorConfig extends EditorConfig {
  textAlign?: 'before' | 'after';
  controlType?: string;
  customValue?: boolean;
  customValueIngoreCase?: boolean;
  trueValue?: string;
  falseValue?: string;
  enableIndeterminated?: boolean;
  radioButtonLayout?: string;
  radioButtonSpace?: number;
  trueText?: string;
  falseText?: string;

  constructor() {
    super();

    this.name = 'boolean';
    this.textAlign = 'after';
    this.controlType = 'checkbox';
    this.customValue = false;
    this.customValueIngoreCase = false;
    this.trueValue = undefined;
    this.falseValue = undefined;
    this.enableIndeterminated = false;
    this.radioButtonLayout = 'row';
    this.radioButtonSpace = 20;
    this.trueText = 'true';
    this.falseText = 'false';
  }
}

export class SelectEditorConfig extends EditorConfig {
  controlType?: string;
  radioButtonLayout?: string;
  radioButtonSpace?: number;
  dataMode?: string;
  options?: Array<{ value: string; text: string }>;
  allowEmpty?: boolean;
  emptyText?: string;
  configKey?: string;
  query?: string;
  valueAttribute?: string;
  textAttribute?: string;
  separator?: string;

  constructor() {
    super();

    this.name = 'select';
    this.controlType = 'combo';
    this.radioButtonLayout = 'row';
    this.radioButtonSpace = 20;
    this.dataMode = 'static';
    this.options = [];
    this.allowEmpty = true;
    this.emptyText = '--';
    this.configKey = undefined;
    this.query = undefined;
    this.valueAttribute = undefined;
    this.textAttribute = undefined;
    this.separator = undefined;
  }
}

export class DateEditorConfig extends EditorConfig {
  dateFormat?: string;
  timeFormat?: string;
  showNavigation?: boolean;
  showWeekNumber?: boolean;
  showTime?: boolean;
  useMinDate?: boolean;
  useMaxDate?: boolean;
  minDate?: Date;
  maxDate?: Date;
  configMinDate?: Date;
  configMaxDate?: Date;
  queryMinDate?: string;
  queryMaxDate?: string;

  constructor() {
    super();

    this.name = 'date';
    this.dateFormat = undefined;
    this.timeFormat = undefined;
    this.showNavigation = true;
    this.showWeekNumber = false;
    this.showTime = false;
    this.useMinDate = false;
    this.useMaxDate = false;
    this.minDate = null;
    this.maxDate = null;
    this.configMinDate = null;
    this.configMaxDate = null;
    this.queryMinDate = undefined;
    this.queryMaxDate = undefined;
  }
}

export class IdentityEditorConfig extends EditorConfig {
  objectType?: string;
  isMultivalue?: boolean;
  showBulkImport?: boolean;
  lettersToTrigger?: number;
  suggestionNumber?: number;
  queryNormalSearch?: string;
  queryExactSearch?: string;
  queryEmptySearch?: string;
  allowEmptySearch?: boolean;
  attributesToShow?: ResourceColumnConfig[];
  showPhoto?: boolean;
  photoAttribute?: string;
  photoPlaceHolder?: string;
  popupWidth?: number;
  popupHeight?: number;
  emptySearchMaxCount?: number;
  browserShowTypePicker?: boolean;
  browserDefaultType?: string;
  browserScrollMode?: string;
  browserScrollHeight?: number;
  browserReadOnly?: boolean;

  constructor() {
    super();

    this.name = 'identity';
    this.objectType = 'Person';
    this.isMultivalue = false;
    this.showBulkImport = false;
    this.lettersToTrigger = 3;
    this.suggestionNumber = 6;
    this.queryNormalSearch = undefined;
    this.queryExactSearch = undefined;
    this.queryEmptySearch = undefined;
    this.allowEmptySearch = false;
    this.attributesToShow = [
      {
        field: 'DisplayName',
        width: null,
        filterable: false,
        filter: 'text',
        sortable: true,
        locked: false,
      },
    ];
    this.showPhoto = false;
    this.photoAttribute = 'Photo';
    this.photoPlaceHolder = 'photo';
    this.popupWidth = 0;
    this.popupHeight = 0;
    this.emptySearchMaxCount = 50;
    this.browserShowTypePicker = false;
    this.browserDefaultType = 'Person';
    this.browserScrollMode = 'basic';
    this.browserScrollHeight = 36;
    this.browserReadOnly = false;
  }
}

export class IdsEditorConfig extends EditorConfig {
  resourceType?: string;
  idpConfig?: IdentityEditorConfig;
  tableConfig?: ResourceTableConfig;
  addMemberConfirmation?: boolean;
  removeMemberConfirmation?: boolean;
  enableRemoveAll?: boolean;
  enableDataExchange?: boolean;
  dataExchangeAttribute?: string;
  dataExchangeCheckType?: boolean;
  dataExchangeLimit?: number;
  linkType?: null | 'directlink' | 'backlink' | 'assignment' | 'intermediat';
  linkedAttribute?: string;
  intermediateObjectType?: string;
  intermediateObjectName?: string;
  intermediateAttributeForSource?: string;
  intermediateAttributeForTarget?: string;
  intermediateAttributes?: any;
  intermediateWizardToStart?: string;

  constructor() {
    super();

    this.name = 'identities';
    this.resourceType = 'Person';
    this.idpConfig = new IdentityEditorConfig();
    this.idpConfig.isMultivalue = true;
    this.tableConfig = new ResourceTableConfig();
    this.tableConfig.selectable = true;
    this.tableConfig.selectMode = 'multiple';
    this.tableConfig.checkboxSelectOnly = true;
    this.tableConfig.resizable = true;
    this.tableConfig.fontSize = 12;
    this.addMemberConfirmation = true;
    this.removeMemberConfirmation = true;
    this.enableRemoveAll = false;
    this.enableDataExchange = false;
    this.dataExchangeAttribute = undefined;
    this.dataExchangeCheckType = true;
    this.dataExchangeLimit = 500;
    this.linkType = null;
    this.linkedAttribute = '';
    this.intermediateObjectType = '';
    this.intermediateObjectName = '';
    this.intermediateAttributeForSource = '';
    this.intermediateAttributeForTarget = '';
    this.intermediateAttributes = null;
    this.intermediateWizardToStart = '';
  }
}

export class IdentitiesEditorConfig extends EditorConfig {
  resourceType?: string;
  objectType?: string;
  navigationKey?: string;
  idpConfig?: IdentityEditorConfig;
  fontSize?: number;
  fontWeight?: number;
  cellPadding?: number;
  pageSize?: number;
  pageCountNumber?: number;
  pageInfo?: boolean;
  pageType?: string;
  pageButton?: boolean;
  scrollMode?: string;
  scrollHeight?: number;
  queryEditableItems?: string;
  sortable?: boolean;
  sortMode?: string;
  allowUnsort?: boolean;
  selectable?: boolean;
  selectBoxWidth?: number;
  selectMode?: string;
  checkboxSelectOnly?: boolean;
  resizable?: boolean;
  defaultLinkAction?: string;
  linkActions?: Array<string>;
  enableRemoveAll?: boolean;
  enableDataExchange?: boolean;
  dataExchangeAttribute?: string;
  dataExchangeCheckType?: boolean;
  dataExchangeLimit?: number;
  datetimeFormat?: string;
  exportToPDF?: boolean;
  exportToExcel?: boolean;
  exportToClipBoard?: boolean;
  exportAllPages?: boolean;
  exportHeader?: boolean;
  exportSeparator?: string;
  exportAttributes?: Array<string>;
  exportState?: {
    enabled: boolean;
    stateLocked: string;
    stateUnlocked: string;
  };
  exportMaxCount?: number;
  tableHeight?: number;
  addMemberConfirmation?: boolean;
  removeMemberConfirmation?: boolean;
  lockIcon?: string;
  lockColor?: string;
  lockText?: string;
  columns?: ResourceColumnConfig[];

  constructor() {
    super();

    this.name = 'identities';
    this.resourceType = 'Person';
    this.objectType = 'Person';
    this.navigationKey = undefined;
    this.idpConfig = new IdentityEditorConfig();
    this.idpConfig.objectType = this.objectType;
    this.idpConfig.isMultivalue = true;
    this.fontSize = 12;
    this.fontWeight = 300;
    this.cellPadding = 4;
    this.pageSize = 5;
    this.pageCountNumber = 3;
    this.pageInfo = true;
    this.pageType = 'numeric';
    this.pageButton = true;
    this.scrollMode = 'basic';
    this.scrollHeight = 0;
    this.queryEditableItems = undefined;
    this.sortable = true;
    this.sortMode = 'single';
    this.allowUnsort = true;
    this.selectable = true;
    this.selectBoxWidth = 45;
    this.selectMode = 'multiple';
    this.checkboxSelectOnly = true;
    this.resizable = true;
    this.defaultLinkAction = '';
    this.linkActions = ['native', 'sideView', 'navigate'];
    this.enableRemoveAll = false;
    this.enableDataExchange = false;
    this.dataExchangeAttribute = undefined;
    this.dataExchangeCheckType = true;
    this.dataExchangeLimit = 500;
    this.datetimeFormat = '';
    this.exportToPDF = false;
    this.exportToExcel = false;
    this.exportToClipBoard = false;
    this.exportAllPages = false;
    this.exportHeader = false;
    this.exportSeparator = '|';
    this.exportAttributes = ['DisplayName'];
    this.exportState = {
      enabled: false,
      stateLocked: 'locked',
      stateUnlocked: 'editable',
    };
    this.exportMaxCount = 5000;
    this.tableHeight = 300;
    this.addMemberConfirmation = true;
    this.removeMemberConfirmation = true;
    this.lockIcon = 'lock';
    this.lockColor = 'disabled';
    this.lockText = 'key_locked';
    this.columns = [
      {
        field: 'DisplayName',
        width: null,
        sortable: true,
      },
    ];
  }
}

export class SeparatorEditorConfig extends EditorConfig {
  text?: string;
  textMode?: string;
  textAlign?: string;
  verticalAlign?: string;
  toolTip?: string;
  icon?: string;
  query?: string;
  attributeToShow?: string;
  color?: string;
  backgroundColor?: string;
  height?: number;
  fontSize?: number;
  borderStyle?: string;
  borderRadius?: number;
  borderWidth?: number;
  borderColor?: string;
  spaceLeft?: number;
  spaceRight?: number;
  spaceTop?: number;
  spaceBottom?: number;
  paddingTop?: number;
  paddingBottom?: number;
  separator?: string;
  isHyperLink?: boolean;
  hyperLinkText?: string;

  constructor() {
    super();

    this.name = 'separator';
    this.text = '';
    this.textMode = 'text';
    this.textAlign = 'start';
    this.verticalAlign = 'center';
    this.toolTip = undefined;
    this.icon = '';
    this.query = undefined;
    this.attributeToShow = undefined;
    this.color = 'black';
    this.backgroundColor = 'lightblue';
    this.height = 26;
    this.fontSize = 16;
    this.borderStyle = 'none';
    this.borderRadius = 0;
    this.borderWidth = 0;
    this.borderColor = 'black';
    this.spaceLeft = 0;
    this.spaceRight = 0;
    this.spaceTop = 0;
    this.spaceBottom = 0;
    this.paddingTop = 0;
    this.paddingBottom = 0;
    this.separator = ';';
    this.isHyperLink = false;
    this.hyperLinkText = '';
  }
}

export class ButtonEditorConfig extends EditorConfig {
  text?: string;
  description?: string;
  style?: string;
  color?: string;
  icon?: string;
  fontSize?: number;
  align?: string;
  stretch?: boolean;

  constructor() {
    super();

    this.name = 'button';
    this.text = 'Button';
    this.style = 'stroked';
    this.description = '';
    this.color = '';
    this.icon = '';
    this.fontSize = 14;
    this.align = 'start center';
    this.stretch = false;
  }
}

export class XpathEditorConfig extends EditorConfig {
  showResults?: boolean;
  showQuery?: boolean;
  displayMode?: string;
  showDisplayMode?: boolean;
  showAttributePicker?: boolean;
  prefix?: string;
  surfix?: string;
  // table properties
  tableConfig?: ResourceTableConfig;
  exportHeader?: boolean;
  exportSeparator?: string;
  exportAttributes?: Array<string>;
  exportState?: {
    enabled: boolean;
    stateLocked: string;
    stateUnlocked: string;
  };
  schema?: string;

  constructor() {
    super();

    this.name = 'xpath';
    this.showResults = true;
    this.showQuery = false;
    this.displayMode = 'builder';
    this.showDisplayMode = true;
    this.showAttributePicker = true;
    this.prefix = '';
    this.surfix = '';
    // table properties
    this.tableConfig = new ResourceTableConfig();
    this.tableConfig.objectType = 'Person';
    this.tableConfig.tableHeight = 300;
    this.tableConfig.cellPadding = 3;
    this.tableConfig.pageSize = 20;
    this.tableConfig.resizable = true;
    this.tableConfig.applyQueryOnLoad = false;
    // additional export settings
    this.exportHeader = false;
    this.exportSeparator = '|';
    this.exportAttributes = ['DisplayName'];
    this.exportState = {
      enabled: false,
      stateLocked: 'locked',
      stateUnlocked: 'editable',
    };
    this.schema = '';
  }
}

export class FrameEditorConfig extends EditorConfig {
  componentID?: string;
  isMultivalue?: boolean;
  data?: string;
  backgroundColor?: string;
  borderStyle?: string;
  borderRadius?: number;
  borderWidth?: number;
  borderColor?: string;
  paddingLeft?: number;
  paddingRight?: number;
  paddingTop?: number;
  paddingBottom?: number;
  marginTop?: number;
  marginBottom?: number;

  constructor() {
    super();

    this.name = 'frame';
    this.componentID = '';
    this.isMultivalue = false;
    this.data = '';
    this.backgroundColor = 'white';
    this.borderStyle = 'none';
    this.borderRadius = 0;
    this.borderWidth = 0;
    this.borderColor = 'black';
    this.paddingLeft = 0;
    this.paddingRight = 0;
    this.paddingTop = 0;
    this.paddingBottom = 0;
    this.marginTop = 0;
    this.marginBottom = 0;
  }
}

export class IterablesProperty {
  name = '';
  displayName = '';
  description?: string;
  type = 'text';
  isKey = false;
  isHidden?: boolean;
  required?: boolean;
  width?: number;
  validation?: string;
  validationKey?: string;
  options?: Array<{ text: string; value: string }> = [];
  default?: any;
}

export class IterablesEditorConfig extends EditorConfig {
  // array / dictionary / object
  iterableType?: 'array' | 'dictionary' | 'object';
  isSimpleValue?: boolean;
  // text / object
  saveAs?: 'text' | 'object';
  updateOn?: ModelUpdateMode;
  maxCount?: number;
  editable?: boolean;
  buttonText?: string;
  properties?: Array<IterablesProperty>;

  constructor() {
    super();

    this.iterableType = 'array';
    this.isSimpleValue = false;
    this.saveAs = 'text';
    this.updateOn = undefined;
    this.maxCount = undefined;
    this.editable = true;
    this.buttonText = 'key_add';
    this.properties = [
      {
        name: 'key',
        displayName: 'key_name',
        type: 'text',
        isKey: true,
        isHidden: false,
        validation: null,
        validationKey: null,
        options: [],
      },
    ];
  }
}

export class ObjectEditorConfig extends EditorConfig {
  rows?: number;
  formatJson?: boolean;
  updateOn?: ModelUpdateMode;

  constructor() {
    super();

    this.rows = 3;
    this.formatJson = false;
    this.updateOn = undefined;
  }
}

export class ForEachEditorConfig extends EditorConfig {
  attributeDef?: Array<{
    attributeName: string;
    editorType: string;
    editorConfig: EditorConfig;
  }>;
  backgroundColor?: string;
  borderStyle?: string;
  borderRadius?: number;
  borderWidth?: number;
  borderColor?: string;
  paddingLeft?: number;
  paddingRight?: number;
  paddingTop?: number;
  paddingBottom?: number;
  marginTop?: number;
  marginBottom?: number;

  constructor() {
    super();

    this.name = 'foreach';
    this.attributeDef = [];
    this.backgroundColor = 'white';
    this.borderStyle = 'none';
    this.borderRadius = 0;
    this.borderWidth = 0;
    this.borderColor = 'black';
    this.paddingLeft = 0;
    this.paddingRight = 0;
    this.paddingTop = 0;
    this.paddingBottom = 0;
    this.marginTop = 0;
    this.marginBottom = 0;
  }
}
