import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { WindowRef } from '@progress/kendo-angular-dialog';

import { ActivityIndexService } from '../../services/activity-index.service';

@Component({
  selector: 'app-activity-creator',
  templateUrl: './activity-creator.component.html',
  styleUrls: ['./activity-creator.component.scss'],
})
export class ActivityCreatorComponent implements OnInit, AfterViewInit {
  data: {
    inSimulationContainer: boolean;
  };

  @ViewChild('titleBar')
  titleBar: TemplateRef<any>;

  activities: Array<any> = [];

  constructor(
    private act: ActivityIndexService,
    private windowRef: WindowRef
  ) {}

  ngOnInit() {
    this.activities = [];

    Object.keys(this.act.activityIndex).forEach((key) => {
      if (!this.act.activityIndex[key].disabled) {
        if (this.data.inSimulationContainer) {
          if (!this.act.activityIndex[key].forbiddenInSimulation) {
            this.activities.push(this.act.activityIndex[key]);
          }
        } else {
          if (!this.act.activityIndex[key].onlyInSimulation) {
            this.activities.push(this.act.activityIndex[key]);
          }
        }
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.titleBar && this.windowRef) {
      this.windowRef.window.instance.titleBarTemplate = this.titleBar;
    }
  }

  onClose(result: any) {
    if (this.windowRef) {
      this.windowRef.close(result);
    }
  }
}
