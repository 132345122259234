<div
  fxFlex="auto"
  *ngIf="configMode || showEditor(resource.rightSets)"
  [ngClass]="{ 'config-hidden': configMode && !showEditor(resource.rightSets) }"
  style="width: 100%; font-size: 0.85em"
>
  <div [ngClass]="{ 'corner-box': configMode }" style="width: 100%">
    <div
      fxFlex="column"
      [style.backgroundColor]="config.backgroundColor"
      [style.borderStyle]="config.borderStyle"
      [style.borderRadius.px]="config.borderRadius"
      [style.borderWidth.px]="config.borderWidth"
      [style.borderColor]="config.borderColor"
      [style.marginTop.px]="config.marginTop"
      [style.marginBottom.px]="config.marginBottom"
      [style.paddingLeft.px]="config.paddingLeft"
      [style.paddingRight.px]="config.paddingRight"
      [style.paddingTop.px]="config.paddingTop"
      [style.paddingBottom.px]="config.paddingBottom"
      style="width: 100%"
    >
      <div *ngIf="configMode">
        <div>
          <app-attribute-view
            #attributeView
            [configMode]="configMode"
            [creationMode]="true"
            [attributeDefs]="config.attributeDef"
            [columnNumber]="1"
            [tabName]="'general'"
            [(attributeArray)]="attributeArray"
            [(resourceToCreate)]="resourceToCreate"
          ></app-attribute-view>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%">
          <button mat-stroked-button color="basic" (click)="onAddComponent()">
            {{ 'key_addComponent' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
