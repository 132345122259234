import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkflowActivityView } from 'src/app/core/models/dynamicEditor.interface';

@Component({
  selector: 'app-activity-approval',
  templateUrl: './activity-approval.component.html',
  styleUrls: ['./activity-approval.component.scss'],
})
export class ActivityApprovalComponent
  extends WorkflowActivityView
  implements OnInit
{
  title: string;
  description: string;
  arrDuration: Array<string>;
  arrApprover: Array<string>;
  threshold: string;
  emergencyapprovers: string;
  etPending: string;
  etEscalation: string;
  etCompleted: string;
  etApproved: string;
  etRejected: string;
  etTimeOut: string;
  disableASA: boolean;

  stageDic: Array<number> = [];

  @Input()
  get approvalTitle() {
    return this.title;
  }
  set approvalTitle(value: string) {
    this.title = value;
    this.approvalTitleChange.emit(this.title);
  }
  @Output()
  approvalTitleChange = new EventEmitter();

  @Input()
  get approvalDescription() {
    return this.description;
  }
  set approvalDescription(value: string) {
    this.description = value;
    this.approvalDescriptionChange.emit(this.description);
  }
  @Output()
  approvalDescriptionChange = new EventEmitter();

  @Input()
  get durationByStage() {
    return this.arrDuration;
  }
  set durationByStage(value: Array<string>) {
    this.arrDuration = value;
    this.durationByStageChange.emit(this.arrDuration);
  }
  @Output()
  durationByStageChange = new EventEmitter();

  @Input()
  get approversByStage() {
    return this.arrApprover;
  }
  set approversByStage(value: Array<string>) {
    this.arrApprover = value;
    this.approversByStageChange.emit(this.arrApprover);
  }
  @Output()
  approversByStageChange = new EventEmitter();

  @Input()
  get approvalThreshold() {
    return this.threshold;
  }
  set approvalThreshold(value: string) {
    this.threshold = value;
    this.approvalThresholdChange.emit(this.threshold);
  }
  @Output()
  approvalThresholdChange = new EventEmitter();

  @Input()
  get disableAutoSelfApproval() {
    return this.disableASA;
  }
  set disableAutoSelfApproval(value: boolean) {
    this.disableASA = value;
    this.disableAutoSelfApprovalChange.emit(this.disableASA);
  }
  @Output()
  disableAutoSelfApprovalChange = new EventEmitter();

  @Input()
  get emergencyApprovers() {
    return this.emergencyapprovers;
  }
  set emergencyApprovers(value: string) {
    this.emergencyapprovers = value;
    this.emergencyApproversChange.emit(this.emergencyapprovers);
  }
  @Output()
  emergencyApproversChange = new EventEmitter();

  @Input()
  get pendingApprovalEmailTemplate() {
    return this.etPending;
  }
  set pendingApprovalEmailTemplate(value: string) {
    this.etPending = value;
    this.pendingApprovalEmailTemplateChange.emit(this.etPending);
  }
  @Output()
  pendingApprovalEmailTemplateChange = new EventEmitter();

  @Input()
  get pendingApprovalEscalationEmailTemplate() {
    return this.etEscalation;
  }
  set pendingApprovalEscalationEmailTemplate(value: string) {
    this.etEscalation = value;
    this.pendingApprovalEscalationEmailTemplateChange.emit(this.etEscalation);
  }
  @Output()
  pendingApprovalEscalationEmailTemplateChange = new EventEmitter();

  @Input()
  get completedApprovalEmailTemplate() {
    return this.etCompleted;
  }
  set completedApprovalEmailTemplate(value: string) {
    this.etCompleted = value;
    this.completedApprovalEmailTemplateChange.emit(this.etCompleted);
  }
  @Output()
  completedApprovalEmailTemplateChange = new EventEmitter();

  @Input()
  get approvedApprovalEmailTemplate() {
    return this.etApproved;
  }
  set approvedApprovalEmailTemplate(value: string) {
    this.etApproved = value;
    this.approvedApprovalEmailTemplateChange.emit(this.etApproved);
  }
  @Output()
  approvedApprovalEmailTemplateChange = new EventEmitter();

  @Input()
  get rejectedRequestEmailTemplate() {
    return this.etRejected;
  }
  set rejectedRequestEmailTemplate(value: string) {
    this.etRejected = value;
    this.rejectedRequestEmailTemplateChange.emit(this.etRejected);
  }
  @Output()
  rejectedRequestEmailTemplateChange = new EventEmitter();

  @Input()
  get timedOutRequestEmailTemplate() {
    return this.etTimeOut;
  }
  set timedOutRequestEmailTemplate(value: string) {
    this.etTimeOut = value;
    this.timedOutRequestEmailTemplateChange.emit(this.etTimeOut);
  }
  @Output()
  timedOutRequestEmailTemplateChange = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (
      this.approversByStage &&
      this.durationByStage &&
      this.approversByStage.length === this.durationByStage.length &&
      this.approversByStage.length !== 0
    ) {
      for (let index = 0; index < this.approversByStage.length; index++) {
        this.stageDic.push(1);
      }
    } else {
      this.onAddApprovalStage();
    }
  }

  onAddApprovalStage() {
    this.approversByStage.push('[//requestor]');
    this.durationByStage.push('7');
    this.stageDic.push(1);
  }

  onRemoveApprovalStage(index: number) {
    this.approversByStage.splice(index, 1);
    this.durationByStage.splice(index, 1);
    this.stageDic.splice(index, 1);
  }
}
