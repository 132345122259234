<form autocomplete="off" #activityForm="ngForm">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start start">
      <app-editor-text
        fxFlex="100"
        name="txtEmailTemplate"
        [creationMode]="true"
        [(ngModel)]="emailTemplate"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          required: true,
          hintLimit: 100,
          customDisplayName: 'key_emailTemplate',
          updateOn: updateOnBlure,
          validationKey: 'workflow/sendemail/emailtemplate'
        }"
        appExpressionValidator
      ></app-editor-text>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" style="margin-top: 10px">
      <app-editor-text
        fxFlex="100"
        style="margin-right: 10px"
        name="txtSendTo"
        [creationMode]="true"
        [(ngModel)]="sendTo"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          required: true,
          hintLimit: 100,
          customDisplayName: 'TO',
          updateOn: updateOnBlure,
          validationKey: 'workflow/sendemail/to'
        }"
        appExpressionValidator
      ></app-editor-text>
      <mat-form-field fxFlex="30" style="margin-bottom: -3px">
        <mat-label>{{ 'key_importance' | translate }}</mat-label>
        <mat-select [(ngModel)]="importance" name="cmbImportance">
          <mat-option value="Normal">{{ 'key_normal' | translate }}</mat-option>
          <mat-option value="Low">{{ 'key_low' | translate }}</mat-option>
          <mat-option value="High">{{ 'key_high' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start start"
      style="margin-top: 10px; margin-bottom: 10px"
    >
      <app-editor-text
        fxFlex="100"
        style="margin-right: 10px"
        name="txtSendCc"
        [(ngModel)]="sendCc"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          hintLimit: 100,
          customDisplayName: 'CC',
          updateOn: updateOnBlure,
          validationKey: 'workflow/sendemail/cc'
        }"
        appExpressionValidator
      ></app-editor-text>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" style="margin-top: 10px">
      <app-editor-text
        fxFlex="100"
        style="margin-right: 10px"
        name="txtSendBcc"
        [(ngModel)]="sendBcc"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          hintLimit: 100,
          customDisplayName: 'BCC',
          updateOn: updateOnBlure,
          validationKey: 'workflow/sendemail/bcc'
        }"
        appExpressionValidator
      ></app-editor-text>
    </div>
  </div>
</form>
