import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WorkflowActivityView } from 'src/app/core/models/dynamicEditor.interface';

@Component({
  selector: 'app-activity-simulation',
  templateUrl: './activity-simulation.component.html',
  styleUrls: ['./activity-simulation.component.scss'],
})
export class ActivitySimulationComponent extends WorkflowActivityView {
  public actor: string;

  @Input()
  get simulationActor() {
    return this.actor;
  }
  set simulationActor(value: string) {
    this.actor = value;
    this.simulationActorChange.emit(this.actor);
  }
  @Output()
  simulationActorChange = new EventEmitter();

  constructor() {
    super();
  }
}
