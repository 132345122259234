import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WorkflowActivityView } from '../../models/dynamicEditor.interface';

@Component({
  selector: 'app-activity-status',
  templateUrl: './activity-status.component.html',
  styleUrls: ['./activity-status.component.scss'],
})
export class ActivityStatusComponent extends WorkflowActivityView {
  activityStatus: string;

  statusMessage: string;

  @Input()
  get status() {
    return this.activityStatus;
  }
  set status(value: string) {
    this.activityStatus = value;
    this.statusChange.emit(this.activityStatus);
  }
  @Output()
  statusChange = new EventEmitter();

  @Input()
  get message() {
    return this.statusMessage;
  }
  set message(value: string) {
    this.statusMessage = value;
    this.messageChange.emit(this.statusMessage);
  }
  @Output()
  messageChange = new EventEmitter();

  constructor() {
    super();
  }
}
