import { Component, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription, of } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';

import { BroadcastEvent, MenuEvent } from '../core/models/dataContract.model';

import { UtilsService } from '../core/services/utils.service';
import { ConfigService } from '../core/services/config.service';
import { SwapService } from '../core/services/swap.service';

import { TreeViewComponent } from '../core/components/tree-view/tree-view.component';
import { ActionMenuComponent } from '../core/components/action-menu/action-menu.component';

@Component({
  selector: 'app-org-structure',
  templateUrl: './org-structure.component.html',
  styleUrls: ['./org-structure.component.scss'],
})
export class OrgStructureComponent implements AfterViewInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  @ViewChild('orgStructure', { static: true }) orgStructure: TreeViewComponent;

  @ViewChild('actionMenu')
  actionMenu: ActionMenuComponent;

  filterString: string;

  treeViewName = 'orgStructure';

  currentOUID: string;

  initQuery: string = this.config.getConfig(
    'structureViewInitQuery',
    `/ocgOrgUnit[not(ocgParentRef=/ocgOrgUnit)]`
  );
  childrenQuery: string = this.config.getConfig(
    'structureViewChildrenQuery',
    `/ocgOrgUnit[ocgParentRef='%ParentID%']`
  );
  sortConfig: string[] = this.config.getConfig('structureViewSort', []);
  pageSize: number = this.config.getConfig('structureViewPageSize', 20);

  attributeStyles = this.config.getConfig('structureViewAttributeStyles', []);

  constructor(
    private utils: UtilsService,
    private route: ActivatedRoute,
    private config: ConfigService,
    private swap: SwapService
  ) {}

  ngAfterViewInit() {
    this.subscription.add(
      this.route.params
        .pipe(
          tap(() => {
            if (
              this.swap.expansionState &&
              this.swap.expansionState[this.treeViewName]
            ) {
              this.orgStructure.setExpansion(
                this.swap.expansionState[this.treeViewName]
              );
            }

            const index = this.route.snapshot.paramMap.get('index');
            if (index) {
              if (index !== 'empty') {
                this.orgStructure.setExpansionWithString(index);
                this.orgStructure.setSelectedItem(index);
              }
            } else {
              this.orgStructure.setSelectedItem('0');
            }
            // make sure to select the correct index
            setTimeout(() => {
              if (index) {
                if (index !== 'empty') {
                  this.orgStructure.setExpansionWithString(index);
                  this.orgStructure.setSelectedItem(index);
                }
              } else {
                this.orgStructure.setSelectedItem('0');
              }
            }, this.config.getConfig('intervalSmall', 300));

            setTimeout(() => {
              const node = this.orgStructure.getSelectedItem();
              if (node) {
                this.currentOUID = this.utils.ExtraValue(
                  node.item.dataItem,
                  'ObjectID'
                );
                this.utils.NavigateToIdentity(
                  node.item.dataItem,
                  `orgstructure/${index}`
                );
              }
            }, this.config.getConfig('intervalLarge', 1000));
          }),
          switchMap(() => {
            if (this.route.children && this.route.children.length > 0) {
              return this.route.children[0].params;
            } else {
              return of(null);
            }
          }),
          tap((value: any) => {
            if (value && value.id) {
              this.currentOUID = value.id;
            }
          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
        if (event) {
          switch (event.name) {
            case 'goto-ou':
              if (this.orgStructure && event.parameter) {
                this.orgStructure.filter(event.parameter, true);
              }
              break;
            case 'refresh-after-change':
              this.onRefreshTree();
              break;
            default:
              break;
          }
        }
      })
    );

    // Send build menu event
    setTimeout(() => {
      this.swap.menuEvent(
        new MenuEvent('build', 'orgStructure', '', null, this.actionMenu)
      );
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onFilterTree() {
    if (this.orgStructure) {
      if (this.filterString && this.filterString.trim().length > 0) {
        this.orgStructure.filter(this.filterString.trim());
      }
    }
  }

  onRefreshTree() {
    this.filterString = '';
    if (this.orgStructure) {
      this.orgStructure.refresh();
    }
  }

  onTreeSelectionChange(item: any) {
    if (item && item.dataItem) {
      if (this.orgStructure.hasFilter) {
        this.utils.NavigateToIdentity(item.dataItem, `orgstructure/-1`);
      } else {
        this.utils.NavigateToIdentity(
          item.dataItem,
          `orgstructure/${item.index}`
        );
      }
    }
  }

  onMenuOpen() {
    this.swap.menuEvent(
      new MenuEvent(
        'open',
        'orgStructure',
        '',
        this.currentOUID,
        this.actionMenu
      )
    );
  }

  onMenuAction(actionName: string) {
    this.swap.menuEvent(
      new MenuEvent('action', 'orgStructure', actionName, {
        currentID: this.currentOUID,
        tree: this,
      })
    );
  }
}
