import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ResourceService } from '../core/services/resource.service';
import { UtilsService } from '../core/services/utils.service';
import { ConfigService } from '../core/services/config.service';
import {
  ResourceColumnConfig,
  ResourceTableConfig,
} from '../core/models/componentContract.model';
import {
  DataStateChangeEvent,
  GridDataResult,
} from '@progress/kendo-angular-grid';
import {
  ApiDef,
  BroadcastEvent,
  ResourceSet,
} from '../core/models/dataContract.model';
import { SwapService } from '../core/services/swap.service';
import { ResourceTableComponent } from '../core/components/resource-table/resource-table.component';
import { set } from 'lodash-es';

@Component({
  selector: 'app-manual-tasks',
  templateUrl: './manual-tasks.component.html',
  styleUrls: ['./manual-tasks.component.scss'],
})
export class ManualTasksComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  private url = 'manualtask/search';
  selectedView = 'open';
  private pageSize = this.config.getConfig('pageSize', 50);

  private apiResourceTableOpen = {
    method: 'get',
    path: this.url,
    param: {
      approver: this.utils.ExtraValue(this.resource.loginUser, 'ObjectID'),
      requestor: '',
      responder: '',
      approvalProcessStatus: ['Open'],
      includeDelegates: true,
      approvalInclusion: 'All',
      responseInclusion: 'All',
      orderBy: 'RequestCreationTime',
      sortOrder: 'Descending',
      take: this.pageSize,
    },
  };
  private apiResourceTableSubmitted = {
    method: 'get',
    path: this.url,
    param: {
      approver: '',
      requestor: '',
      responder: this.utils.ExtraValue(this.resource.loginUser, 'ObjectID'),
      approvalProcessStatus: [
        'Approved',
        'Rejected',
        'Expired',
        'Aborted',
        'Open',
      ],
      includeDelegates: true,
      approvalInclusion: 'All',
      responseInclusion: 'All',
      orderBy: 'RequestCreationTime',
      sortOrder: 'Descending',
      take: this.pageSize,
    },
  };
  private apiResourceTableRequested = {
    method: 'get',
    path: this.url,
    param: {
      approver: '',
      requestor: this.utils.ExtraValue(this.resource.loginUser, 'ObjectID'),
      responder: '',
      approvalProcessStatus: [
        'Approved',
        'Rejected',
        'Expired',
        'Aborted',
        'Open',
      ],
      includeDelegates: true,
      approvalInclusion: 'All',
      responseInclusion: 'All',
      orderBy: 'RequestCreationTime',
      sortOrder: 'Descending',
      take: this.pageSize,
    },
  };
  columns: Array<ResourceColumnConfig> = [
    {
      title: 'Title',
      field: 'approvalTitle',
      width: -1,
      sortable: false,
      filterable: false,
      filter: 'text',
    },
    {
      title: 'Display Name',
      field: 'task',
      width: 300,
      sortable: false,
      filterable: false,
      filter: 'text',
    },
    {
      title: 'Request time',
      field: 'requestDateTime',
      width: 100,
      sortable: true,
      filterable: false,
      filter: 'date',
      display: this.selectedView !== 'submitted',
    },
    {
      title: 'Requestor',
      field: 'requestor',
      width: 100,
      sortable: true,
      filterable: false,
      filter: 'text',
    },
    {
      title: 'Status',
      field: 'approvalProcessDecision',
      width: 100,
      sortable: false,
      showStatus: {
        approved: { color: 'green' },
        rejected: { color: 'red' },
        expired: { color: 'red' },
        aborted: { color: 'red' },
      },
      fallbackStatus: {
        color: 'goldenrod',
        text: 'Pending',
      },
      filterable: false,
      filter: 'text',
    },
  ];
  columnsSubmitted: Array<ResourceColumnConfig> = [
    {
      title: 'Title',
      field: 'approvalTitle',
      width: -1,
      sortable: false,
      filterable: false,
      filter: 'text',
    },
    {
      title: 'Display Name',
      field: 'task',
      width: 300,
      sortable: false,
      filterable: false,
      filter: 'text',
    },
    {
      title: 'Response time',
      field: 'requestDateTime',
      width: 100,
      sortable: true,
      filterable: false,
      filter: 'date',
    },
    {
      title: 'Requestor',
      field: 'requestor',
      width: 100,
      sortable: true,
      filterable: false,
      filter: 'text',
    },
    {
      title: 'Status',
      field: 'approvalProcessDecision',
      width: 100,
      sortable: false,
      showStatus: {
        approved: { color: 'green' },
        rejected: { color: 'red' },
        expired: { color: 'red' },
        aborted: { color: 'red' },
      },
      fallbackStatus: {
        color: 'goldenrod',
        text: 'Pending',
      },
      filterable: false,
      filter: 'text',
    },
  ];

  @ViewChild('resourceTable')
  resourceTable: ResourceTableComponent;

  tableConfig: ResourceTableConfig;

  constructor(
    private resource: ResourceService,
    private utils: UtilsService,
    private config: ConfigService,
    private swap: SwapService
  ) {}

  ngOnInit(): void {
    this.tableConfig = new ResourceTableConfig();
    this.tableConfig.cellPadding = 10;
    this.tableConfig.pageSize = this.pageSize;
    this.tableConfig.selectBoxWidth = 8;
    this.tableConfig.resizable = true;
    this.tableConfig.exportToClipBoard = false;
    this.tableConfig.exportToExcel = false;
    this.tableConfig.linkScope = 'manualtasks';

    this.tableConfig.columns = this.columns;

    switch (this.selectedView) {
      case 'open':
        this.tableConfig.api = this.utils.DeepCopy(this.apiResourceTableOpen);
        this.tableConfig.columns = this.columns;

        break;

      case 'submitted':
        this.tableConfig.api = this.utils.DeepCopy(
          this.apiResourceTableSubmitted
        );
        this.tableConfig.columns = this.columnsSubmitted;

        break;
      case 'requested':
        this.tableConfig.api = this.utils.DeepCopy(
          this.apiResourceTableRequested
        );
        this.tableConfig.columns = this.columns;

        break;
    }

    this.subscription.add(
      this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
        if (event) {
          switch (event.name) {
            case 'refresh-list':
              this.onRefresh();
              break;

            default:
              break;
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  adjustIncommingData = (data: any): ResourceSet => {
    const result = new ResourceSet();
    const input: any = data;
    let output: ResourceSet[] = [];

    if (input && input.length > 0) {
      if (this.selectedView === 'open') {
        output = input;
      } else {
        input.forEach((manualTaskProcess) => {
          manualTaskProcess.approvals.map((approvals: any) => {
            let newManualTask;
            switch (this.selectedView) {
              case 'submitted':
                if (
                  approvals.responders.includes(
                    this.utils.ExtraValue(this.resource.loginUser, 'ObjectID')
                  )
                ) {
                  const timestamp = approvals.responses.find(
                    (response) =>
                      response.approver ===
                      this.utils.ExtraValue(this.resource.loginUser, 'ObjectID')
                  ).responseCreationTime;
                  newManualTask = {
                    ...approvals,
                    approvalProcessDiplayName:
                      manualTaskProcess.approvalProcessDiplayName,
                    approvalProcessDecision: approvals.approvalDecision,
                    approvalTitle: manualTaskProcess.approvalTitle,
                    requestor: manualTaskProcess.requestor,
                    requestorId: manualTaskProcess.requestorId,
                    requestorDisplayName:
                      manualTaskProcess.requestorDisplayName,
                    requestDateTime: timestamp,
                    task: approvals.approvalTitle,
                  };
                  output.push(newManualTask);
                }
                break;
              case 'requested':
                newManualTask = {
                  ...approvals,
                  approvalProcessDiplayName:
                    manualTaskProcess.approvalProcessDiplayName,
                  approvalProcessDecision: approvals.approvalDecision,
                  approvalTitle: manualTaskProcess.approvalTitle,
                  requestor: manualTaskProcess.requestor,
                  requestorId: manualTaskProcess.requestorId,
                  requestorDisplayName: manualTaskProcess.requestorDisplayName,
                  requestDateTime: manualTaskProcess.requestDateTime,
                  task: approvals.approvalTitle,
                };
                output.push(newManualTask);
                break;
            }
          });
        });
      }
      result.results = output;
      result.hasMoreItems = output.length >= this.pageSize;
      if (!result.hasMoreItems) {
        result.totalCount = output.length;
      }
    }
    return result;
  };

  adjustData = (data: GridDataResult): GridDataResult => {
    if (data && data.data) {
      data.data.forEach((element) => {
        element.objecttype = 'manualtask';
        if (element.requestorId && element.requestorDisplayName) {
          element.requestor = {
            ObjectID: element.requestorId,
            DisplayName: element.requestorDisplayName,
            ObjectType: 'person',
          };
        }
        if (
          element.targetId &&
          element.targetDisplayName &&
          element.targetObjectType
        ) {
          element.target = {
            ObjectID: element.targetId,
            DisplayName: element.targetDisplayName,
            ObjectType: element.targetObjectType,
          };
        }
        if (element.approvals && element.approvals.length > 0) {
          let pos = element.approvals.indexOf((e: any) => !e.approvalDecision);
          if (pos < 0) {
            pos = element.approvals.length - 1;
          }
          element.task = {
            ObjectID: element.approvals[pos].approvalObjectId,
            DisplayName: element.approvals[pos].approvalTitle,
            ObjectType: 'manualtask',
          };
        }
      });
    }
    return data;
  };

  adjustPageSize = (pageSize: number, apiDef: ApiDef): ApiDef => {
    if (apiDef.param) {
      if (apiDef.param.skip === undefined) {
        apiDef.param.skip = 0;
      } else {
        apiDef.param.skip += pageSize;
      }
    }

    return apiDef;
  };

  adjustStateChange = (state: DataStateChangeEvent, apiDef: ApiDef): ApiDef => {
    if (state && state.sort && state.sort.length > 0 && state.sort[0].dir) {
      const sortAttribute = state.sort[0].field;
      if (sortAttribute === 'requestor') {
        apiDef.param.orderBy = 'requestordisplayname';
      } else if (sortAttribute === 'target') {
        apiDef.param.orderBy = 'targetdisplayname';
      } else if (sortAttribute === 'requestDateTime') {
        apiDef.param.orderBy = 'RequestCreationTime';
      } else {
        apiDef.param.orderBy = sortAttribute;
      }

      apiDef.param.sortOrder =
        state.sort[0].dir === 'asc' ? 'Ascending' : 'Descending';
    } else {
      apiDef.param.orderBy = 'requestcreationtime';
      apiDef.param.sortOrder = 'Descending';
    }

    return apiDef;
  };

  onRefresh() {
    if (this.resourceTable) {
      this.resourceTable.resetSelection();
      this.resourceTable.updateDataSource();
    }
  }
  onViewChange(view: string) {
    this.selectedView = view;
    switch (this.selectedView) {
      case 'open':
        this.tableConfig.api = this.utils.DeepCopy(this.apiResourceTableOpen);
        this.tableConfig.columns = this.columns;

        break;
      case 'submitted':
        this.tableConfig.api = this.utils.DeepCopy(
          this.apiResourceTableSubmitted
        );
        this.tableConfig.columns = this.columnsSubmitted;

        break;
      case 'requested':
        this.tableConfig.api = this.utils.DeepCopy(
          this.apiResourceTableRequested
        );
        this.tableConfig.columns = this.columns;

        break;
    }
    this.resourceTable.updateDataSource(true, true);
  }
}
