import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';

import { mergeWith as _mergeWith } from 'lodash-es';
import { assignInWith as _assignInWith } from 'lodash-es';

function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(`MSAL Angular - ${message} `);
}

function bootstrapWithoutMSAL() {
  const configuration = new PublicClientApplication({
    auth: {
      clientId: '',
    },
    cache: {
      cacheLocation: 'localStorage',
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
    },
  });

  const settings = {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map<string, Array<string> | null>([
      ['assets/', null],
      ['uploadSaveUrl', null],
    ]),
  } as MsalInterceptorConfiguration;

  const providers = [
    { provide: MSAL_INSTANCE, useValue: configuration },
    { provide: MSAL_INTERCEPTOR_CONFIG, useValue: settings },
  ];

  platformBrowserDynamic([providers])
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}

function bootstrapWithMSAL(config: any) {
  if (config.msalSettings && config.msalSettings.enableMsal) {
    const msalSettings = config.msalSettings;

    const configuration = new PublicClientApplication({
      auth: {
        clientId: msalSettings.clientId,
        authority: msalSettings.authority,
        redirectUri: msalSettings.redirectUri,
        postLogoutRedirectUri: msalSettings.postLogoutRedirectUri,
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: config.msalSettings.logLevel ?? LogLevel.Error,
          piiLoggingEnabled: false,
        },
      },
    });

    const protectedResources = new Map<string, Array<string> | null>(
      msalSettings.protectedResources
    );
    if (protectedResources && msalSettings.unprotectedResources) {
      msalSettings.unprotectedResources.forEach((path: string) => {
        protectedResources.set(path, null);
      });
    }

    const guard = {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['user.read'],
      },
      loginFailedRoute: '/login',
    } as MsalGuardConfiguration;

    const settings = {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: protectedResources,
      // extraQueryParameters: {
      //   domain_hint: msalSettings.domainHint ?? '',
      // },
    } as MsalInterceptorConfiguration;

    const providers = [
      { provide: MSAL_INSTANCE, useValue: configuration },
      { provide: MSAL_GUARD_CONFIG, useValue: guard },
      { provide: MSAL_INTERCEPTOR_CONFIG, useValue: settings },
    ];

    platformBrowserDynamic(providers)
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  } else {
    bootstrapWithoutMSAL();
  }
}

function getArrayOverwriteMode(element: any) {
  if (typeof element === 'string') {
    if (/^\s*(overwritemode|op)\s*:\s*replace\s*$/i.test(element)) {
      return 'replace';
    } else if (/^\s*(overwritemode|op)\s*:\s*append\s*$/i.test(element)) {
      return 'append';
    } else if (
      /^\s*(overwritemode|op)\s*:\s*(delete|insert|replace)\s*:\s*\d+$/i.test(
        element
      )
    ) {
      const arr = element.split(':');
      return `${arr[1].trim()}:${arr[2].trim()}`;
    }
  }

  return null;
}

function executeMode(mode: string, arrResult: Array<any>, arrMode: Array<any>) {
  if (mode === 'append') {
    return arrResult.concat(arrMode);
  } else if (mode === 'replace') {
    return arrMode;
  } else if (mode.startsWith('insert:')) {
    const pos = parseInt(mode.substring(7));
    arrResult.splice(pos, 0, ...arrMode);
    return arrResult;
  } else if (mode.startsWith('replace:')) {
    const pos = parseInt(mode.substring(8));
    arrResult.splice(pos, 1, ...arrMode);
    return arrResult;
  }
}

if (environment.production) {
  enableProdMode();
}

let customConfigExists = true;
let customConfigValid = true;

fetch(
  `${document.getElementsByTagName('base')[0].href}/assets/config/config.${
    environment.env
  }.json?t=${new Date().getTime()}`
)
  .then((response) => response.json())
  .then((json) => {
    fetch(
      `${
        document.getElementsByTagName('base')[0].href
      }/app/customisation/assets/config/customConfig.${
        environment.env
      }.json?t=${new Date().getTime()}`
    )
      .then((customResponse) => {
        if (customResponse.status === 404) {
          customConfigExists = false;
        }
        return customResponse.json();
      })
      .catch(() => {
        customConfigValid = false;
      })
      .then((customJson) => {
        if (customConfigExists) {
          if (customConfigValid) {
            let config = undefined;
            if (customJson.overwriteMode === 'merge') {
              config = _mergeWith({}, json, customJson, (obj, src) => {
                if (Array.isArray(src) && src.length > 0) {
                  if (obj) {
                    let copy: Array<any> = JSON.parse(JSON.stringify(obj));
                    const srcCopy: Array<any> = JSON.parse(JSON.stringify(src));
                    let previousPos = -1;
                    let previousMode = '';

                    srcCopy.forEach((item: any, index: number) => {
                      const mode = getArrayOverwriteMode(item);
                      if (mode) {
                        if (previousPos > 0 && previousMode) {
                          copy = executeMode(
                            previousMode,
                            copy,
                            srcCopy.slice(previousPos, index)
                          );
                        }
                        if (mode.startsWith('delete')) {
                          previousPos = -1;
                          previousMode = '';
                          copy.splice(parseInt(mode.substring(7)), 1);
                        } else {
                          previousPos = index + 1;
                          previousMode = mode;
                        }
                      }
                    });
                    if (previousPos >= 0 && previousMode) {
                      copy = executeMode(
                        previousMode,
                        copy,
                        srcCopy.slice(previousPos)
                      );
                    }

                    if (getArrayOverwriteMode(src[0])) {
                      return copy;
                    }
                  }

                  // switch (getArrayOverwriteMode(src[0])) {
                  //   case 'replace':
                  //     src.shift();
                  //     return src;
                  //   case 'append':
                  //     src.shift();
                  //     return obj.concat(src);
                  //   case 'merge':
                  //     src.shift();
                  //     break;
                  //   default:
                  //     break;
                  // }
                }
              });
            } else {
              // config = { ...json, ...customJson };
              config = _assignInWith(json, customJson, (obj, src) => {
                if (Array.isArray(src) && src.length > 0) {
                  const mode = getArrayOverwriteMode(src[0]);
                  if (mode) {
                    src.shift();
                    return src;
                  }
                }
              });
            }
            localStorage.setItem('LS_CONFIGURATION', JSON.stringify(config));
            bootstrapWithMSAL(config);
          } else {
            localStorage.setItem('LS_CONFIGURATION', 'LS_CONFIGURATIONERROR');
            bootstrapWithoutMSAL();
          }
        } else {
          localStorage.setItem('LS_CONFIGURATION', JSON.stringify(json));
          bootstrapWithMSAL(json);
        }
      });
  })
  .catch(() => {
    localStorage.setItem('LS_CONFIGURATION', 'LS_CONFIGURATIONERROR');
    bootstrapWithoutMSAL();
  });
